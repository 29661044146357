import {
  Badge,
  BadgeVariant,
  Button,
  ButtonVariant,
  Icon,
} from '@hubportal/components';
import { format } from 'date-fns';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ComplianceCaseType } from 'utils/constants';
import { updateStrike } from 'utils/network/apis';
import { RejectStrikeModal } from './reject-strike-modal';

export const StrikeItem = ({
  strike,
  displayBadge,
}: {
  strike: Strike;
  displayBadge?: boolean;
}): JSX.Element => {
  const { t } = useTranslation();

  const [modal, setModal] = useState<boolean>(false);

  const [status, setStatus] = useState<{
    type: '' | 'loading' | 'error' | 'success';
    action?: 'reject' | 'confirm';
    error?: string;
  }>({ type: '' });

  const onReject = (reason): Promise<void> => {
    setStatus({ type: 'loading', action: 'reject' });
    return updateStrike(strike.id, { status: 'EXCUSED', status_reason: reason })
      .then(() => setStatus({ type: 'success', action: 'reject' }))
      .catch((err) => setStatus({ type: 'error', error: err?.message }));
  };

  const onConfirm = (): Promise<void> => {
    setStatus({ type: 'loading', action: 'confirm' });
    return updateStrike(strike.id, { status: 'CONFIRMED' })
      .then(() => setStatus({ type: 'success', action: 'confirm' }))
      .catch((err) => setStatus({ type: 'error', error: err?.message }));
  };

  return (
    <div className="flex gap-10 justify-between bg-primary rounded-lg p-4">
      <div className="flex flex-col gap-1">
        {displayBadge && (
          <>
            <div className="[&>div]:!rounded-full [&>div]:!px-2 self-start">
              <Badge variant={BadgeVariant.white}>
                {t(`strike_type_${strike.type?.toLowerCase()}`)}
              </Badge>
            </div>
            <div className="text-white title-m">
              {strike?.extra?.workforce_name}
            </div>
            <Link
              className="title-s text-[#E81F76]"
              to={`/workforce/${strike.workforce_id}`}
              target="_blank"
            >
              {t('view_profile')}
            </Link>
          </>
        )}

        <div className="title-s text-white">
          {`${format(new Date(strike.created_at), 'iiii, dd LLLL')} - ${
            strike.extra?.hub_name
          }`}
        </div>
        <div className="text-s text-white">
          {[
            ComplianceCaseType.SHORT_LATENESS,
            ComplianceCaseType.LONG_LATENESS,
          ].includes(strike?.type as any) ? (
            t('shift_from_to', {
              from: format(
                new Date(strike.extra?.shift_start_at || 0),
                'HH:mm'
              ),
              to: format(new Date(strike.extra?.shift_end_at || 0), 'HH:mm'),
            })
          ) : (
            <div>
              {t('request_late_by')}:&nbsp;
              <span className="title-s">
                {t('n_hours', {
                  n: strike.extra.sick_note_lateness_in_hours,
                })}
              </span>
            </div>
          )}
        </div>
        <div className="flex gap-2">
          <div className="w-0.5 h-full bg-secondary rounded-full" />
          <div className="flex flex-col gap-1 text-s">
            {[
              ComplianceCaseType.SHORT_LATENESS,
              ComplianceCaseType.LONG_LATENESS,
            ].includes(strike?.type as any) &&
              strike.extra.punched_in_at && (
                <div className="flex gap-1">
                  {t('started_at_time', {
                    time: format(
                      new Date(strike.extra.punched_in_at || 0),
                      'HH:mm'
                    ),
                  })}
                  &nbsp;
                  <div className="title-s">
                    {`(${t('x_min_late', {
                      min: strike.extra.late_seconds
                        ? Math.floor(strike.extra.late_seconds / 60)
                        : 0,
                    })})`}
                  </div>
                </div>
              )}
            {[ComplianceCaseType.LATE_SICK_NOTE].includes(
              strike?.type as any
            ) &&
              strike.extra.sick_note_from_date &&
              strike.extra.sick_note_send_date && (
                <>
                  <div className="flex gap-1">
                    {t('absence_start_date')}:{' '}
                    {format(
                      new Date(strike.extra.sick_note_from_date || 0),
                      'dd.MM.yyyy'
                    )}
                  </div>
                  <div>
                    {t('request_date')}:{' '}
                    {format(
                      new Date(strike.extra.sick_note_send_date || 0),
                      'dd.MM.yyyy'
                    )}
                  </div>
                </>
              )}
          </div>
        </div>
      </div>
      {strike.status === 'PENDING' && (
        <div className="flex gap-4 self-center">
          {status.action === 'confirm' && status.type === 'success' ? (
            <div className="flex gap-1">
              <Icon type="check" />
              {t('warning_sent')}
            </div>
          ) : status.action === 'reject' && status.type === 'success' ? (
            <div className="flex gap-1">{t('rejected')}</div>
          ) : (
            <div>
              <div className="flex gap-4">
                <Button
                  variant={ButtonVariant.quinary}
                  disabled={status.type === 'loading'}
                  loading={
                    status.type === 'loading' && status.action === 'reject'
                  }
                  onClick={() => {
                    setModal(true);
                  }}
                >
                  {t('reject')}
                </Button>
                <Button
                  variant={ButtonVariant.primary}
                  disabled={status.type === 'loading'}
                  loading={
                    status.type === 'loading' && status.action === 'confirm'
                  }
                  onClick={() => {
                    onConfirm();
                  }}
                >
                  {t('confirm')}
                </Button>
              </div>
              {status.type === 'error' && (
                <div className="text-pink text-xs pt-2">{status.error}</div>
              )}
              <RejectStrikeModal
                open={modal}
                onClose={() => {
                  setModal(false);
                }}
                onSubmit={async (reason) => {
                  onReject(reason);
                }}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};
