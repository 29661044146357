import { useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { format, isToday } from 'date-fns';
import { WorkforceCompliance } from 'models';
import {
  Button,
  ButtonVariant,
  Pagination,
  TableSortBy,
} from '@hubportal/components';
import { Sidebar } from 'components/sidebar-temp';
import { CreateRider } from 'partials/create-rider';
import {
  FeedbackLevelMultiselect,
  PerformanceGroupMultiselect,
} from 'partials/multiselect';
import { AllEmployeesPerformanceTable } from './all-employees-performance-table';
import usePermissions, { Permission } from 'utils/hooks/usePermissions';
import useWorkforceV2, {
  WorkforceSearchQuery,
} from 'utils/hooks/useWorkforceV2';
import { ComplianceCaseStatus, MIN_SEARCH_TERM_LENGTH } from 'utils/constants';
import { isPerformanceGroupEnabled, sortKey } from 'utils/helpers';
import { track } from 'utils/tracking';
import { WorkStatusFilter } from 'partials/select/work-status';
import { EmploymentStatusFilter } from 'partials/select/employment-status';
import { TerminationEligibilityFilter } from 'partials/select/termination-eligibility';
import { CPHFilter } from 'partials/select/cph';
import { ChurnRiskFilter } from 'partials/select/churn-risk';

const DEFAULT_PAGE_SIZE = 100;
const DEFAULT_QUERY = {
  max: DEFAULT_PAGE_SIZE,
  skip: 0,
};

const DEFAULT_PAGINATION = {
  pageIndex: 0,
  pageSize: DEFAULT_PAGE_SIZE,
  pageCount: 0,
};

export const AllEmployeesPerformance = ({
  selectedPeriod,
  selectedHubs,
  searchTerm,
  setSearchTerm,
  setUpdatedAt,
  isTerminationEligibilityFilterEnabled,
  isCPHFilterEnabled,
  isChurnRiskFilterEnabled,
}: {
  selectedPeriod: { from: Date; to: Date; cw: number; isCurrentWeek?: boolean };
  selectedHubs?: string[];
  searchTerm: string;
  setSearchTerm: (s) => void;
  setUpdatedAt: (u) => void;
  isTerminationEligibilityFilterEnabled: boolean;
  isCPHFilterEnabled: boolean;
  isChurnRiskFilterEnabled: boolean;
}): JSX.Element => {
  const ref = useRef(0);

  const { t } = useTranslation();
  const { isAllowed } = usePermissions();
  const [, setSearchParams] = useSearchParams();

  const [sortBy, setSortBy] = useState<TableSortBy>({ column: '', type: null });
  const [selectedTerminationEligibility, setSelectedTerminationEligibility] =
    useState<any>({ id: 'all', label: t('termination_eligibility') });
  const [selectedFeedbackLevels, setSelectedFeedbackLevels] = useState<
    string[]
  >([]);

  const [selectedPerformanceGroups, setSelectedPerformanceGroups] = useState<
    string[]
  >([]);

  const [selectedWorkStatus, setSelectedWorkStatus] = useState<any>({
    id: 'all',
    label: t('next_shift'),
  });

  const [selectedEmploymentStatus, setSelectedEmploymentStatus] = useState<any>(
    {
      id: 'all',
      label: t('employment_status'),
    }
  );

  const [selectedCPH, setSelectedCPH] = useState<any>({
    id: 'all',
    label: t('cph'),
  });

  const [selectedChurnRisk, setSelectedChurnRisk] = useState<any>({
    id: 'all',
    label: t('churn_risk'),
  });

  const [newRider, setNewRider] = useState<'internal' | 'external' | null>(
    null
  );

  const [employees, setEmployees] = useState<WorkforceCompliance[]>([]);
  const [pagination, setPagination] = useState(DEFAULT_PAGINATION);

  const [query, setQuery] = useState<WorkforceSearchQuery>({
    ...DEFAULT_QUERY,
    from: format(selectedPeriod.from, "yyyy-MM-dd'T'HH:mm:ss'Z'"),
    to: format(selectedPeriod.to, "yyyy-MM-dd'T'HH:mm:ss'Z'"),
    generic: searchTerm,
    selectedHubs,
    sort: sortKey(sortBy.column, sortBy.type),
  });

  const { status, error, data } = useWorkforceV2(query);

  const onChangeSelectedTerminationEligibility = ({ id, label }: any): void => {
    track('click', {
      component_content:
        id === 'is_critical' || id === 'opened'
          ? 'requires_analysis'
          : id?.toLowerCase(),
      component_name: 'filter',
      screen_name: 'workforce_dashboard',
    });

    setSelectedTerminationEligibility({ id, label });
  };

  useEffect(() => {
    if (status === 'success') {
      if (ref?.current === 0) {
        ref.current += 1;

        track('workforceDashboardViewed', {
          screen_name: 'workforce_dashboard',
          actionable_employees: data?.results?.filter(
            (e) => e.caseStatus === ComplianceCaseStatus.OPENED
          ).length,
        });
      }
    }
  }, [status]);

  useEffect(() => {
    setQuery({
      ...query,
      max: pagination.pageSize,
      skip: pagination.pageSize * pagination.pageIndex,
    });
  }, [pagination.pageIndex, pagination.pageSize]);

  useEffect(() => {
    setQuery({
      ...query,
      selectedHubs,
      selectedFeedbackLevels,
      selectedPerformanceGroups,
      selectedTerminationEligibility: selectedTerminationEligibility.id,
      selectedWorkStatus: selectedWorkStatus.id,
      selectedEmploymentStatus: selectedEmploymentStatus.id,
      selectedCPH: selectedCPH.id,
      selectedChurnRisk: selectedChurnRisk.id,
      from: format(selectedPeriod.from, "yyyy-MM-dd'T'HH:mm:ss'Z'"),
      to: format(selectedPeriod.to, "yyyy-MM-dd'T'HH:mm:ss'Z'"),
      skip: 0,
    });
    setPagination({ ...pagination, pageIndex: 0 });
  }, [
    selectedHubs,
    selectedTerminationEligibility,
    selectedWorkStatus,
    selectedEmploymentStatus,
    selectedFeedbackLevels,
    selectedPerformanceGroups,
    selectedCPH,
    selectedChurnRisk,
    selectedPeriod,
  ]);

  useEffect(() => {
    if (searchTerm.length === 0 || searchTerm.length > MIN_SEARCH_TERM_LENGTH) {
      setQuery({ ...query, skip: 0, generic: searchTerm });
      setPagination({ ...pagination, pageIndex: 0 });
    }
  }, [searchTerm]);

  useEffect(() => {
    setEmployees(data?.results || []);
    const updatedAt = (data.results || [])
      .map((r) => r.updatedAt)
      .sort((a, b) => new Date(b || 0).getTime() - new Date(a || 0).getTime());
    setUpdatedAt(updatedAt?.length ? updatedAt[0] : '');
    setPagination({
      ...pagination,
      pageCount: Math.ceil(
        (data?.pagination?.total || 1) /
          (data?.pagination?.max || data?.pagination?.limit || 1)
      ),
    });
  }, [data]);

  useEffect(() => {
    setQuery({ ...query, sort: sortKey(sortBy.column, sortBy.type), skip: 0 });
    setPagination({ ...pagination, pageIndex: 0 });
  }, [sortBy]);

  useEffect(() => {
    setSearchParams(
      {
        hubs: (selectedHubs || []).join(','),
        search: searchTerm,
        sort: sortKey(sortBy.column, sortBy.type),
        cw: `${selectedPeriod?.cw || 0}`,
      },
      { replace: true }
    );
  }, [selectedHubs, searchTerm, sortBy]);

  const isSearchActive =
    searchTerm && searchTerm.length > MIN_SEARCH_TERM_LENGTH;

  return (
    <div className="flex flex-col gap-2">
      {isSearchActive ? (
        <>
          <div className="header-s text-white">
            {status !== 'loading' && employees?.length === 0
              ? t('no_result_for', { key: searchTerm })
              : t('search_result_for', { key: searchTerm })}
          </div>
          <div
            onClick={() => setSearchTerm('')}
            className="text-xs text-pink underline cursor-pointer"
          >
            {t('clear')}
          </div>
        </>
      ) : (
        <>
          <div className="header-s text-white">{t('all_employees')}</div>
          <div className="text-s">
            {isToday(selectedPeriod.from)
              ? t('showing_data_from_today')
              : t('showing_data_from', {
                  from: format(selectedPeriod.from || 0, 'MMM dd'),
                  to: selectedPeriod.isCurrentWeek
                    ? t('today')
                    : format(selectedPeriod.to || 0, 'MMM dd'),
                })}
          </div>
        </>
      )}
      <div className="my-2 flex justify-between gap-4">
        <div className="flex gap-2 flex-wrap">
          {!isSearchActive && (
            <>
              {isTerminationEligibilityFilterEnabled && (
                <TerminationEligibilityFilter
                  value={selectedTerminationEligibility}
                  onChange={onChangeSelectedTerminationEligibility}
                />
              )}

              <EmploymentStatusFilter
                isTerminationEligibilityFilterEnabled={
                  isTerminationEligibilityFilterEnabled
                }
                value={selectedEmploymentStatus}
                onChange={setSelectedEmploymentStatus}
              />

              {isPerformanceGroupEnabled(selectedPeriod.from) ? (
                <PerformanceGroupMultiselect
                  values={selectedPerformanceGroups}
                  onChange={setSelectedPerformanceGroups}
                />
              ) : (
                <FeedbackLevelMultiselect
                  values={selectedFeedbackLevels}
                  onChange={setSelectedFeedbackLevels}
                />
              )}

              <WorkStatusFilter
                value={selectedWorkStatus}
                onChange={setSelectedWorkStatus}
              />

              {isCPHFilterEnabled && (
                <CPHFilter value={selectedCPH} onChange={setSelectedCPH} />
              )}

              {isChurnRiskFilterEnabled && (
                <ChurnRiskFilter
                  value={selectedChurnRisk}
                  onChange={setSelectedChurnRisk}
                />
              )}
            </>
          )}
        </div>
        <div className="flex gap-x-2 items-start">
          {isAllowed(Permission.CREATE_RIDER_EXTERNAL) && !isSearchActive && (
            <Button
              variant={ButtonVariant.quaternary}
              onClick={() => setNewRider('external')}
            >
              {t('create_external_rider')}
            </Button>
          )}
          {isAllowed(Permission.CREATE_RIDER_INTERNAL) && !isSearchActive && (
            <Button
              variant={ButtonVariant.quaternary}
              onClick={() => setNewRider('internal')}
            >
              {t('import_rider')}
            </Button>
          )}
        </div>
      </div>

      {(!isSearchActive ||
        !(
          isSearchActive &&
          status === 'success' &&
          employees?.length === 0
        )) && (
        <>
          <AllEmployeesPerformanceTable
            data={employees}
            loading={status === 'loading'}
            sortBy={sortBy}
            onSortChange={(sb) => setSortBy(sb)}
            displayShiftsData
            isPerformanceGroupEnabled={isPerformanceGroupEnabled(
              selectedPeriod.from
            )}
            calendarWeek={selectedPeriod.cw}
            sortableColumns={
              isSearchActive
                ? ['NAME', 'JOB_TITLE', 'STATUS']
                : [
                    'NO_SHOWS',
                    'LATENESS',
                    'ACCEPTANCE_RATE',
                    'UTR',
                    'INACTIVITY_DAYS',
                    'AVERAGE_PICKING_TIME',
                    'POST_ORDER_ISSUE_RATE',
                    'PROBATION_STATUS',
                    'LAST_FEEDBACK',
                  ]
            }
          />
          {status === 'success' &&
            (data?.pagination?.total || 0) > pagination.pageSize && (
              <div className="py-2">
                <Pagination
                  goToPage={(pi) => {
                    setPagination({ ...pagination, pageIndex: pi });
                  }}
                  pageCount={pagination.pageCount}
                  pageIndex={pagination.pageIndex}
                  pageSize={pagination.pageSize}
                  setPageSize={(ps) => {
                    setPagination({ ...pagination, pageSize: ps });
                  }}
                  totalCount={data?.pagination?.total || 0}
                  labels={{ show: t('show') }}
                />
              </div>
            )}
        </>
      )}

      {/* error state */}
      {error && (
        <div className="text-center p-4">
          <div className="text-s max-w-sm m-auto">{t(error)}</div>
        </div>
      )}

      <Sidebar open={!!newRider} onClose={() => setNewRider(null)}>
        <CreateRider type={newRider} onSuccess={() => setQuery({ ...query })} />
      </Sidebar>
    </div>
  );
};
