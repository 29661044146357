import { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, ButtonVariant, Modal } from '@hubportal/components';

interface ConfirmationModalProps {
  open: boolean;
  onClose(): void;
  onConfirm(): void;
  labels?: {
    confirm: string;
    cancel: string;
  };
}

export const ConfirmationModal = ({
  open,
  onConfirm,
  onClose,
  labels = {
    confirm: 'yes',
    cancel: 'no',
  },
  children,
}: PropsWithChildren<ConfirmationModalProps>): JSX.Element => {
  const { t } = useTranslation();
  return (
    <Modal open={open} onClose={onClose}>
      <div className="text-left max-w-lg flex flex-col gap-2">
        {children}
        <div className="flex gap-2 my-4 [&>button]:flex-1 [&>button]:justify-center">
          <Button variant={ButtonVariant.quinary} onClick={onClose}>
            {t(labels.cancel)}
          </Button>
          <Button onClick={onConfirm}>{t(labels.confirm)}</Button>
        </div>
      </div>
    </Modal>
  );
};
