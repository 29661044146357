import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { format, isToday } from 'date-fns';
import { WorkforceCompliance } from 'models';
import { Pagination, TableSortBy } from '@hubportal/components';
import { AllEmployeesPerformanceTable } from './all-employees-performance-table';
import useWorkforceV2, {
  WorkforceSearchQuery,
} from 'utils/hooks/useWorkforceV2';
import { isPerformanceGroupEnabled, sortKey } from 'utils/helpers';
import { MIN_SEARCH_TERM_LENGTH } from 'utils/constants';

const DEFAULT_PAGE_SIZE = 100;
const DEFAULT_QUERY = {
  max: DEFAULT_PAGE_SIZE,
  skip: 0,
  isTerminated: false,
  excludeZeroLateShifts: true,
};

export const LateShiftsMetric = ({
  selectedPeriod,
  selectedHubs,
  searchTerm,
  setSearchTerm,
}: {
  selectedPeriod: { from: Date; to: Date; cw: number; isCurrentWeek?: boolean };
  selectedHubs?: string[];
  searchTerm: string;
  setSearchTerm: (s) => void;
}): JSX.Element => {
  const { t } = useTranslation();
  const [, setSearchParams] = useSearchParams();

  const [sortBy, setSortBy] = useState<TableSortBy>({
    column: 'LATENESS',
    type: 'DESC',
  });

  const [employees, setEmployees] = useState<WorkforceCompliance[]>([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: DEFAULT_PAGE_SIZE,
    pageCount: 0,
  });

  const [query, setQuery] = useState<WorkforceSearchQuery>({
    ...DEFAULT_QUERY,
    from: format(selectedPeriod.from, "yyyy-MM-dd'T'HH:mm:ss'Z'"),
    to: format(selectedPeriod.to, "yyyy-MM-dd'T'HH:mm:ss'Z'"),
    generic: searchTerm,
    selectedHubs,
    sort: sortKey(sortBy.column, sortBy.type),
  });

  const { status, error, data } = useWorkforceV2(query);

  useEffect(() => {
    setQuery({
      ...query,
      max: pagination.pageSize,
      skip: pagination.pageSize * pagination.pageIndex,
    });
  }, [pagination.pageIndex, pagination.pageSize]);

  useEffect(() => {
    setQuery({
      ...query,
      selectedHubs,
      skip: 0,
      from: format(selectedPeriod.from, "yyyy-MM-dd'T'HH:mm:ss'Z'"),
      to: format(selectedPeriod.to, "yyyy-MM-dd'T'HH:mm:ss'Z'"),
    });
    setPagination({ ...pagination, pageIndex: 0 });
  }, [selectedHubs, selectedPeriod]);

  useEffect(() => {
    if (searchTerm.length === 0 || searchTerm.length > MIN_SEARCH_TERM_LENGTH) {
      setQuery({ ...query, skip: 0, generic: searchTerm });
      setPagination({ ...pagination, pageIndex: 0 });
    }
  }, [searchTerm]);

  useEffect(() => {
    setEmployees(data?.results || []);
    setPagination({
      ...pagination,
      pageCount: Math.ceil(
        (data?.pagination?.total || 1) /
          (data?.pagination?.max || data?.pagination?.limit || 1)
      ),
    });
  }, [data]);

  useEffect(() => {
    setQuery({
      ...query,
      sort: sortKey(sortBy.column, sortBy.type),
      skip: 0,
    });
    setPagination({ ...pagination, pageIndex: 0 });
  }, [sortBy]);

  useEffect(() => {
    setSearchParams(
      {
        hubs: (selectedHubs || []).join(','),
        search: searchTerm,
        sort: sortKey(sortBy.column, sortBy.type),
      },
      { replace: true }
    );
  }, [selectedHubs, searchTerm, sortBy]);

  const isSearchActive =
    searchTerm && searchTerm.length > MIN_SEARCH_TERM_LENGTH;

  return (
    <div className="flex flex-col gap-2">
      {isSearchActive ? (
        <>
          <div className="header-s text-white">
            {status !== 'loading' && employees?.length === 0
              ? t('no_result_for', { key: searchTerm })
              : t('search_result_for', { key: searchTerm })}
          </div>
          <div
            onClick={() => setSearchTerm('')}
            className="text-xs text-pink underline cursor-pointer"
          >
            {t('clear')}
          </div>
        </>
      ) : (
        <>
          <div className="header-s text-white">
            {isToday(selectedPeriod.from)
              ? t('employees_with_late_shifts_today')
              : t('employees_with_late_shifts', {
                  from: format(selectedPeriod.from || 0, 'MMM dd'),
                  to: selectedPeriod.isCurrentWeek
                    ? t('today')
                    : format(selectedPeriod.to || 0, 'MMM dd'),
                })}
          </div>
        </>
      )}
      <div className="my-2" />
      {(!isSearchActive ||
        !(
          isSearchActive &&
          status === 'success' &&
          employees?.length === 0
        )) && (
        <>
          <AllEmployeesPerformanceTable
            data={employees}
            loading={status === 'loading'}
            sortBy={sortBy}
            onSortChange={(sb) => setSortBy(sb)}
            displayShiftsData
            calendarWeek={selectedPeriod.cw}
            isPerformanceGroupEnabled={isPerformanceGroupEnabled(
              selectedPeriod.from
            )}
            sortableColumns={
              isSearchActive
                ? ['NAME', 'JOB_TITLE', 'STATUS']
                : [
                    'NO_SHOWS',
                    'LATENESS',
                    'ACCEPTANCE_RATE',
                    'UTR',
                    'INACTIVITY_DAYS',
                    'AVERAGE_PICKING_TIME',
                    'POST_ORDER_ISSUE_RATE',
                    'PROBATION_STATUS',
                    'LAST_FEEDBACK',
                  ]
            }
          />
          {status === 'success' &&
            (data?.pagination?.total || 0) > pagination.pageSize && (
              <div className="py-2">
                <Pagination
                  goToPage={(pi) => {
                    setPagination({ ...pagination, pageIndex: pi });
                  }}
                  pageCount={pagination.pageCount}
                  pageIndex={pagination.pageIndex}
                  pageSize={pagination.pageSize}
                  setPageSize={(ps) => {
                    setPagination({ ...pagination, pageSize: ps });
                  }}
                  totalCount={data?.pagination?.total || 0}
                  labels={{ show: t('show') }}
                />
              </div>
            )}
        </>
      )}

      {/* error state */}
      {error && (
        <div className="text-center p-4">
          <div className="text-s max-w-sm m-auto">{t(error)}</div>
        </div>
      )}
    </div>
  );
};
