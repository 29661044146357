import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Select, SelectItem, SelectProps } from '@hubportal/components';
import { getExternalAgencies } from 'utils/eppo/helpers';

const AgencySelect = ({ onChange, ...props }: SelectProps): JSX.Element => {
  const { t } = useTranslation();
  const externalAgencies = useMemo(() => getExternalAgencies(), []);

  return (
    <Select
      required
      searchable
      label={t('external_agency')}
      placeholder={t('external_agency')}
      onChange={onChange}
      {...props}
    >
      {externalAgencies.map((agency) => {
        return (
          <SelectItem key={agency} id={agency} label={agency}>
            {agency}
          </SelectItem>
        );
      })}
    </Select>
  );
};

export { AgencySelect };
