import {
  Multiselect,
  MultiselectItem,
  MultiselectProps,
} from '@hubportal/components';
import { useTranslation } from 'react-i18next';
import useHubs from 'utils/hooks/useHubs';

const HubsMultiselect = ({
  values,
  onChange,
  ...props
}: MultiselectProps): JSX.Element => {
  const { t } = useTranslation();
  const { data: hubs } = useHubs();

  return (
    <Multiselect
      searchable
      labels={{
        default: t('hubs'),
        search: t('search_hubs'),
      }}
      values={values}
      onChange={onChange}
      stackSelectedOnTop
      {...props}
    >
      {hubs.map((hub) => {
        const { id } = hub;
        return (
          <MultiselectItem key={id} value={id} label={id}>
            {id}
          </MultiselectItem>
        );
      })}
    </Multiselect>
  );
};

export { HubsMultiselect };
