import { useTranslation } from 'react-i18next';
import {
  Button,
  ButtonVariant,
  Modal,
  Textarea,
  TextareaVariant,
} from '@hubportal/components';
import { useState } from 'react';
import { updateCase } from 'utils/network/apis';
import { ComplianceCaseStatus } from 'utils/constants';

interface CloseCaseModalProps {
  open: boolean;
  workforceID: string;
  caseID: string;
  onClose: () => void;
  onSuccess: (editedFields) => void;
  setSnackbar?: (props) => void;
}

export const CloseCaseModal = ({
  open,
  workforceID,
  caseID,
  onClose,
  onSuccess,
  setSnackbar = (props) => {},
}: CloseCaseModalProps): JSX.Element => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState('');
  const [error, setError] = useState('');

  const onConfirm = async (): Promise<void> => {
    try {
      setError('');
      setLoading(true);
      await updateCase(workforceID, caseID, {
        status: ComplianceCaseStatus.CLOSED,
        description: value,
      });

      if (setSnackbar) {
        setSnackbar({
          visible: true,
          message: 'compliance_case_close_success_message',
        });
        setTimeout(() => setSnackbar({ visible: false, message: '' }), 3000);
      }

      onSuccess({ status: ComplianceCaseStatus.CLOSED });
      onClose();
    } catch (err: any) {
      setError(err?.response?.data?.error || err.message);
    } finally {
      setValue('');
      setLoading(false);
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <>
        <div className="text-left max-w-lg flex flex-col gap-2">
          <div className="header-m">
            {t('compliance_case_close_modal_header')}
          </div>
          <Textarea
            value={value}
            rows={4}
            variant={TextareaVariant.secondary}
            placeholder={t('specify_reasons')}
            onChange={(e) => setValue(e?.target?.value)}
          />
          {error && <span className="text-xs text-pink">{error}</span>}
        </div>
        <div className="flex gap-2 my-4 [&>button]:flex-1 [&>button]:justify-center">
          <Button variant={ButtonVariant.quinary} onClick={onClose}>
            {t('cancel')}
          </Button>
          <Button
            disabled={loading || !value}
            onClick={() => {
              onConfirm();
            }}
          >
            {t('send')}
          </Button>
        </div>
      </>
    </Modal>
  );
};
