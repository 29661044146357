import {
  ComplianceCaseStatus,
  RiderStateColors,
  RiderStates,
} from 'utils/constants';
import { isInThePast } from 'utils/helpers';
import { t } from 'i18next';

type Shift = {
  from: Date;
  to: Date;
  hub: string;
  scheduleID: number;
};
export class WorkforceCompliance {
  workforceID: string;
  fullName: string;
  ecID?: string;
  jobTitle?: string;
  probationEndDate?: string;
  contractEndDate?: string;
  caseStatus?: ComplianceCaseStatus;
  caseType?: 'NO_SHOW' | 'INACTIVITY';
  totalNoShows?: number;
  periodNoShows?: number;
  lateShifts?: number;
  acceptanceRate?: number;
  utr?: number;
  averagePickingTime?: number;
  postOrderIssueRate?: number;
  totalWarnings?: number;
  lastActivityAt?: string;
  terminatedAt?: string;
  homeHub?: string;
  hubSlug?: string;
  isExternal?: boolean;
  isCritical?: boolean;
  status: RiderStates;
  feedbackNeed?: string;
  performanceGroup?: string;
  workingShift?: Shift | null;
  nextShift?: Shift | null;
  lastFeedback?: string;
  updatedAt?: string;

  constructor(details: any = {}) {
    this.workforceID = details.workforce_id;
    this.fullName = details.full_name;
    this.ecID = details.ec_id;
    this.jobTitle = details.job_title;
    this.probationEndDate = details.probation_end_date;
    this.contractEndDate = details.contract_end_date;
    this.caseStatus = details.case_status;
    this.caseType = details.case_type;
    this.totalNoShows = details.total_no_shows;
    this.periodNoShows = details.period_no_shows;
    this.lateShifts = details.late_shows_count;
    this.acceptanceRate = details.acceptance_rate;
    this.utr = details.utr;
    this.averagePickingTime = details.average_picking_time;
    this.postOrderIssueRate = details.post_order_issue_rate;
    this.totalWarnings = details.total_warnings;
    this.lastActivityAt = details.last_activity_at;
    this.homeHub = details.home_hub;
    this.hubSlug = details.hub_slug;
    this.status = details.status;
    this.terminatedAt = details.terminated_at?.startsWith('0001-01-01')
      ? null
      : details.terminated_at;
    this.isExternal = details.is_external;
    this.isCritical = details.is_critical;
    this.feedbackNeed = details.feedback_level;
    this.performanceGroup = details.performance_group;
    this.workingShift = details.working_shift
      ? {
          from: new Date(details.working_shift?.from_date),
          to: new Date(details.working_shift?.to_date),
          hub: details.working_shift?.hub_slug,
          scheduleID: details.working_shift?.quinyx_schedule_id,
        }
      : null;
    this.nextShift = details.next_shift
      ? {
          from: new Date(details.next_shift?.from_date),
          to: new Date(details.next_shift?.to_date),
          hub: details.next_shift?.hub_slug,
          scheduleID: details.next_shift?.quinyx_schedule_id,
        }
      : null;
    this.lastFeedback = details.last_feedback_date;
    this.updatedAt = details.updated_at;
  }

  public static from(details: any): WorkforceCompliance {
    return new WorkforceCompliance(details);
  }

  public isProbationPassed(): boolean {
    return this.probationEndDate
      ? isInThePast(new Date(this.probationEndDate || 0))
      : false;
  }

  public isInProbation(): boolean {
    return this.probationEndDate
      ? !isInThePast(new Date(this.probationEndDate || 0))
      : false;
  }

  public isTerminated(): boolean {
    return this.terminatedAt ? isInThePast(new Date(this.terminatedAt)) : false;
  }

  public getState(): string {
    return [t(`rider_status_${this.status?.toLowerCase()}`)]
      .filter((s) => s)
      .join(' / ');
  }

  public getStateColor(): string {
    if (this.status === RiderStates.TEMP_OFFLINE) {
      return 'bg-orange-400';
    }
    return RiderStateColors[this.status] || 'bg-gray-300';
  }
}
