import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import {
  Badge,
  BadgeVariant,
  Button,
  ButtonVariant,
  Icon,
} from '@hubportal/components';
import {
  ComplianceCaseStatus,
  ComplianceCaseType,
  SICKNESS_RATE_THRESHOLD,
} from 'utils/constants';
import { CloseCaseModal } from './close-case-modal';
import { ComplianceCase, Employee } from 'models';
import { NoShowsTrackerItem } from './termination-tracker-item/no-shows-tracker-item';
import { InactivityTrackerItem } from './termination-tracker-item/inactivity-tracker-item';
import { track } from 'utils/tracking';

interface CaseDecisionApprovalProps {
  employee: Employee;
  complianceCase: ComplianceCase;
  sicknessRateCritical: boolean;
  onSuccess: (editedFields) => void;
}

export const CaseDecisionApproval = ({
  employee,
  complianceCase,
  sicknessRateCritical,
  onSuccess,
}: CaseDecisionApprovalProps): JSX.Element => {
  const { t } = useTranslation();
  const [confirmationModal, setConfirmationModal] = useState(false);

  const [info, setInfo] = useState<null | {
    type: 'success' | 'error';
    message: string;
  }>();

  return (
    <div className="grid grid-cols-[1fr,_3fr]  gap-1">
      <div className="p-8 border-r-2 border-solid border-secondary">
        <div className="flex flex-col gap-2 items-start">
          {complianceCase.type === ComplianceCaseType.INACTIVITY ? (
            <InactivityTrackerItem
              daysInactive={complianceCase?.daysInactive}
              lastActivityAt={complianceCase?.lastActivityAt || ''}
            />
          ) : (
            <NoShowsTrackerItem
              count={
                complianceCase?.noShows?.filter((ns) => ns.type === 'UNEXCUSED')
                  ?.length
              }
            />
          )}
          {sicknessRateCritical && (
            <Badge variant={BadgeVariant['light-red']}>
              {t('sickness_rate_threshold', {
                threshold: SICKNESS_RATE_THRESHOLD,
              })}
            </Badge>
          )}
        </div>
      </div>
      <div className="text-s p-8 flex flex-col gap-4">
        {info?.type === 'success' ? (
          <div className="flex gap-2">
            <div className="text-pink">
              <Icon type="check" />
            </div>
            <Trans i18nKey="compliance_case_termination_requested_confirmation_rejected" />
          </div>
        ) : (
          <>
            <Trans i18nKey="compliance_case_termination_requested_confirmation_info" />
            <div>
              <Button
                variant={ButtonVariant.primary}
                onClick={() => {
                  track('click', {
                    component_content: 'reject',
                    component_name: 'termination_tracker',
                    screen_name: 'compliance',
                    component_value: `${employee?.ecID}`,
                  });
                  setConfirmationModal(true);
                }}
              >
                {t('reject')}
              </Button>
            </div>
          </>
        )}
        <CloseCaseModal
          open={confirmationModal}
          workforceID={employee.workforceID}
          caseID={complianceCase.id}
          onSuccess={() => {
            setInfo({ type: 'success', message: '' });
            onSuccess({ status: ComplianceCaseStatus.TERMINATION_REJECTED }); // TODO: check in case case status designs change
          }}
          onClose={() => setConfirmationModal(false)}
        />
        {info?.type === 'error' && (
          <div className="text-pink text-xs">{t(info.message)}</div>
        )}
      </div>
    </div>
  );
};
