import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, Button, ButtonVariant, Loading } from '@hubportal/components';
import { Info } from 'components/info';

export const getErrorMessage = (t: any, err: any): string => {
  const validationFields = Object.keys(err?.response?.data?.fields || {});
  const error = err?.response?.data?.error || '';

  const errorMessage = error
    ? validationFields?.length
      ? error.concat(': ').concat(validationFields)
      : error
    : '';

  return t(errorMessage) || t(err.message);
};

export const SyncWorkforceProfileModal = ({
  open,
  onClose,
  onSubmit,
}: {
  open: boolean;
  onClose: () => void;
  onSubmit: () => Promise<void>;
}): JSX.Element => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const [info, setInfo] = useState<null | {
    type: 'success' | 'error';
    message: string;
  }>();

  const submit = async (): Promise<void> => {
    try {
      setInfo(null);
      setLoading(true);
      await onSubmit();
      setInfo({ type: 'success', message: t('sync_profile_success') });
    } catch (err: any) {
      setInfo({ type: 'error', message: getErrorMessage(t, err) });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      open={open}
      onClose={() => {
        onClose();
      }}
    >
      <div className="w-96 flex flex-col gap-4 overflow-auto min-h-[8rem] justify-center">
        {loading ? (
          <Loading />
        ) : info?.message ? (
          <div>
            <Info type={info?.type}>
              <div className="detail-l">{t(info?.message)}</div>
            </Info>
          </div>
        ) : (
          <>
            <div className="text-left flex flex-col gap-4">
              {t('sync_profile_confirmation')}
            </div>
            <div className="flex gap-2 justify-between mt-auto">
              <Button variant={ButtonVariant.quinary} onClick={onClose}>
                {t('cancel')}
              </Button>
              <Button
                disabled={loading}
                loading={loading}
                onClick={() => {
                  submit();
                }}
              >
                {t('sync_profile')}
              </Button>
            </div>
          </>
        )}
      </div>
    </Modal>
  );
};
