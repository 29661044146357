import { Trans, useTranslation } from 'react-i18next';
import { ComplianceCaseStatus } from 'utils/constants';
import { ComplianceCase } from 'models';
import { NoShowsTrackerItem } from './termination-tracker-item/no-shows-tracker-item';
import { WarningsTrackerItem } from './termination-tracker-item/warnings-tracker-item';
import { InactivityTrackerItem } from './termination-tracker-item/inactivity-tracker-item';

interface CaseBuildingProps {
  complianceCase: ComplianceCase;
  warnings: Warning[];
  isWarningsModuleEnabled?: boolean;
}

export const CaseBuilding = ({
  complianceCase,
  warnings,
  isWarningsModuleEnabled,
}: CaseBuildingProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div className="grid grid-cols-[3fr,_2fr] gap-1">
      <div
        className={`p-8 border-solid border-secondary ${
          isWarningsModuleEnabled ||
          complianceCase.status === ComplianceCaseStatus.TERMINATION_REQUESTED
            ? 'border-r-2'
            : ''
        }`}
      >
        <div className={`grid grid-cols-3 gap-4`}>
          <NoShowsTrackerItem
            count={
              complianceCase?.noShows?.filter((ns) => ns.type === 'UNEXCUSED')
                ?.length
            }
          />
          {isWarningsModuleEnabled && (
            <WarningsTrackerItem count={warnings?.length} />
          )}
          <InactivityTrackerItem
            daysInactive={complianceCase?.daysInactive}
            lastActivityAt={complianceCase?.lastActivityAt || ''}
          />
        </div>
      </div>
      <div className="text-s p-8">
        {complianceCase.status ===
        ComplianceCaseStatus.TERMINATION_REQUESTED ? (
          t('termination_tracker_termination_requested_description')
        ) : isWarningsModuleEnabled ? (
          <Trans i18nKey="termination_tracker_not_eligible_description">
            This employee will be eligible for termination if they meet all of
            these criteria::
            <ul className="list-disc ml-4">
              <li>3 or more unexcused no shows with at least 2 warnings</li>
            </ul>
          </Trans>
        ) : (
          <div></div>
        )}
      </div>
    </div>
  );
};
