import { parse, differenceInYears } from 'date-fns';
import {
  block,
  resetPassword,
  unblock,
  updateAgency,
  updateEmail,
  updateHub,
  updatePhoneNumber,
  updateRiderStateOffline,
  updateRiderStateOnline,
  updateRiderStateTempOffline,
} from 'utils/network/apis';
import {
  RiderStateColors,
  RiderStates,
  UNDERAGE_RESTRICTED_START_TIME,
  VehicleTypes,
} from 'utils/constants';
import { t } from 'i18next';
import { getTimeNow, isInThePast } from 'utils/helpers';

export class Employee {
  workforceID: string;
  ecID?: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  status: RiderStates;
  statusReason?: string;
  statusUpdatedAt: string;
  hubSlug: string;
  homeHub?: string;
  isBlocked: boolean;
  blockingReasons?: { reason: string }[];
  isEnding: boolean;
  isShortTerm: boolean;
  jobTitle?: string;
  externalAgency?: string;
  hireDate?: string;
  dateOfBirth?: string;
  probationEndDate?: string;
  terminatedAt?: string;
  isInternal?: string;
  vacationBalanceLeft?: number;
  vehicleType?: VehicleTypes;
  vehicleSKU?: string;
  VehicleNumberOfSContainers?: number;

  private readonly updateCallback?: (values: any) => void;

  // eslint-disable-next-line default-param-last
  constructor(details: any = {}, updateCallback?: (values: any) => void) {
    this.workforceID = details.auth0_id;
    this.ecID = details.ec_id;
    this.firstName = details.first_name;
    this.lastName = details.last_name;
    this.email = details.email;
    this.phoneNumber = details.phone_number;
    this.status = details.status;
    this.statusReason = details.status_reason;
    this.statusUpdatedAt = details.status_updated_at;
    this.hubSlug = details.hub_slug;
    this.homeHub = details.home_hub;
    this.isBlocked = details.is_blocked;
    this.isEnding = details.is_ending;
    this.isShortTerm = details.is_short_term;
    this.jobTitle = details.job_title;
    this.externalAgency = details.external_agency_name;
    this.hireDate = details.hire_date;
    this.dateOfBirth = details.date_of_birth;
    this.probationEndDate = details.probation_end_date;
    this.terminatedAt = details.terminated_at?.startsWith('0001-01-01')
      ? null
      : details.terminated_at;

    this.isInternal = details.ec_id && !details.is_short_term;
    this.vacationBalanceLeft = details.available_vacation_days;
    this.vehicleType = details.vehicle?.type;
    this.vehicleSKU = details.vehicle?.sku;
    this.VehicleNumberOfSContainers = details.vehicle?.number_of_s_containers;

    const reasons = (details.blocking_reasons || [])?.map((reason) => ({
      reason: reason.reason,
    }));

    if (
      reasons.findIndex((r) => r.reason === 'OTHER') > -1 &&
      reasons.findIndex((r) => r.reason === 'IP_BLOCKED') > -1
    ) {
      this.blockingReasons = [
        ...reasons.filter((r) => !['OTHER', 'IP_BLOCKED'].includes(r.reason)),
        { reason: 'OTHER_AND_IP_BLOCKED' },
      ];
    } else {
      this.blockingReasons = reasons;
    }

    this.updateCallback = updateCallback;
  }

  public static from(
    details: any,
    updateCallback?: (values: any) => void
  ): Employee {
    return new Employee(details, updateCallback);
  }

  public clone(): Employee {
    return new Employee(
      {
        auth0_id: this.workforceID,
        ec_id: this.ecID,
        first_name: this.firstName,
        last_name: this.lastName,
        email: this.email,
        phone_number: this.phoneNumber,
        status: this.status,
        status_reason: this.statusReason,
        status_updated_at: this.statusUpdatedAt,
        hub_slug: this.hubSlug,
        home_hub: this.homeHub,
        is_blocked: this.isBlocked,
        blocking_reasons: this.blockingReasons,
        is_ending: this.isEnding,
        job_title: this.jobTitle,
        external_agency_name: this.externalAgency,
        hire_date: this.hireDate,
        date_of_birth: this.dateOfBirth,
        probation_end_date: this.probationEndDate,
        terminated_at: this.terminatedAt,
        is_short_term: this.isShortTerm,
        available_vacation_days: this.vacationBalanceLeft,
      },
      this.updateCallback
    );
  }

  public getFullName(): string {
    return `${this.firstName || ''} ${this.lastName || ''}`;
  }

  public getAbrvName(): string {
    return `${this.firstName} ${this.lastName?.trim()[0]}.`;
  }

  public getVehicleIcon(): string {
    switch (this.vehicleType) {
      case VehicleTypes.BICYCLE:
        return 'bike';
      case VehicleTypes.CAR:
        return 'car';
      case VehicleTypes.SCOOTER:
        return 'scooter';
      case VehicleTypes.CARGO:
        return 'cargo';
      default:
        return 'notAssignedVehicle';
    }
  }

  public getVehicleIconColor(): string {
    switch (this.vehicleType) {
      case VehicleTypes.BICYCLE:
        return 'text-pink-600';
      case VehicleTypes.CAR:
        return 'text-purple-500';
      case VehicleTypes.SCOOTER:
        return 'text-sky-500';
      case VehicleTypes.CARGO:
        return 'text-yellow-400';
      default:
        return 'text-gray-400';
    }
  }

  public getField(
    key: 'email' | 'ecID' | 'phoneNumber' | 'jobTitle' | 'hubSlug'
  ): string {
    return (this[key] ? this[key] : '-') as string;
  }

  public getState(): string {
    return [
      t(`rider_status_${this.status?.toLowerCase()}`),
      this.getStateReason(),
    ]
      .filter((s) => s)
      .join(' / ');
  }

  public getStateMizimized(): string {
    if (
      this.status === RiderStates.TEMP_OFFLINE ||
      (this.status === RiderStates.ONLINE && this.isEnding)
    ) {
      return this.getStateReason();
    }
    return t(`rider_status_${this.status?.toLowerCase()}`);
  }

  public isDeclining(): boolean {
    return this.status === RiderStates.TEMP_OFFLINE && !this.statusReason;
  }

  public isUnderage(): boolean {
    return (
      !!this?.dateOfBirth &&
      differenceInYears(
        new Date(),
        parse(this.dateOfBirth, 'yyyy-MM-dd', new Date())
      ) < 18
    );
  }

  public isTerminated(): boolean {
    return this.terminatedAt ? isInThePast(new Date(this.terminatedAt)) : false;
  }

  public isProbationPassed(): boolean {
    return this.probationEndDate
      ? isInThePast(new Date(this.probationEndDate))
      : false;
  }

  public getStateColor(): string {
    if (this.status === RiderStates.TEMP_OFFLINE && this.statusReason) {
      return 'bg-orange-400';
    }
    return RiderStateColors[this.status] || 'bg-gray-300';
  }

  public isSetTempOfflineEnabled(): boolean {
    return (
      this.status === RiderStates.ONLINE ||
      (this.status === RiderStates.ON_BREAK &&
        (this.hubSlug?.startsWith('nl') || this.hubSlug?.startsWith('fr')))
    );
  }

  public isSetOnlineEnabled(): boolean {
    if (this.status !== RiderStates.TEMP_OFFLINE) {
      return false;
    }

    if (
      this?.hubSlug?.startsWith('nl') &&
      getTimeNow() >= UNDERAGE_RESTRICTED_START_TIME &&
      this.isUnderage()
    ) {
      return false;
    }

    return true;
  }

  public async changeEmail(email: string): Promise<void> {
    await updateEmail(this.workforceID, { email });
    this.email = email;
    this.updateCallback && this.updateCallback(this);
  }

  public async changePhoneNumber(phoneNumber: string): Promise<void> {
    await updatePhoneNumber(this.workforceID, {
      ...(phoneNumber ? { phone_number: phoneNumber } : {}),
    });
    this.phoneNumber = phoneNumber;
    this.updateCallback && this.updateCallback(this);
  }

  public async changeHub(hubSlug: string): Promise<void> {
    await updateHub(this.workforceID, { hub_slug: hubSlug });
    this.hubSlug = hubSlug;
    this.updateCallback && this.updateCallback(this);
  }

  public async changeAgency(agency: string): Promise<void> {
    await updateAgency(this.workforceID, { name: agency });
    this.externalAgency = agency;
    this.updateCallback && this.updateCallback(this);
  }

  public async block(reason: string = 'OTHER'): Promise<void> {
    await block(this.workforceID, reason);
    this.isBlocked = true;

    const reasons = [...(this.blockingReasons || []), { reason }];

    if (
      reasons.findIndex((r) => r.reason === 'OTHER') > -1 &&
      reasons.findIndex((r) => r.reason === 'IP_BLOCKED') > -1
    ) {
      this.blockingReasons = [
        ...reasons.filter((r) => !['OTHER', 'IP_BLOCKED'].includes(r.reason)),
        { reason: 'OTHER_AND_IP_BLOCKED' },
      ];
    } else {
      this.blockingReasons = reasons;
    }

    this.updateCallback && this.updateCallback(this);
  }

  public async unblock(reason: string): Promise<void> {
    let data = {};

    if (reason === 'OTHER_AND_IP_BLOCKED') {
      data = {
        reasons: [
          {
            reason: 'OTHER',
            action: 'REMOVE',
          },
          {
            reason: 'IP_BLOCKED',
            action: 'REMOVE',
          },
        ],
      };
    } else {
      data = {
        reasons: [
          {
            reason,
            action: 'REMOVE',
          },
        ],
      };
    }

    await unblock(this.workforceID, data);
    this.blockingReasons = this.blockingReasons?.filter(
      (r) => r.reason !== reason
    );
    this.isBlocked = (this.blockingReasons || []).length > 0;
    this.updateCallback && this.updateCallback(this);
  }

  public async setOnline(): Promise<void> {
    await updateRiderStateOnline(this.workforceID);
    this.status = RiderStates.ONLINE;
    this.updateCallback && this.updateCallback(this);
  }

  public async setOffline(): Promise<void> {
    await updateRiderStateOffline(this.workforceID);
    this.status = RiderStates.OFFLINE;
    this.updateCallback && this.updateCallback(this);
  }

  public async setTempOffline(reason?: string): Promise<void> {
    await updateRiderStateTempOffline(this.workforceID, {
      ...(reason ? { reason } : null),
    });
    this.status = RiderStates.TEMP_OFFLINE;
    this.statusReason = reason;
    this.updateCallback && this.updateCallback(this);
  }

  public async resetPassword(): Promise<void> {
    await resetPassword(this.email);
  }

  private getStateReason(): string {
    if (this.status === RiderStates.TEMP_OFFLINE) {
      return this.statusReason
        ? t(
            `rider_status_temp_offline_reason_${this.statusReason?.toLowerCase()}`
          )
        : t('rider_status_temp_offline_reason_declining');
    }
    if (this.status === RiderStates.ONLINE) {
      if (this.isEnding) {
        return t(`rider_status_online_reason_ending`);
      }
    }
    return '';
  }
}
