import { useTranslation } from 'react-i18next';
import { Icon } from '@hubportal/components';

export const Back = ({ onClick }: { onClick: () => void }): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div
      className="inline-flex gap-4 items-center self-start text-white cursor-pointer"
      onClick={onClick}
    >
      <div className="rounded-full bg-primary p-2 ">
        <Icon type="arrowSignLeft" />
      </div>
      <span>{t('back')}</span>
    </div>
  );
};
