import './index.css';

const Spinner = (): JSX.Element => {
  return (
    <div className="text-center">
      <div className="spinner">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};

export { Spinner };
