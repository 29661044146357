import create from 'zustand';
import { Permission } from 'utils/hooks/usePermissions';

type Country = 'DE' | 'AT' | 'FR' | 'NL';

type Permissions = {
  [key in Permission]?: boolean;
};

type Store = {
  country: Country;
  selectedHub: { slug: string };
  hubs: { id: string }[];
  countries: { id: string }[];
  permissions: Permissions;
  permissionsReady: boolean;
  allowedHubs: string[];
  setHubs: (hubs: { id: string }[]) => void;
  setCountry: (country: Country) => void;
  setSelectedHub: (hub: { slug: string }) => void;
  setPermissions: (permissions: Permissions) => void;
  setAllowedHubs: (hubs: string[]) => void;
};

const useStore = create<Store>((set) => ({
  country: 'DE',
  selectedHub: { slug: '' },
  hubs: [],
  countries: [{ id: 'de' }, { id: 'at' }, { id: 'fr' }, { id: 'nl' }],
  permissions: {},
  permissionsReady: false,
  allowedHubs: [],
  setHubs: (hubs) => set({ hubs }),
  setCountry: (country) => set({ country }),
  setSelectedHub: (selectedHub) => set({ selectedHub }),
  setPermissions: (permissions) => set({ permissions, permissionsReady: true }),
  setAllowedHubs: (allowedHubs) => set({ allowedHubs }),
}));

export default useStore;
export const { getState } = useStore;
