import { useTranslation } from 'react-i18next';
import {
  Table,
  TableSortBy,
  TableVariant,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@hubportal/components';
import { Spinner } from 'components/spinner';
import { Employee } from 'models';

export const AllEmployeesTable = ({
  data,
  loading,
  sortBy,
  onSortChange,
}: {
  data: Employee[];
  loading?: boolean;
  sortBy: TableSortBy;
  onSortChange: (sb: TableSortBy) => void;
}): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col">
      <Table
        variant={TableVariant.secondary}
        sortLabels={{
          asc: t('sort_ascending'),
          desc: t('sort_descending'),
        }}
        sortBy={sortBy}
        onSortChange={onSortChange}
        gridTemplateColumns={`
        minmax(150px, 1fr)
        minmax(150px, 1fr)
        minmax(150px, 1fr)
        minmax(150px, 1fr)
    `}
      >
        <Thead>
          <Tr>
            <Th name="NAME" sortable>
              {t('name')}
            </Th>
            <Th name="JOB_TITLE" sortable>
              {t('position')}
            </Th>
            <Th>{t('hub')}</Th>
            <Th name="STATUS" sortable>
              {t('status')}
            </Th>
          </Tr>
        </Thead>
        {!loading && (
          <Tbody>
            {data.map((employee) => {
              return (
                <Tr
                  key={employee?.workforceID}
                  href={`/workforce/${employee.workforceID}`}
                  target="_self"
                >
                  <Td>
                    <span className="title-xs">{employee.getFullName()}</span>
                  </Td>
                  <Td>{employee.getField('jobTitle')}</Td>
                  <Td>{employee.getField('hubSlug')}</Td>
                  <Td>
                    <div className="flex items-center gap-1">
                      <span
                        className={`w-1 h-1 rounded shrink-0 ${employee.getStateColor()}`}
                      />
                      {employee.getStateMizimized()}
                    </div>
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        )}
      </Table>
      {loading && (
        <div className="bg-primary/50 min-h-[24rem] w-full col-span-8 flex items-center justify-center rounded-b-lg">
          <Spinner />
        </div>
      )}
    </div>
  );
};
