import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { differenceInDays, format } from 'date-fns';
import { Snackbar, SnackbarVariant } from '@hubportal/components';
import { Spinner } from 'components/spinner';
import { Notes as NotesV2 } from 'partials/notes/v2';
import { SicknessRate } from 'partials/sickness-rate';
import { ShiftCompliance } from 'partials/shift-compliance';
import { Help } from 'partials/help';
import { Employee, Sendout } from 'models';
import {
  getSendouts,
  getComments,
  getWorkforceComplianceCases,
  getNoShowDays,
  getManualWarnings,
} from 'utils/network/apis';
import {
  isComplianceViewEnabled,
  getComplianceJobTitles,
} from 'utils/eppo/helpers';
import usePermissions, { Permission } from 'utils/hooks/usePermissions';
import withMFA from 'utils/hoc/withMFA';
import { ComplianceCaseStatus, ComplianceCaseType } from 'utils/constants';
import { ComplianceTracker } from 'partials/compliance-tracker';
import {
  getCaseCopy,
  getCommentsCopy,
  getInactivityCopy,
  getNoShowsCopy,
  getWarningsCopy,
} from 'utils/helpers';
import { NoShow } from 'models/no-show';

const WorkforceComplianceFC = (): JSX.Element => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { id } = useParams();
  const { employee, employeeError } = useOutletContext<{
    employee: Employee | null;
    employeeError?: string;
  }>();
  const { isAllowed, isAllowedStrict } = usePermissions();

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [snackbar, setSnackbar] = useState({ visible: false, message: '' });

  const [tracker, setTracker] = useState<ComplianceTracker>({
    status: '',
    should_be_terminated: false,
  });
  const [complianceCases, setComplianceCases] = useState<ComplianceCase[]>([]);
  const [comments, setComments] = useState<Comment[]>([]);
  const [sendouts, setSendouts] = useState<Sendout[]>([]);
  const [manualWarnings, setManualWarnings] = useState<ManualWarning[]>([]);
  const [noShows, setNoShows] = useState<NoShow[]>([]);

  const complianceJobTitles = useMemo(() => getComplianceJobTitles(), []);

  const getCommentsList = async (): Promise<void> => {
    const response = await getComments(id);
    setComments(response?.results);
  };

  const getSendoutsList = async (): Promise<void> => {
    const response = await getSendouts(id);
    setSendouts(
      response?.results
        ?.map(Sendout.from)
        .filter((s: Sendout) => s.isWarning())
        .sort(
          (s1: Sendout, s2: Sendout) =>
            new Date(s2.sendAt).getTime() - new Date(s1.sendAt).getTime()
        )
    );
  };

  const getManualWarningsList = async (): Promise<void> => {
    const response = await getManualWarnings(id);
    setManualWarnings(response?.results);
  };

  const getData = async (): Promise<void> => {
    try {
      const response = await getWorkforceComplianceCases(id);

      setTracker(response.tracker);
      setComplianceCases(response.cases || []);
      await Promise.all([
        getSendoutsList(),
        getManualWarningsList(),
        getCommentsList(),
      ]);
    } catch (err: any) {
      if (err?.response?.status !== 404) {
        throw err;
      }
    }
  };

  const onLoad = async (): Promise<void> => {
    try {
      setError('');
      setLoading(true);
      await getData();
    } catch (err: any) {
      setError(err?.message);
    } finally {
      setLoading(false);
    }
  };

  const isComplianceEnabled = useMemo(() => {
    return (
      employee?.isInternal &&
      (isAllowed(Permission.READ_COMPLIANCE_ALL) ||
        isAllowedStrict(
          Permission.READ_COMPLIANCE_ALLOWED,
          employee?.homeHub
        ) ||
        isAllowedStrict(
          Permission.READ_COMPLIANCE_LIMITED,
          employee?.homeHub
        )) &&
      complianceJobTitles?.includes(employee?.jobTitle || '') &&
      isComplianceViewEnabled()
    );
  }, [employee]);

  useEffect(() => {
    if (id && isComplianceEnabled) {
      onLoad();
    }
  }, [id, isComplianceEnabled]);

  useEffect(() => {
    if (employee?.workforceID && !isComplianceEnabled) {
      navigate(location.pathname?.replace('/compliance', ''), {
        replace: true,
      });
    }
  }, [employee]);

  const warnings = useMemo(() => {
    const acc: Warning[] = [];

    for (const cs of complianceCases) {
      for (const item of cs.items || []) {
        for (const sendout of item?.sendouts || []) {
          if (sendout.type === 'warning' && sendout.delivered_at) {
            acc.push({
              type: cs.type,
              at: item.date,
              delivered_at: sendout.delivered_at,
            });
          }
        }
      }
    }

    return acc.sort((a, b) => {
      return b.delivered_at.localeCompare(a.delivered_at);
    });
  }, [complianceCases]);

  const onSuccess = async (editedFields): Promise<void> => {
    const response = await getWorkforceComplianceCases(id);
    setTracker(response.tracker);
    setComplianceCases(response.cases || []);
    getCommentsList();
  };

  const onExcuseNoShowSuccess = async (): Promise<void> => {
    const response = await getWorkforceComplianceCases(id);
    setTracker(response.tracker);
    setComplianceCases(response.cases || []);
  };

  const isWarningsModuleEnabled = useMemo(() => {
    return (
      employee?.homeHub?.startsWith('de') || employee?.hubSlug?.startsWith('de')
    );
  }, [employee]);

  const getNoShows = async (ids: string[]): Promise<void> => {
    const response = await getNoShowDays(employee?.workforceID, { ids });
    setNoShows(response?.results?.map(NoShow.from).reverse() || []);
  };

  useEffect(() => {
    const ids = complianceCases
      .find((c) => c.type === ComplianceCaseType.NO_SHOW)
      ?.items?.map((ns) => ns.id);
    if (ids && ids?.length > 0) {
      getNoShows(ids);
    }
  }, [complianceCases?.length]);

  const getCaseCopyText = (): string => {
    const inactivityCase = complianceCases.find(
      (c) => c.type === ComplianceCaseType.INACTIVITY
    );

    const lastActivityAt = inactivityCase?.extra?.last_activity_at;
    const daysInactive = lastActivityAt
      ? differenceInDays(new Date(), new Date(lastActivityAt || 0))
      : 0;

    return getCaseCopy({
      ecId: employee?.ecID,
      startDate: format(new Date(employee?.hireDate || 0), 'dd/MM/yy'),
      probationStatus: employee?.probationEndDate
        ? employee.isProbationPassed()
          ? t('probation_passed')
          : t('probation_ongoing')
        : '-',
      comments: getCommentsCopy(comments),
      warnings: getWarningsCopy(warnings, t),
      noShowsCount: noShows.filter((ns) => ns.type === 'UNEXCUSED').length,
      noShows: getNoShowsCopy(
        noShows.filter((ns) => ns.type === 'UNEXCUSED'),
        t
      ),
      inactivity: getInactivityCopy(daysInactive, lastActivityAt, t),
    });
  };

  return (
    <>
      {!employeeError && loading && <Spinner />}
      {error && <span className="text-xs text-pink">{error}</span>}
      {!loading && !error ? (
        <div className="grid grid-cols-[minmax(75%,_960px)] gap-8">
          <div className="flex flex-col gap-8">
            {employee?.isTerminated() ||
            tracker.status === ComplianceCaseStatus.TERMINATED ? (
              <div className="p-8 text-red bg-red-100 rounded-lg">
                {t('termination_tracker_terminated_description', {
                  date: format(
                    new Date(employee?.terminatedAt || 0),
                    'dd.MM.yyyy'
                  ),
                })}
              </div>
            ) : (
              complianceCases?.length > 0 && (
                <ComplianceTracker
                  terminatedAt={employee?.terminatedAt}
                  tracker={tracker}
                  cases={complianceCases}
                  ecID={employee?.ecID as string}
                  workforceID={employee?.workforceID as string}
                  sicknessRateCritical={tracker.should_be_terminated}
                  getCaseCopy={getCaseCopyText}
                  onSuccess={onSuccess}
                  setSnackbar={setSnackbar}
                />
              )
            )}

            <div className="">
              <div className="flex flex-col gap-8 min-w-[24rem]">
                <ShiftCompliance
                  employee={employee}
                  isWarningsModuleEnabled={!!isWarningsModuleEnabled}
                  complianceCaseStatus={tracker.status}
                  onExcuseNoShowSuccess={onExcuseNoShowSuccess}
                />

                <SicknessRate id={id} />
                {isWarningsModuleEnabled ? (
                  <NotesV2
                    warnings={sendouts}
                    manualWarnings={manualWarnings}
                  />
                ) : (
                  <></>
                )}
              </div>

              <div className="[&>div]:z-10">
                <Snackbar
                  open={snackbar.visible}
                  variant={SnackbarVariant.success}
                  icon="verification"
                  onClose={() => setSnackbar({ visible: false, message: '' })}
                >
                  {t(snackbar.message)}
                </Snackbar>
              </div>
            </div>
            <Help />
          </div>
          <div />
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

const WorkforceCompliance = withMFA(WorkforceComplianceFC);
export { WorkforceCompliance };
