import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { importInternalRider } from 'utils/network/apis';
import { CountrySelect, HubsSelect } from 'partials/select';
import {
  BIRTHDAY_MASKED_DATE_OPTIONS,
  BIRTHDAY_REGEX,
  getErrorMessage,
  PHONE_NUMBER_REGEX,
  zeroPad,
} from 'utils/helpers';
import {
  Badge,
  BadgeVariant,
  Button,
  ButtonVariant,
  Icon,
  Input,
  InputVariant,
  MASKED_DATE_OPTIONS,
  Modal,
  ModalSize,
  Radio,
  RadioGroup,
  SelectVariant,
} from '@hubportal/components';
import {
  ControlledAccordion,
  AccordionItem,
  useAccordionProvider,
} from '@szhsin/react-accordion';
import { Info } from 'components/info';
import { Account } from 'models';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import omit from 'lodash.omit';
import * as Yup from 'yup';

type EditableRiderFields = {
  name: string;
  surname: string;
  phone: string;
  email: string;
  hub: { id: string; label: string };
  country: { id: string; label: string };
  birthday: string;
  source: string;
  auth0Id?: string;
};

const initialValues: EditableRiderFields = {
  name: '',
  surname: '',
  phone: '',
  email: '',
  hub: { id: '', label: '' },
  country: { id: '', label: '' },
  birthday: '',
  source: '',
  auth0Id: '',
};

const getValidationSchema = (t): object =>
  Yup.object({
    name: Yup.string().required(t('required')),
    surname: Yup.string().required(t('required')),
    email: Yup.string().email(t('invalid_email')).required(t('required')),
    phone: Yup.string()
      .matches(RegExp(PHONE_NUMBER_REGEX), t('invalid_phone_number'))
      .required(t('required')),
    hub: Yup.object({
      id: Yup.string().required(t('required')),
      label: Yup.string(),
    }).required(),
    country: Yup.object({
      id: Yup.string().required(t('required')),
      label: Yup.string(),
    }).required(),
    birthday: Yup.string()
      .matches(RegExp(BIRTHDAY_REGEX), t('invalid_date'))
      .when('country', {
        is: ({ label }: { label: string }) => label === 'NL',
        then: (schema) => schema.required(t('required')),
      }),
  });

const AccountSummary = ({ account }: { account: Account }): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col items-start gap-1 w-full -mt-0.5">
      <div className=" flex justify-between gap-10 w-full">
        <div className="title-s text-white">
          {account.firstName} {account.lastName}
        </div>
        <Link
          to={`/workforce/${account.auth0Id}`}
          target="_blank"
          className="text-pink-500 underline"
        >
          {t('employee_profile')}
        </Link>
      </div>
      <div className="">{account.email}</div>
      <div className="text-xs flex flex-col gap-1">
        <div>{account.cellPhone}</div>
      </div>
      <div className="mt-2 flex gap-2">
        {account.isBlocked && (
          <Badge variant={BadgeVariant.red}>{t('blocked')}</Badge>
        )}
        {account.isTerminated() && (
          <Badge variant={BadgeVariant.red}>{t('terminated')}</Badge>
        )}
      </div>
      <div className="detail-l grid grid-cols-5 gap-5 items-start my-2">
        <div className="flex flex-col items-start gap-1">
          <div className="text-white">{t('home_hub')}</div>
          <div>{account.homeHub || '-'}</div>
        </div>
        <div className="flex flex-col items-start gap-1">
          <div className="text-white">{t('position')}</div>
          <div>{account.jobTitle || '-'}</div>
        </div>
        <div className="flex flex-col items-start gap-1">
          <div className="text-white">{t('ec_id')}</div>
          <div>{account.ecID || '-'}</div>
        </div>
        <div className="flex flex-col items-start gap-1">
          <div className="text-white">{t('external_agency')}</div>
          <div>{account.externalAgency || '-'}</div>
        </div>
        <div className="flex flex-col items-start gap-1">
          <div className="text-white">{t('contract_end_date')}</div>
          <div>
            {account?.contractEndDate
              ? format(new Date(account.contractEndDate), 'dd.MM.yyyy')
              : '-'}
          </div>
        </div>
      </div>
      {account?.message && (
        <div className="flex items-center gap-1">
          <div className="text-pink flex items-center justify-center">
            <Icon type="infoSquare" size="small" />
          </div>
          <div>{t(`error_${account?.message?.toLowerCase()}`)}</div>
        </div>
      )}
    </div>
  );
};

export const MultipleAccountView = ({
  open,
  ecID,
  source,
  account,
  existingAccounts,
  onSuccess,
  onClose,
}: {
  open: boolean;
  ecID: string;
  source: string;
  account: Account;
  existingAccounts: Account[];
  onSuccess: () => void;
  onClose: () => void;
}): JSX.Element => {
  const { t } = useTranslation();
  const formRef = useRef<HTMLDivElement>(null);

  const [selectedAccount, setSelectedAccount] = useState<number | string>();
  const [error, setError] = useState<string | null>();

  const formik = useFormik({
    initialValues: {
      ...initialValues,
    },
    validateOnMount: false,
    validationSchema: getValidationSchema(t),
    onSubmit: async () => {
      try {
        formik.setSubmitting(true);
        setError(null);

        await importInternalRider(
          {
            ...(formik.values.auth0Id
              ? { auth0_id: formik.values.auth0Id }
              : {}),
            first_name: formik.values.name,
            last_name: formik.values.surname,
            email: formik.values.email,
            phone_number: formik.values.phone,
            hub_slug: formik.values.hub.id?.toLowerCase(),
            country: formik.values.country.id?.toUpperCase(),
            date_of_birth: formik.values.birthday,
            source: formik.values.source,
            ec_id: account.ecID || ecID,
            job_title: account?.jobTitle,
            is_short_term: account?.isShortTerm,
          },
          formik.values.auth0Id ? { skip_check: true } : null
        );

        onSuccess();
      } catch (err: any) {
        setError(getErrorMessage(t, err));
      } finally {
        formik.setSubmitting(false);
      }
    },
  });

  const providerValue = useAccordionProvider({
    allowMultiple: true,
    transition: true,
    transitionTimeout: 250,
  });

  const { toggle } = providerValue;

  useEffect(() => {
    toggle('item-1', selectedAccount === 'import');

    setTimeout(() => {
      if (selectedAccount === 'import') {
        formRef.current?.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
          inline: 'nearest',
        });
      }
    }, 200);
  }, [selectedAccount]);

  useEffect(() => {
    const selected =
      (selectedAccount as number) >= 0
        ? existingAccounts[selectedAccount as number]
        : account;

    const hub = selected?.hubSlug || '';
    const country = hub.slice(0, 2);

    formik.setValues(
      {
        ...formik.values,
        name: selected?.firstName || '',
        surname: selected?.lastName || '',
        phone: selected?.cellPhone || '',
        email: selected?.email || '',
        hub: { id: hub || '', label: hub || '' },
        country: {
          id: country.toLowerCase(),
          label: t(`countries_${country.toLowerCase()}`),
        },
        birthday: selected?.dateOfBirth || '',
        source: selected?.source || '',
        auth0Id: selected?.auth0Id || '',
      },
      true
    );
  }, [account, selectedAccount]);

  const handleClose = (): void => {
    if (!formik.isSubmitting) {
      setError(null);
      onClose();
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      header={
        <div className="title-s max-w-2xl">
          {t('import_internal_rider_multiple_accounts')}
        </div>
      }
      size={ModalSize.large}
    >
      <>
        <div className="mt-10 min-h-0 overflow-y-auto flex flex-col">
          <RadioGroup
            name="accounts"
            value={selectedAccount}
            onChange={(r) => setSelectedAccount(r)}
          >
            {existingAccounts.map((a, i) => {
              return (
                <Radio
                  key={i}
                  id={`accounts-profile-${i}`}
                  value={i}
                  disabled={
                    a?.message
                      ? [
                          'CANT_REPLACE_EXTERNAL_FROM_QX',
                          'CANT_REPLACE_INTERNAL_FROM_QX',
                        ].includes(a.message)
                      : false
                  }
                >
                  <AccountSummary account={a} />
                </Radio>
              );
            })}
          </RadioGroup>
          <div className="grid grid-cols-[1fr,_max-content,_1fr] items-center gap-5 my-5">
            <div className="h-px bg-secondary w-full" />
            <div className="title-s text-white">OR</div>
            <div className="h-px bg-secondary w-full" />
          </div>

          <div ref={formRef}>
            <Radio
              name="accounts"
              value="import"
              checked={selectedAccount === 'import'}
              onChange={() => {
                setSelectedAccount('import');
              }}
            >
              <div className="flex flex-col gap-5 items-start -mt-0.5">
                <div className="title-s text-white flex flex-col gap-1 items-start">
                  <div>
                    {t('ec_id')}: {zeroPad(ecID, 8)}
                  </div>
                  <div className="text-gray-300 detail-l">
                    {t('source')}:{' '}
                    <span className="text-gray-300">{source}</span>
                  </div>
                  {account?.message && (
                    <div className="detail-l text-gray-300 flex items-center gap-1 mt-1">
                      <div className="text-pink flex items-center justify-center">
                        <Icon type="infoSquare" size="small" />
                      </div>
                      <div>
                        {t(`error_${account?.message?.toLowerCase() || ''}`)}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </Radio>
          </div>
          <ControlledAccordion
            // Forward the `providerValue` directly to `ControlledAccordion`
            providerValue={providerValue}
          >
            <AccordionItem
              header=""
              // Set an explicit `itemKey`
              itemKey="item-1"
              contentProps={{
                style: {
                  transition: 'height 0.25s cubic-bezier(0, 0, 0, 1)',
                },
              }}
              initialEntered={selectedAccount === 'import'}
            >
              <form
                onSubmit={formik.handleSubmit}
                autoComplete="off"
                className="flex flex-col grow gap-4 py-6 px-10 max-w-md"
              >
                <div className="flex gap-2">
                  <Input
                    required
                    label={t('first_name')}
                    placeholder={t('first_name')}
                    variant={InputVariant.secondary}
                    error={formik.touched.name ? formik.errors.name : ''}
                    {...formik.getFieldProps('name')}
                  />

                  <Input
                    required
                    label={t('last_name')}
                    placeholder={t('last_name')}
                    variant={InputVariant.secondary}
                    error={formik.touched.surname ? formik.errors.surname : ''}
                    {...formik.getFieldProps('surname')}
                  />
                </div>
                <HubsSelect
                  label={t('home_hub')}
                  variant={SelectVariant.secondary}
                  error={formik.touched?.hub ? formik.errors?.hub?.id : ''}
                  onChange={(value) => {
                    const country = value?.id.toLowerCase().slice(0, 2);
                    formik.handleChange({ target: { name: 'hub', value } });
                    formik.handleChange({
                      target: {
                        name: 'country',
                        value: {
                          id: country.toLowerCase(),
                          label: t(`countries_${country.toLowerCase()}`),
                        },
                      },
                    });
                  }}
                  {...omit(formik.getFieldProps('hub'), 'onChange')}
                />
                <CountrySelect
                  disabled
                  variant={SelectVariant.secondary}
                  error={
                    formik.touched.country ? formik.errors.country?.id : ''
                  }
                  onChange={(value) =>
                    formik.handleChange({
                      target: { name: 'country', value },
                    })
                  }
                  {...omit(formik.getFieldProps('country'), 'onChange')}
                />
                <Input
                  required
                  type="email"
                  label={t('email')}
                  placeholder="max@gmail.com"
                  variant={InputVariant.secondary}
                  error={(formik.errors.email as string) || ''}
                  {...formik.getFieldProps('email')}
                />

                <Input
                  required
                  type="tel"
                  label={t('phone_number')}
                  placeholder="+491112223344"
                  variant={InputVariant.secondary}
                  error={(formik.errors.phone as string) || ''}
                  {...formik.getFieldProps('phone')}
                />

                <Input
                  required={formik.values.country.label === 'NL'}
                  label={t('date_of_birth')}
                  placeholder="2001-08-31"
                  maskOptions={{
                    ...MASKED_DATE_OPTIONS,
                    ...BIRTHDAY_MASKED_DATE_OPTIONS,
                  }}
                  variant={InputVariant.secondary}
                  error={formik.touched.birthday ? formik.errors.birthday : ''}
                  {...formik.getFieldProps('birthday')}
                />
              </form>
            </AccordionItem>
          </ControlledAccordion>
        </div>
        {error && (
          <Info className="!mt-5" type="error">
            {error}
          </Info>
        )}
        <div className="flex gap-2 justify-end mt-5">
          <Button
            variant={ButtonVariant.quinary}
            onClick={handleClose}
            disabled={formik.isSubmitting}
          >
            {t('cancel')}
          </Button>
          <Button
            loading={formik.isSubmitting}
            disabled={
              selectedAccount === 'import'
                ? !formik.isValid || formik.isSubmitting
                : !((selectedAccount as number) >= 0) || formik.isSubmitting
            }
            onClick={() => {
              formik.submitForm();
            }}
          >
            {t('confirm')}
          </Button>
        </div>
      </>
    </Modal>
  );
};
