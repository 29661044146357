import { useTranslation } from 'react-i18next';
import { differenceInDays, format } from 'date-fns';
import {
  Table,
  TableSortBy,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@hubportal/components';
import { TooltipHeader } from 'partials/tooltip-header';
import {
  ComplianceCaseStatus,
  ComplianceCaseStatusKeys,
} from 'utils/constants';
import { WorkforceCompliance } from 'models';

export const ComplianceTable = ({
  data,
  sortBy,
  onSortChange,
}: {
  data: WorkforceCompliance[];
  sortBy: TableSortBy;
  onSortChange: (sb: TableSortBy) => void;
}): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Table
      sortBy={sortBy}
      sortLabels={{
        asc: t('sort_ascending'),
        desc: t('sort_descending'),
      }}
      onSortChange={onSortChange}
      gridTemplateColumns={`
        40px
        minmax(210px, 210px)
        minmax(150px, 150px)
        minmax(150px, max-content)
        minmax(150px, max-content)
        minmax(150px, max-content)
        minmax(150px, max-content)
        minmax(150px, 1fr)
        40px
      `}
    >
      <Thead>
        <Tr>
          <Th></Th>
          <Th name="NAME" sortable>
            {t('name')}
          </Th>
          <Th name="PROBATION_STATUS" sortable>
            {t('probation_status')}
          </Th>
          <Th name="NO_SHOWS" sortable>
            <TooltipHeader label={t('no_shows')} info={t('no_shows_tooltip')} />
          </Th>
          <Th name="WARNINGS" sortable>
            <TooltipHeader
              label={t('warnings_sent')}
              info={t('warnings_tooltip')}
            />
          </Th>
          <Th name="INACTIVITY_DAYS" sortable>
            <TooltipHeader
              label={t('inactivity_days')}
              info={t('inactivity_days_tooltip')}
            />
          </Th>
          <Th name="POSITION" sortable>
            {t('position')}
          </Th>
          <Th>{t('compliance_case_status')}</Th>
          <Th></Th>
        </Tr>
      </Thead>
      <Tbody>
        {data.map((employee) => {
          const ped = employee?.probationEndDate;
          const hasOpenCase =
            employee?.caseStatus === ComplianceCaseStatus.OPENED;
          return (
            <Tr
              key={employee?.workforceID}
              highlighted={hasOpenCase}
              selected={hasOpenCase}
              href={`/workforce/${employee.workforceID}/compliance`}
            >
              <Td border={false}>
                {hasOpenCase ? (
                  <div className="relative">
                    <div className="w-2 h-2 bg-pink-400 rounded-xl absolute top-1.5 left-3"></div>
                  </div>
                ) : (
                  <></>
                )}
              </Td>
              <Td>{`${employee?.fullName || ''}`}</Td>
              <Td>
                {ped ? (
                  employee.isProbationPassed() ? (
                    t('probation_passed')
                  ) : (
                    <>
                      <div className="flex flex-col">
                        {t('probation_ongoing')}
                        <span className="text-xs text-gray-300">{`${t(
                          'ends_on'
                        )} ${format(new Date(ped || 0), 'dd/MM/yyyy')}`}</span>
                      </div>
                    </>
                  )
                ) : (
                  '-'
                )}
              </Td>
              <Td>{employee?.totalNoShows || '-'}</Td>
              <Td>{employee?.totalWarnings || '-'}</Td>
              <Td>
                {employee?.lastActivityAt
                  ? differenceInDays(
                      new Date(),
                      new Date(employee?.lastActivityAt || 0)
                    ) || '-'
                  : '-'}
              </Td>
              <Td>{employee?.jobTitle || '-'}</Td>
              <Td>
                {employee.caseStatus
                  ? t(ComplianceCaseStatusKeys[employee.caseStatus])
                  : ''}
              </Td>
              <Td border={false}></Td>
            </Tr>
          );
        })}
      </Tbody>
    </Table>
  );
};
