import { useTranslation } from 'react-i18next';
import { Select, SelectItem, SelectProps } from '@hubportal/components';

const EmploymentStatusFilter = ({
  isTerminationEligibilityFilterEnabled,
  onChange,
  ...props
}: SelectProps & {
  isTerminationEligibilityFilterEnabled: boolean;
}): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div className="">
      <Select
        placeholder={t('next_shift')}
        onChange={onChange}
        minWidth={false}
        dropdownFit="wide"
        {...props}
      >
        {[
          { id: 'all', label: 'employment_status' },
          { id: 'in_probation', label: 'probation_in_future' },
          { id: 'limited_contract', label: 'limited_contract' },
          { id: 'unlimited_contract', label: 'unlimited_contract' },
          isTerminationEligibilityFilterEnabled
            ? { id: 'termination_requested', label: 'termination_requested' }
            : null,
          isTerminationEligibilityFilterEnabled
            ? { id: 'termination_approved', label: 'termination_approved' }
            : null,
          { id: 'terminated', label: 'terminated' },
        ]
          .filter((s) => s)
          .map(({ id, label }: any) => {
            return (
              <SelectItem key={id} id={id} label={t(label)}>
                {id === 'all' ? t('all') : t(label)}
              </SelectItem>
            );
          })}
      </Select>
    </div>
  );
};

export { EmploymentStatusFilter };
