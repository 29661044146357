import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Modal,
  RadioGroup,
  Radio,
  Button,
  ButtonVariant,
} from '@hubportal/components';
import { Info } from 'components/info';
import {
  getPunchInOverrideDuration,
  getPunchInOverrideReasons,
} from 'utils/eppo/helpers';

export const PunchInOverrideModal = ({
  open,
  onClose,
  onSubmit,
}: {
  open: boolean;
  onClose: () => void;
  onSubmit: (fields) => Promise<void>;
}): JSX.Element => {
  const { t } = useTranslation();
  const [reason, setReason] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);
  const [info, setInfo] = useState<null | {
    type: 'success' | 'error';
    message: string;
  }>();

  const punchInOverrideReasons = useMemo(() => getPunchInOverrideReasons(), []);

  const duration = useMemo(() => getPunchInOverrideDuration(), []);

  const create = async (poReason: string = ''): Promise<void> => {
    try {
      setInfo(null);
      setLoading(true);
      await onSubmit({
        reason: poReason,
        duration,
      });
      setReason('');
      onClose();
    } catch (err: any) {
      setInfo({ type: 'error', message: err?.message });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <div className="w-96">
        <div className="header-m text-left">
          {t('create_punch_in_override_modal_header')}
        </div>
        <div className="title-m text-left">
          {t('create_punch_in_override_modal_description', { duration })}
        </div>
        <div className="flex flex-col gap-1 my-6">
          <RadioGroup
            name="punch-in-override-reasons"
            value={reason}
            onChange={(r) => setReason(r)}
          >
            {punchInOverrideReasons.map((r) => (
              <Radio key={r} id={`punch-in-override-reason-${r}`} value={r}>
                {t(`punch_in_override_reason_${r.toLowerCase()}`)}
              </Radio>
            ))}
          </RadioGroup>
        </div>
        {info?.message && <Info type={info?.type}>{t(info?.message)}</Info>}
        <div className="flex gap-2 justify-between">
          <Button variant={ButtonVariant.quinary} onClick={onClose}>
            {t('cancel')}
          </Button>
          <Button
            disabled={!reason || loading}
            loading={loading}
            onClick={() => {
              create(reason);
            }}
          >
            {t('allow')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};
