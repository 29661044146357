import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { Snackbar, SnackbarVariant } from '@hubportal/components';
import { Spinner } from 'components/spinner';
import { Notes as NotesV2 } from 'partials/notes/v2';
import { SicknessRate } from 'partials/sickness-rate';
import { TerminationTracker } from 'partials/case/termination-tracker';
import { ShiftCompliance } from 'partials/shift-compliance';
import { Help } from 'partials/help';
import { ComplianceCaseStatus, SICKNESS_RATE_THRESHOLD } from 'utils/constants';
import { ComplianceCase, Employee, Sendout } from 'models';
import {
  getSendouts,
  getComments,
  getWorkforceCompliance,
} from 'utils/network/apis';
import {
  isComplianceViewEnabled,
  getComplianceJobTitles,
} from 'utils/eppo/helpers';
import usePermissions, { Permission } from 'utils/hooks/usePermissions';
import withMFA from 'utils/hoc/withMFA';

/**
 *
 * TODO: should be refactored including subcomponents
 *
 */
const WorkforceComplianceFC = (): JSX.Element => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { id } = useParams();
  const { employee, employeeError } = useOutletContext<{
    employee: Employee | null;
    employeeError?: string;
  }>();
  const { isAllowed, isAllowedStrict } = usePermissions();

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [snackbar, setSnackbar] = useState({ visible: false, message: '' });

  const [complianceCase, setComplianceCase] = useState<ComplianceCase>();
  const [comments, setComments] = useState<Comment[]>([]);
  const [sendouts, setSendouts] = useState<Sendout[]>([]);

  const complianceJobTitles = useMemo(() => getComplianceJobTitles(), []);

  const getCommentsList = async (): Promise<void> => {
    const response = await getComments(id);
    setComments(response?.results);
  };

  const getSendoutsList = async (): Promise<void> => {
    const response = await getSendouts(id);
    setSendouts(
      response?.results
        ?.map(Sendout.from)
        .filter((s: Sendout) => s.isWarning())
        .sort(
          (s1: Sendout, s2: Sendout) =>
            new Date(s2.sendAt).getTime() - new Date(s1.sendAt).getTime()
        )
    );
  };

  const getData = async (): Promise<void> => {
    try {
      const response = await getWorkforceCompliance(id);
      setComplianceCase(ComplianceCase.from(response));
      await Promise.all([getSendoutsList(), getCommentsList()]);
    } catch (err: any) {
      if (err?.response?.status !== 404) {
        throw err;
      }
    }
  };

  const onLoad = async (): Promise<void> => {
    try {
      setError('');
      setLoading(true);
      await getData();
    } catch (err: any) {
      setError(err?.message);
    } finally {
      setLoading(false);
    }
  };

  const isComplianceEnabled = useMemo(() => {
    return (
      employee?.isInternal &&
      (isAllowed(Permission.READ_COMPLIANCE_ALL) ||
        isAllowedStrict(
          Permission.READ_COMPLIANCE_ALLOWED,
          employee?.homeHub
        ) ||
        isAllowedStrict(
          Permission.READ_COMPLIANCE_LIMITED,
          employee?.homeHub
        )) &&
      complianceJobTitles?.includes(employee?.jobTitle || '') &&
      isComplianceViewEnabled()
    );
  }, [employee]);

  useEffect(() => {
    if (id && isComplianceEnabled) {
      onLoad();
    }
  }, [id, isComplianceEnabled]);

  useEffect(() => {
    if (employee?.workforceID && !isComplianceEnabled) {
      navigate(location.pathname?.replace('/compliance', ''), {
        replace: true,
      });
    }
  }, [employee]);

  const warnings = useMemo(() => {
    return complianceCase?.noShows
      ?.reduce((acc, ns) => {
        if (ns.warningDeliveredAt) {
          acc.push({
            no_showed_at: ns.at,
            delivered_at: ns.warningDeliveredAt,
          });
        }
        return acc;
      }, [] as Warning[])
      .sort((a, b) => {
        return b.delivered_at.localeCompare(a.delivered_at);
      });
  }, [complianceCase]);

  const sicknessRateCritical = useMemo(() => {
    return (
      (complianceCase?.sicknessRate?.last_3_months?.rate || 0) * 100 >
      SICKNESS_RATE_THRESHOLD
    );
  }, [complianceCase]);

  const onSuccess = (editedFields): void => {
    setComplianceCase({ ...complianceCase, ...editedFields });
    getCommentsList();
  };

  const onExcuseNoShowSuccess = async (): Promise<void> => {
    const response = await getWorkforceCompliance(id);
    setComplianceCase(ComplianceCase.from(response));
  };

  const isWarningsModuleEnabled = useMemo(() => {
    return (
      employee?.homeHub?.startsWith('de') || employee?.hubSlug?.startsWith('de')
    );
  }, [employee]);

  return (
    <>
      {!employeeError && loading && <Spinner />}
      {error && <span className="text-xs text-pink">{error}</span>}
      {!loading && !error ? (
        <div className="grid grid-cols-[3fr,_minmax(25%,_200px)] gap-8">
          <div className="flex flex-col gap-8">
            {employee?.isTerminated() && (
              <div className="p-8 text-red bg-red-100 rounded-lg">
                {t('termination_tracker_terminated_description', {
                  date: format(
                    new Date(employee?.terminatedAt || 0),
                    'dd.MM.yyyy'
                  ),
                })}
              </div>
            )}
            {!employee?.isTerminated() &&
              complianceCase?.status !== ComplianceCaseStatus.EMPTY && (
                <TerminationTracker
                  employee={employee as Employee}
                  complianceCase={complianceCase as ComplianceCase}
                  isWarningsModuleEnabled={isWarningsModuleEnabled}
                  warnings={warnings || []}
                  comments={comments || []}
                  sicknessRateCritical={sicknessRateCritical}
                  onSuccess={onSuccess}
                  setSnackbar={setSnackbar}
                />
              )}
            <div className="">
              <div className="flex flex-col gap-8 min-w-[24rem]">
                <ShiftCompliance
                  employee={employee}
                  isWarningsModuleEnabled={!!isWarningsModuleEnabled}
                  complianceCaseStatus={
                    complianceCase?.status as ComplianceCaseStatus
                  }
                  onExcuseNoShowSuccess={onExcuseNoShowSuccess}
                />

                <SicknessRate id={id} />
                {isWarningsModuleEnabled ? (
                  <NotesV2 warnings={sendouts} />
                ) : (
                  <></>
                )}
              </div>

              <Snackbar
                open={snackbar.visible}
                variant={SnackbarVariant.success}
                icon="verification"
                onClose={() => setSnackbar({ visible: false, message: '' })}
              >
                {t(snackbar.message)}
              </Snackbar>
            </div>
            <Help />
          </div>
          <div />
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

const WorkforceCompliance = withMFA(WorkforceComplianceFC);
export { WorkforceCompliance };
