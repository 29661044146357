import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import usePermissions, { Permission } from 'utils/hooks/usePermissions';
import { CaseStatusToBadgeMap, ComplianceCaseStatus } from 'utils/constants';
import { Badge } from '@hubportal/components';
import { CaseBuilding } from './case-building';
import { CaseDecision } from './case-decision';
import { CaseDecisionApproval } from './case-decision-approval';
import { ComplianceCase, Employee } from 'models';

interface TerminationTrackerProps {
  complianceCase: ComplianceCase;
  warnings: Warning[];
  comments: Comment[];
  employee: Employee;
  isWarningsModuleEnabled?: boolean;
  sicknessRateCritical: boolean;
  onSuccess: (editedFields) => void;
  setSnackbar: (props) => void; // TODO: find a better way to display snackbar
}

const TerminationBox = ({
  employee,
  complianceCase,
  isWarningsModuleEnabled,
  ...props
}: TerminationTrackerProps): JSX.Element => {
  const { t } = useTranslation();
  const { isAllowed } = usePermissions();

  if (
    complianceCase.status === ComplianceCaseStatus.OPENED &&
    (isAllowed(Permission.READ_COMPLIANCE_ALL) ||
      isAllowed(Permission.READ_COMPLIANCE_ALLOWED)) &&
    !isAllowed(Permission.WRITE_COMPLIANCE_CONFIRMATION_ALL)
  ) {
    return (
      <CaseDecision
        employee={employee}
        complianceCase={complianceCase}
        {...props}
      />
    );
  }
  if (
    (complianceCase.status === ComplianceCaseStatus.TERMINATION_REQUESTED ||
      complianceCase.status === ComplianceCaseStatus.TERMINATION_REJECTED) &&
    isAllowed(Permission.WRITE_COMPLIANCE_CONFIRMATION_ALL)
  ) {
    return (
      <CaseDecisionApproval
        employee={employee}
        complianceCase={complianceCase}
        {...props}
      />
    );
  }

  if (complianceCase.status === ComplianceCaseStatus.TERMINATION_APPROVED) {
    return (
      <div className="p-8 text-s">
        {t('termination_tracker_termination_approved_description', {
          date: format(new Date(employee?.terminatedAt || 0), 'dd.MM.yyyy'),
        })}
      </div>
    );
  }

  return (
    <CaseBuilding
      isWarningsModuleEnabled={isWarningsModuleEnabled}
      complianceCase={complianceCase}
      warnings={props.warnings}
    />
  );
};

export const TerminationTracker = ({
  complianceCase,
  isWarningsModuleEnabled,
  ...props
}: TerminationTrackerProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col gap-2">
      <div className="flex gap-2 items-center text-white">
        <div className="header-s">{t('compliance_tracker')}</div>
        {isWarningsModuleEnabled &&
          [
            ComplianceCaseStatus.OPENED,
            ComplianceCaseStatus.DRAFTED,
            ComplianceCaseStatus.TERMINATION_REQUESTED,
            ComplianceCaseStatus.TERMINATION_APPROVED,
            ComplianceCaseStatus.TERMINATION_REJECTED,
            ComplianceCaseStatus.CLOSED,
          ].includes(complianceCase.status) && (
            <Badge
              variant={
                CaseStatusToBadgeMap[complianceCase.status].variant as any
              }
            >
              {t(CaseStatusToBadgeMap[complianceCase.status].label)}
            </Badge>
          )}
      </div>
      <div className="rounded-xl bg-primary">
        <TerminationBox
          isWarningsModuleEnabled={isWarningsModuleEnabled}
          complianceCase={complianceCase}
          {...props}
        />
      </div>
    </div>
  );
};
