import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Modal,
  RadioGroup,
  Radio,
  Button,
  ButtonVariant,
} from '@hubportal/components';
import { Info } from 'components/info';
import { getStrikeRejectReasons } from 'utils/eppo/helpers';

export const RejectStrikeModal = ({
  open,
  onClose,
  onSubmit,
}: {
  open: boolean;
  onClose: () => void;
  onSubmit: (fields) => Promise<void>;
}): JSX.Element => {
  const { t } = useTranslation();
  const [reason, setReason] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);
  const [info, setInfo] = useState<null | {
    type: 'success' | 'error';
    message: string;
  }>();

  const strikeRejectReasons = useMemo(() => getStrikeRejectReasons(), []);

  const rejectStrike = async (toReason?: string): Promise<void> => {
    try {
      setInfo(null);
      setLoading(true);
      await onSubmit(toReason);
      setReason('');
      onClose();
    } catch (err: any) {
      setInfo({ type: 'error', message: err?.message });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      open={open}
      onClose={() => {
        setReason('');
        onClose();
      }}
    >
      <div className="w-96 min-h-0 flex flex-col overflow-auto">
        <div className="header-m text-left">
          {t('reject_strike_reason_modal_header')}
        </div>
        <div className="flex flex-col gap-1 my-6 min-h-0 overflow-auto">
          <RadioGroup
            name="reject-strike-reasons"
            value={reason}
            onChange={(r) => setReason(r)}
          >
            {strikeRejectReasons.map((r) => (
              <Radio key={r} id={`reject-strike-reason-${r}`} value={r}>
                {t(`strike_reject_reason_${r?.toLowerCase()}`)}
              </Radio>
            ))}
          </RadioGroup>
        </div>
        {info?.message && <Info type={info?.type}>{t(info?.message)}</Info>}
        <div className="flex gap-2 justify-between">
          <Button variant={ButtonVariant.quinary} onClick={onClose}>
            {t('cancel')}
          </Button>
          <Button
            disabled={!reason || loading}
            loading={loading}
            onClick={() => {
              rejectStrike(reason);
            }}
          >
            {t('reject')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};
