import { Loading } from '@hubportal/components';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getPunchImage } from 'utils/network/apis';

export const WorkforcePunchImage = (): JSX.Element => {
  const { id } = useParams();
  const [image, setImage] = useState({ type: '', content: '' });
  const [status, setStatus] = useState({ type: 'initialize', message: '' });

  const onLoad = async (): Promise<void> => {
    try {
      setStatus({ type: 'loading', message: '' });
      const response = await getPunchImage(id);

      setImage({
        type: response?.mime_type,
        content: response?.decoded_content,
      });
      setStatus({ type: 'success', message: '' });
    } catch (err: any) {
      setStatus({ type: 'error', message: err?.message });
    }
  };

  useEffect(() => {
    if (id) {
      onLoad();
    }
  }, [id]);

  return (
    <div className="flex flex-col gap-5 p-10 items-start">
      {status.type === 'loading' && (
        <div>
          <Loading />
        </div>
      )}
      {status.type === 'success' && (
        <img src={`data:${image?.type};base64,${image?.content}`} />
      )}
      {status.type === 'error' && (
        <span className="text-xs text-pink">{status.message}</span>
      )}
    </div>
  );
};
