import { useState, useEffect } from 'react';
import { useOutletContext, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, Chip, Pagination } from '@hubportal/components';
import useStore from 'utils/store';
import usePermissions, { Permission } from 'utils/hooks/usePermissions';
import waitPermissions from 'utils/hoc/waitPermissions';
import useWorkforce, { WorkforceSearchQuery } from 'utils/hooks/useWorkforce';
import { Spinner } from 'components/spinner';
import { Sidebar } from 'components/sidebar-temp';
import { Employee } from 'models';
import { CreateRider } from 'partials/create-rider';
import { WorkforceOverviewTable } from 'partials/workforce-overview/table';
import {
  BlockedMultiselect,
  HubsMultiselect,
  RiderStatusMultiselect,
} from 'partials/multiselect';
import { MIN_SEARCH_TERM_LENGTH } from 'utils/constants';

const DEFAULT_PAGE_SIZE = 100;
const DEFAULT_QUERY = {
  max: DEFAULT_PAGE_SIZE,
  skip: 0,
};

const WorkforceOverviewFC = (): JSX.Element => {
  const { t } = useTranslation();
  const { isAllowed } = usePermissions();
  const { selectedHub, country } = useStore();
  const { searchTerm } = useOutletContext<{ searchTerm: string }>();
  const [searchParams, setSearchParams] = useSearchParams();

  const [employees, setEmployees] = useState<Employee[]>([]);
  const [newRider, setNewRider] = useState<'internal' | 'external' | null>(
    null
  );
  const [selectedHubs, setSelectedHubs] = useState<string[]>(
    isAllowed(Permission.READ_HUBS_ALL)
      ? searchParams?.get('hubs')?.split(',').filter(Boolean) || []
      : [selectedHub?.slug]
  );

  const [selectedStatus, setSelectedStatus] = useState<string[]>(
    searchParams?.get('status')?.split(',').filter(Boolean) || []
  );

  const [selectedBlockStatus, setSelectedBlockStatus] = useState<string[]>(
    searchParams?.get('blocked')?.split(',').filter(Boolean) || []
  );

  const [underage, setUnderage] = useState<boolean>();
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: DEFAULT_PAGE_SIZE,
    pageCount: 0,
  });

  const [query, setQuery] = useState<WorkforceSearchQuery>({
    ...DEFAULT_QUERY,
    generic: searchTerm,
    selectedHubs,
    selectedStatus,
    selectedBlockStatus,
  });
  const { status, error, data } = useWorkforce(query);

  useEffect(() => {
    setQuery({
      ...query,
      max: pagination.pageSize,
      skip: pagination.pageSize * pagination.pageIndex,
    });
  }, [pagination.pageIndex, pagination.pageSize]);

  useEffect(() => {
    setQuery({
      ...query,
      selectedHubs,
      selectedStatus,
      selectedBlockStatus,
      isUnderage: underage,
      skip: 0,
    });
    setPagination({ ...pagination, pageIndex: 0 });
  }, [selectedHubs, selectedStatus, selectedBlockStatus, underage]);

  useEffect(() => {
    if (searchTerm.length === 0 || searchTerm.length > MIN_SEARCH_TERM_LENGTH) {
      setQuery({ ...query, skip: 0, generic: searchTerm });
      setPagination({ ...pagination, pageIndex: 0 });
    }
  }, [searchTerm]);

  useEffect(() => {
    setSearchParams(
      {
        hubs: selectedHubs.join(','),
        status: selectedStatus.join(','),
        blocked: selectedBlockStatus.join(','),
        search: searchTerm,
      },
      { replace: true }
    );
  }, [selectedHubs, selectedStatus, selectedBlockStatus, searchTerm]);

  useEffect(() => {
    setEmployees(data?.results || []);
    setPagination({
      ...pagination,
      pageCount: Math.ceil(
        (data?.pagination?.total || 1) /
          (data?.pagination?.max || data?.pagination?.limit || 1)
      ),
    });
  }, [data]);

  return (
    <>
      <div className="px-10 py-4 flex gap-2 justify-between">
        <div className="flex gap-x-2 items-start">
          {isAllowed(Permission.READ_HUBS_ALL) && (
            <div className="w-52">
              <HubsMultiselect
                values={selectedHubs}
                onChange={setSelectedHubs}
              />
            </div>
          )}
          <RiderStatusMultiselect
            values={selectedStatus}
            onChange={setSelectedStatus}
          />
          <BlockedMultiselect
            values={selectedBlockStatus}
            onChange={setSelectedBlockStatus}
          />
          {country === 'NL' && (
            <Chip selected={underage} onClick={() => setUnderage(!underage)}>
              {t('show_underage_riders')}
            </Chip>
          )}
        </div>
        <div className="flex gap-x-2 items-start">
          {!newRider && isAllowed(Permission.CREATE_RIDER_EXTERNAL) && (
            <Button onClick={() => setNewRider('external')}>
              {t('create_external_rider')}
            </Button>
          )}
          {!newRider && isAllowed(Permission.CREATE_RIDER_INTERNAL) && (
            <Button onClick={() => setNewRider('internal')}>
              {t('import_rider')}
            </Button>
          )}
        </div>
      </div>
      <div className="relative px-10 mb-16">
        {error && <div className="text-pink text-xs px-10">{t(error)}</div>}
        {status === 'loading' && (
          <div className="absolute top-20 right-0 left-0 text-center">
            <Spinner />
          </div>
        )}
        <WorkforceOverviewTable data={employees} />
      </div>
      <div className="px-10 py-2">
        <Pagination
          goToPage={(pi) => {
            setPagination({ ...pagination, pageIndex: pi });
          }}
          pageCount={pagination.pageCount}
          pageIndex={pagination.pageIndex}
          pageSize={pagination.pageSize}
          setPageSize={(ps) => {
            setPagination({ ...pagination, pageSize: ps });
          }}
          totalCount={data?.pagination?.total || 0}
          labels={{ show: t('show') }}
        />
      </div>
      <Sidebar open={!!newRider} onClose={() => setNewRider(null)}>
        <CreateRider type={newRider} onSuccess={() => setQuery({ ...query })} />
      </Sidebar>
    </>
  );
};

const WorkforceOverview = waitPermissions(WorkforceOverviewFC);
export { WorkforceOverview };
