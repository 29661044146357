import { useState } from 'react';
import { getWorkforceList } from 'utils/network/apis';
import { useDeepCompareEffect } from './useDeepCompareEffect';
import { Employee } from 'models';

export type WorkforceSearchStatus = 'idle' | 'loading' | 'success' | 'error';

export type WorkforceSearchQuery = {
  max: number;
  skip: number;
  generic?: string;
  selectedHubs?: string[];
  selectedStatus?: string[];
  selectedBlockStatus?: string[];
  isUnderage?: boolean;
  sort?: string;
};

export type WorkforceSearchResponse = {
  status: WorkforceSearchStatus;
  error: string | null;
  data: {
    pagination?: { total?: number; max?: number; limit?: number };
    results?: Employee[];
  };
};

const useWorkforce = (query: WorkforceSearchQuery): WorkforceSearchResponse => {
  const [status, setStatus] = useState<WorkforceSearchStatus>('idle');
  const [data, setData] = useState<{
    pagination?: { total?: number; max?: number };
    results?: Employee[];
  }>({});
  const [error, setError] = useState(null);

  useDeepCompareEffect(() => {
    if (!query) {
      return;
    }

    const getRiders = async (): Promise<void> => {
      setData({});
      setStatus('loading');
      setError(null);

      try {
        const {
          max,
          skip,
          generic,
          selectedHubs,
          selectedStatus,
          selectedBlockStatus,
          isUnderage,
          sort,
        } = query;

        const response = await getWorkforceList({
          max,
          skip,
          ...(generic ? { generic } : null),
          ...(selectedHubs ? { hub: selectedHubs } : null),
          ...(selectedStatus ? { status: selectedStatus } : null),
          ...(selectedBlockStatus ? { is_blocked: selectedBlockStatus } : null),
          ...(isUnderage ? { is_underage: isUnderage } : null),
          ...(sort ? { sort } : null),
        });

        setData({
          ...response,
          results: response.results.map(Employee.from),
        });
        setStatus('success');
      } catch (err: any) {
        setStatus('error');
        setError(
          err?.response?.status === 403
            ? 'error_permission_required'
            : err?.message
        );
      }
    };

    getRiders();
  }, [query]);

  return { status, error, data };
};

export default useWorkforce;
