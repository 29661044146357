import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './locales/en.json';
import de from './locales/de.json';
import fr from './locales/fr.json';
import nl from './locales/nl.json';

const resources = {
  en: { translation: en },
  de: { translation: de },
  fr: { translation: fr },
  nl: { translation: nl },
};

export const config = {
  resources,
  lng: 'en',
  // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
  // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
  // if you're using a language detector, do not define the lng option
  fallbackLng: 'en',
  returnNull: false,
  interpolation: {
    escapeValue: false, // react already safes from xss
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init(config);

export default i18n;
