import {
  Multiselect,
  MultiselectItem,
  MultiselectProps,
} from '@hubportal/components';
import { useTranslation } from 'react-i18next';

const FeedbackLevelMultiselect = ({
  values,
  onChange,
  ...props
}: MultiselectProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Multiselect
      labels={{
        default: t('feedback_need'),
      }}
      values={values}
      onChange={onChange}
      displayDefaultLabel
      minWidth={false}
      dropdownFit="wide"
      {...props}
    >
      {['high', 'medium', 'low'].map((level) => {
        return (
          <MultiselectItem
            key={level}
            value={level}
            label={t(`feedback_level_${level}`)}
          >
            {t(`feedback_level_${level}`)}
          </MultiselectItem>
        );
      })}
    </Multiselect>
  );
};

export { FeedbackLevelMultiselect };
