import { Icon } from '@hubportal/components';
import { format, isToday } from 'date-fns';
import { useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useOnClickOutside from 'utils/hooks/useOnClickOutside';

type CalendarItem = {
  cw: number;
  from: Date;
  to: Date;
};

interface CalendarProps {
  list: CalendarItem[];
  selectedIndex: number;
  onChange: (index: number) => void;
}

const CalendarWeekChip = ({
  period,
}: {
  period: { cw: number; from: Date; to: Date; isCurrentWeek?: boolean };
}): JSX.Element => {
  return (
    <div
      className={` text-white title-xs px-1.5 py-1 rounded whitespace-nowrap bg-blue`}
    >
      {`${format(period.from, 'yyyy')} - CW ${period.cw}`}
    </div>
  );
};

const CalendarWeekText = ({
  period,
}: {
  period: { cw: number; from: Date; to: Date; isCurrentWeek?: boolean };
}): JSX.Element => {
  const { t } = useTranslation();
  return (
    <div className="title-s text-white whitespace-nowrap">
      {isToday(period.from)
        ? t('today')
        : `${format(period.from, 'LLL dd')} - ${
            period.isCurrentWeek ? t('today') : format(period.to, 'LLL dd')
          }`}
    </div>
  );
};

export const Calendar = ({
  list,
  selectedIndex,
  onChange,
}: CalendarProps): JSX.Element => {
  const ref = useRef(null);
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);

  useOnClickOutside(
    ref,
    () => {
      setOpen(false);
    },
    {}
  );

  const onClick = (): void => {
    setOpen(!open);
  };
  const selected = useMemo(() => list[selectedIndex], [list, selectedIndex]);

  return (
    <div className="relative min-w-[16rem] rounded-lg flex" ref={ref}>
      <div
        className={`w-8 flex items-center justify-center bg-primary rounded-l-lg text-white ${
          selectedIndex === list?.length - 1
            ? 'pointer-events-none opacity-50'
            : 'cursor-pointer'
        }`}
        onClick={() => {
          if (selectedIndex < list.length - 1) {
            onChange(selectedIndex + 1);
          }
        }}
      >
        <Icon type="arrowLeft" />
      </div>

      <div
        className="grow bg-primary/[0.5] p-2 flex items-center gap-2 cursor-pointer"
        onClick={onClick}
      >
        <CalendarWeekChip period={selected} />
        <CalendarWeekText period={selected} />
      </div>

      <div
        className={`w-8 flex items-center justify-center bg-primary rounded-r-lg text-white ${
          selectedIndex === 0
            ? 'pointer-events-none opacity-50'
            : 'cursor-pointer'
        }`}
        onClick={() => {
          if (selectedIndex > 0) {
            onChange(selectedIndex - 1);
          }
        }}
      >
        <Icon type="arrowRight" />
      </div>

      {open && (
        <div
          className={`absolute w-full z-10 rounded-md mt-px mb-px py-1 shadow-md top-full bg-primary`}
        >
          <ul className="max-h-60 my-6 overflow-auto flex flex-col gap-0.5">
            <div className="title-xs px-6 mb-2">
              {t('select_calendar_week')}
            </div>
            {list.map((p, i) => {
              return (
                <li
                  key={i}
                  className={`flex items-center gap-2 cursor-pointer px-6 py-2 hover:bg-secondary ${
                    selectedIndex === i ? 'bg-secondary' : ''
                  }`}
                  onClick={() => onChange(i)}
                >
                  <CalendarWeekChip period={p} />
                  <CalendarWeekText period={p} />
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </div>
  );
};
