import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePassword } from 'utils/network/apis';
import { Info } from 'components/info';
import { Button, Modal } from '@hubportal/components';
import { ConfirmationModal } from 'partials/confirmation-modal';

interface GenerateOtpProps {
  auth0Id: string;
}

const GenerateOtp = ({ auth0Id }: GenerateOtpProps): JSX.Element => {
  const { t } = useTranslation();

  const [otp, setOtp] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [modal, setModal] = useState<'generate' | 'success' | null>(null);
  const [info, setInfo] = useState<null | {
    type: 'success' | 'error';
    message: string;
  }>();

  const generate = async (): Promise<void> => {
    try {
      setInfo(null);
      setLoading(true);
      const response = await generatePassword(auth0Id);
      setOtp(response?.otp);
      setModal('success');
    } catch (err: any) {
      setInfo({ type: 'error', message: err?.message });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setOtp(null);
  }, [auth0Id]);

  return (
    <>
      <div>
        <Button
          disabled={loading}
          loading={loading}
          onClick={() => setModal('generate')}
        >
          {t('generate_otp')}
        </Button>
        {info?.message && (
          <Info type={info?.type} variant="secondary">
            {t(info?.message)}
          </Info>
        )}
      </div>

      <ConfirmationModal
        open={modal === 'generate'}
        onClose={() => setModal(null)}
        onConfirm={() => {
          setModal(null);
          generate();
        }}
        labels={{
          confirm: 'generate_otp',
          cancel: 'cancel',
        }}
      >
        {t('generate_otp_confirmation')}
      </ConfirmationModal>
      <Modal
        open={modal === 'success'}
        onClose={() => {
          setOtp(null);
          setModal(null);
        }}
      >
        <div>
          {t('generate_otp_success')}
          <div className="p-4 my-4 border border-solid border-pink rounded bg-primary">
            {otp}
          </div>
        </div>
      </Modal>
    </>
  );
};

export { GenerateOtp };
