import { useTranslation } from 'react-i18next';
import { Select, SelectItem, SelectProps } from '@hubportal/components';

const ChurnRiskFilter = ({ onChange, ...props }: SelectProps): JSX.Element => {
  const { t } = useTranslation();
  const list = [
    {
      churnRisk: 'high',
      label: 'churn_risk_high',
    },
    {
      churnRisk: 'medium',
      label: 'churn_risk_medium',
    },
    {
      churnRisk: 'low',
      label: 'churn_risk_low',
    },
  ];

  return (
    <Select
      label=""
      placeholder={t('churn_risk')}
      onChange={onChange}
      minWidth={false}
      dropdownFit="wide"
      {...props}
    >
      {[{ churnRisk: 'all', label: 'all' }, ...list].map(
        ({ churnRisk, label }) => {
          return (
            <SelectItem
              key={churnRisk}
              id={`${churnRisk}`}
              label={churnRisk === 'all' ? t('churn_risk') : t(label)}
            >
              {churnRisk === 'all' ? t('all') : t(label)}
            </SelectItem>
          );
        }
      )}
    </Select>
  );
};

export { ChurnRiskFilter };
