import { isInThePast } from 'utils/helpers';

export class Account {
  ecID?: string;
  firstName: string;
  lastName: string;
  email: string;
  cellPhone: string;
  hubSlug: string;
  dateOfBirth: string;
  source: string;
  auth0Id?: string;
  homeHub?: string;
  isBlocked?: boolean;
  jobTitle?: string;
  terminatedAt?: string;
  contractEndDate?: string;
  externalAgency?: string;

  constructor(details: any = {}) {
    this.ecID = details.ec_id;
    this.firstName = details.first_name;
    this.lastName = details.last_name;
    this.email = details.email;
    this.cellPhone = details.cell_phone;
    this.hubSlug = details.hub_slug;
    this.dateOfBirth = details.date_of_birth;
    this.source = details.source;
    this.auth0Id = details.auth0_id;
    this.homeHub = details.home_hub;
    this.isBlocked = details.is_blocked;
    this.jobTitle = details.job_title;
    this.terminatedAt = details.terminated_at?.startsWith('0001-01-01')
      ? null
      : details.terminated_at;

    this.contractEndDate = details.contract_end_date;
    this.externalAgency = details.external_agency_name;
  }

  public static from(details: any): Account {
    return new Account(details);
  }

  public isTerminated(): boolean {
    return this.terminatedAt ? isInThePast(new Date(this.terminatedAt)) : false;
  }
}
