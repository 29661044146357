import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, Snackbar, SnackbarVariant } from '@hubportal/components';
import { Spinner } from 'components/spinner';
import { Employee } from 'models';
import { getComments } from 'utils/network/apis';
import { track } from 'utils/tracking';
import {
  isComplianceViewEnabled,
  getComplianceJobTitles,
} from 'utils/eppo/helpers';
import usePermissions, { Permission } from 'utils/hooks/usePermissions';
import { FeedbackItem } from './feedback-item';
import { AddFeedbackModal } from './add-feedback-modal';

const WorkforceFeedbackFC = (): JSX.Element => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { id } = useParams();
  const { employee } = useOutletContext<{ employee: Employee | null }>();
  const { isAllowed, isAllowedStrict } = usePermissions();

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [addFeedbackModal, setAddFeedbackModal] = useState(false);
  const [snackbar, setSnackbar] = useState(false);

  const [comments, setComments] = useState<Comment[]>([]);

  const complianceJobTitles = useMemo(() => getComplianceJobTitles(), []);

  const onLoad = async (): Promise<void> => {
    try {
      setError('');
      setLoading(true);
      const response = await getComments(id);
      setComments(response?.results);
    } catch (err: any) {
      setError(err?.message);
    } finally {
      setLoading(false);
    }
  };

  const isComplianceEnabled = useMemo(() => {
    return (
      employee?.isInternal &&
      (isAllowed(Permission.READ_COMPLIANCE_ALL) ||
        isAllowedStrict(
          Permission.READ_COMPLIANCE_ALLOWED,
          employee?.homeHub
        ) ||
        isAllowedStrict(
          Permission.READ_COMPLIANCE_LIMITED,
          employee?.homeHub
        ) ||
        isAllowedStrict(
          Permission.READ_PERFORMANCE_LIMITED,
          employee?.homeHub
        )) &&
      complianceJobTitles?.includes(employee?.jobTitle || '') &&
      isComplianceViewEnabled()
    );
  }, [employee]);

  useEffect(() => {
    if (id && isComplianceEnabled) {
      onLoad();
    }
  }, [id, isComplianceEnabled]);

  useEffect(() => {
    if (employee?.workforceID && !isComplianceEnabled) {
      navigate(location.pathname?.replace('/feedback', ''), {
        replace: true,
      });
    }
  }, [employee]);

  return (
    <>
      {loading && <Spinner />}
      {error && <span className="text-xs text-pink">{error}</span>}
      {!loading && !error ? (
        comments?.length > 0 ? (
          <div className="flex flex-col gap-8">
            <div className="max-w-[18rem]">
              <Button
                onClick={() => {
                  track('click', {
                    component_name: 'add_feedback',
                    screen_name: 'feedback',
                    component_value: `${employee?.ecID}`,
                  });
                  setAddFeedbackModal(true);
                }}
              >
                {t('add_feedback')}
              </Button>
            </div>
            <div className="grid grid-cols-[repeat(auto-fill,_minmax(16rem,_1fr))] gap-6">
              {comments.map((c, i) => (
                <FeedbackItem key={i} feedback={c} />
              ))}
            </div>
          </div>
        ) : (
          <div className="flex flex-col items-center gap-4 text-white">
            <div>{t('no_feedback_available')}</div>
            <Button onClick={() => setAddFeedbackModal(true)}>
              {t('add_feedback')}
            </Button>
          </div>
        )
      ) : (
        <></>
      )}

      {addFeedbackModal && (
        <AddFeedbackModal
          open={addFeedbackModal}
          workforceID={employee?.workforceID || ''}
          ecID={employee?.ecID || ''}
          name={employee?.firstName}
          onSuccess={() => {
            setAddFeedbackModal(false);
            setSnackbar(true);
            setTimeout(() => setSnackbar(false), 3000);
            onLoad();
          }}
          onClose={() => setAddFeedbackModal(false)}
        />
      )}

      <Snackbar
        open={snackbar}
        variant={SnackbarVariant.success}
        icon="verification"
        onClose={() => setSnackbar(false)}
      >
        <div id="snackbar-feedback-added">
          {t('feedback_added', { name: employee?.firstName })}
        </div>
      </Snackbar>
    </>
  );
};

const WorkforceFeedback = WorkforceFeedbackFC;
export { WorkforceFeedback };
