import { PropsWithChildren, useEffect, useState } from 'react';
import {
  Routes,
  Route,
  Navigate,
  BrowserRouter,
  useLocation,
} from 'react-router-dom';
import { tokenMiddleware } from 'utils/network/tokenMiddleware';
import {
  isComplianceViewEnabled,
  isWorkforceRedesignEnabled,
} from 'utils/eppo/helpers';
import { Main } from 'layouts/main';
import { WorkforceIndex } from './pages/workforce/v1';
import { WorkforceIndex as WorkforceIndexV2 } from './pages/workforce/v2';
import { WorkforceOverview } from './pages/workforce/overview/v1';
import { WorkforceOverview as WorkforceOverviewV2 } from './pages/workforce/overview/v2';
import { WorkforceComplianceOverview } from './pages/workforce/compliance';
import { WorkforceCompliance } from './pages/workforce/id/compliance';
import { WorkforcePerformance } from './pages/workforce/id/performance';
import { WorkforceFeedback } from './pages/workforce/id/feedback';
import { WorkforceProfileIndex } from './pages/workforce/id/v1';
import { WorkforceProfile } from './pages/workforce/id/v1/overview';
import { WorkforceProfileIndex as WorkforceProfileIndexV2 } from './pages/workforce/id/v2';
import { WorkforceProfile as WorkforceProfileV2 } from './pages/workforce/id/v2/overview';
import { WorkforcePunchImage } from './pages/workforce/punch';
import { useCurrentPath } from 'utils/hooks/useCurrentPath';

import sdk from '@hubportal/sdk';
import axios from 'axios';

// TODO: find a way to clean up this tailwind
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import './tailwind.css';
import '@hubportal/components/index.css';

const ProtectedRoute = ({
  authClient,
  children,
}: PropsWithChildren<any>): JSX.Element => {
  const { pathname, search } = useLocation();
  const currentPath = useCurrentPath();

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios.interceptors.request.use(tokenMiddleware(authClient));
    setLoading(false);
  }, []);

  useEffect(() => {
    window?.analytics?.page(
      {
        name: currentPath?.id,
        path: pathname,
        referrer: document.referrer,
        search,
        title: 'HubPortal',
        traits: {
          hub_slug: sdk.getHub()?.slug,
          user_type: sdk?.getUserProfile()?.roles,
        },
      },
      {
        traits: {
          hub_slug: sdk.getHub()?.slug,
          user_type: sdk?.getUserProfile()?.roles,
        },
        userId: sdk?.getUserProfile()?.id,
      }
    );
  }, [pathname]);

  if (loading) {
    return <div>...</div>;
  }

  return children;
};

const Index = (): JSX.Element => {
  const isWorkforceNewDesignEnabled = isWorkforceRedesignEnabled();
  return isWorkforceNewDesignEnabled ? (
    <WorkforceIndexV2 />
  ) : (
    <WorkforceIndex />
  );
};

const Overview = (): JSX.Element => {
  const isWorkforceNewDesignEnabled = isWorkforceRedesignEnabled();
  return isWorkforceNewDesignEnabled ? (
    <WorkforceOverviewV2 />
  ) : (
    <WorkforceOverview />
  );
};

const ProfileIndex = (): JSX.Element => {
  const isWorkforceNewDesignEnabled = isWorkforceRedesignEnabled();
  return isWorkforceNewDesignEnabled ? (
    <WorkforceProfileIndexV2 />
  ) : (
    <WorkforceProfileIndex />
  );
};

const Profile = (): JSX.Element => {
  const isWorkforceNewDesignEnabled = isWorkforceRedesignEnabled();
  return isWorkforceNewDesignEnabled ? (
    <WorkforceProfileV2 />
  ) : (
    <WorkforceProfile />
  );
};

const App = ({ authClient }: PropsWithAuth0Client): JSX.Element => {
  const isComplianceEnabled = isComplianceViewEnabled();
  const isWorkforceNewDesignEnabled = isWorkforceRedesignEnabled();

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            <ProtectedRoute authClient={authClient}>
              <Main />
            </ProtectedRoute>
          }
        >
          <Route path="workforce" element={<Index />}>
            <Route index element={<Overview />} />
            {!isWorkforceNewDesignEnabled && (
              <Route
                path="performance"
                element={
                  isComplianceEnabled ? <WorkforceComplianceOverview /> : <></>
                }
              />
            )}
          </Route>
          <Route
            path="workforce/punch/image/:id"
            element={<WorkforcePunchImage />}
          />
          <Route path="workforce/:id" element={<ProfileIndex />}>
            <Route index element={<Profile />} />
            <Route
              path="compliance"
              element={isComplianceEnabled ? <WorkforceCompliance /> : <></>}
            />
            {isWorkforceNewDesignEnabled && (
              <Route
                path="performance"
                element={isComplianceEnabled ? <WorkforcePerformance /> : <></>}
              />
            )}
            {isWorkforceNewDesignEnabled && (
              <Route
                path="feedback"
                element={isComplianceEnabled ? <WorkforceFeedback /> : <></>}
              />
            )}
          </Route>
          <Route path="*" element={<Navigate to="/workforce" replace />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default App;
