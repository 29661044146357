import { useTranslation } from 'react-i18next';
import { Table, Tbody, Td, Th, Thead, Tr } from '@hubportal/components';
import { Employee } from 'models';

export const WorkforceOverviewTable = ({
  data,
}: {
  data: Employee[];
}): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Table
      gridTemplateColumns={`
        minmax(150px, 1fr)
        minmax(150px, 2fr)
        minmax(150px, 1fr)
        minmax(150px, 1fr)
        minmax(150px, 1fr)
        minmax(150px, 1fr)
        minmax(150px, 1fr)
        minmax(50px, 1fr)
    `}
    >
      <Thead>
        <Tr>
          <Th>{t('name')}</Th>
          <Th>{t('email')}</Th>
          <Th>{t('phone_number')}</Th>
          <Th>{t('employee_number')}</Th>
          <Th>{t('hub')}</Th>
          <Th>{t('position')}</Th>
          <Th>{t('status')}</Th>
          <Th>{t('blocked')}</Th>
        </Tr>
      </Thead>
      <Tbody>
        {data.map((employee) => {
          return (
            <Tr
              key={employee?.workforceID}
              href={`/workforce/${employee.workforceID}`}
              target="_self"
            >
              <Td>{employee.getFullName()}</Td>
              <Td>{employee.getField('email')}</Td>
              <Td>{employee.getField('phoneNumber')}</Td>
              <Td>{employee.getField('ecID')}</Td>
              <Td>{employee.getField('hubSlug')}</Td>
              <Td>{employee.getField('jobTitle')}</Td>
              <Td>{employee.getStateMizimized()}</Td>
              <Td>{employee?.isBlocked ? t('yes') : t('no')}</Td>
            </Tr>
          );
        })}
      </Tbody>
    </Table>
  );
};
