import { useTranslation } from 'react-i18next';
import { TooltipHeader } from 'partials/tooltip-header';
import {
  ComposedChart,
  Bar,
  Rectangle,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  ReferenceLine,
  Label,
} from 'recharts';
import { useMemo } from 'react';
import { eachDayOfInterval, format, isSameDay } from 'date-fns';

const CustomTooltip = ({ t, active, payload, label }: any): JSX.Element => {
  if (active && payload && payload.length) {
    const data = payload[0];
    return (
      <div className="bg-white rounded-lg p-4 text-black text-xs flex flex-col gap-2 items-start">
        <div className="flex gap-2 items-center">
          <div className="bg-pink w-3 h-3 rounded-full" />
          <div className="detail-l">
            <div>{`${t('acceptance_rate')}: ${
              data?.payload?.acceptanceRate || 0
            }%`}</div>
            <div className="detail-m">
              {t('acceptance_rate_graph_item_tooltip', {
                total: data?.payload?.totalOffers || 0,
                accepted: data?.payload?.acceptedOffers || 0,
              })}
            </div>
          </div>
        </div>
        <div className="flex gap-2 items-center">
          <div className="bg-[#BFBFBF] w-3 h-3 rounded-full" />
          <div>{`${t('hub_average')}: ${
            Number(data?.payload?.hubAverage).toFixed(2) || 0
          }%`}</div>
        </div>
      </div>
    );
  }

  return <></>;
};

const renderContent = (props: any): JSX.Element => {
  const { fill, value, textAnchor, fontSize, viewBox, dy, dx } = props;
  const x = viewBox.width / 2 + viewBox.x / 2;
  const y = viewBox.y;
  return (
    <g>
      <rect
        x={x - 10}
        y={y - 15}
        dy={dy}
        dx={dx}
        width={Number(viewBox.x) + 52}
        height={30}
        rx="15"
        fill="white"
      />
      <text
        x={x}
        y={Number(y) + 4}
        dy={dy}
        dx={dx}
        fill={fill}
        fontSize={fontSize || 14}
        textAnchor={textAnchor}
      >
        {value}
      </text>
    </g>
  );
};

export const AcceptanceRateGraph = ({
  selectedPeriod,
  data,
}: {
  selectedPeriod: {
    cw: number;
    from: Date;
    to: Date;
  };
  data: any;
}): JSX.Element => {
  const { t } = useTranslation();

  const graphData = useMemo(() => {
    return eachDayOfInterval({
      start: new Date(selectedPeriod.from),
      end: new Date(selectedPeriod.to),
    }).map((date) => {
      const found = data?.items?.find((d) => isSameDay(date, new Date(d.date)));
      return {
        date,
        acceptanceRate: found?.value || 0,
        acceptedOffers: found?.extra?.accepted_offers || 0,
        totalOffers: found?.extra?.total_offers || 0,
        hubAverage: found?.value ? found?.extra?.hub_average || 0 : 0,
      };
    });
  }, [selectedPeriod]);

  const target = useMemo(() => {
    return data?.items?.find((d) => d.value !== 0)?.target || 0;
  }, [data]);

  return (
    <div className="flex flex-col gap-4 text-white">
      <TooltipHeader
        fontBold
        label={t('acceptance_rate')}
        info={t('acceptance_rate_graph_tooltip')}
      />

      <div className="rounded-lg bg-primary h-80">
        <ResponsiveContainer width="100%" height="100%">
          <ComposedChart
            width={500}
            height={300}
            data={graphData}
            margin={{
              top: 30,
              right: 40,
              left: 10,
              bottom: 10,
            }}
          >
            <XAxis
              dataKey="date"
              tick={{ fontSize: 12, fontFamily: 'Flink', fill: '#BFBFBF' }}
              tickFormatter={(tick) => format(tick, 'dd.MM.yy')}
            />
            <YAxis
              ticks={[10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
              tick={{ fontSize: 12, fontFamily: 'Flink', fill: '#BFBFBF' }}
              tickFormatter={(tick) => `${tick}%`}
            />
            <Tooltip cursor={false} content={<CustomTooltip t={t} />} />
            <Bar
              dataKey="acceptanceRate"
              fill="#E31C79"
              opacity={0.7}
              activeBar={<Rectangle fill="#E31C79" opacity={1} />}
              barSize={20}
            />
            <Bar
              dataKey="hubAverage"
              fill="#BFBFBF"
              opacity={0.7}
              activeBar={<Rectangle fill="#BFBFBF" opacity={1} />}
              barSize={20}
            />
            {target > 0 && (
              <ReferenceLine
                alwaysShow
                y={target}
                label={
                  <Label
                    position="center"
                    content={renderContent as any}
                    value={`${t('top_riders')}: ${target}%`}
                    fill="#23272A"
                  />
                }
                stroke="white"
              />
            )}
          </ComposedChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};
