import { useOutletContext } from 'react-router-dom';
import { Employee } from 'models';
import { PersonalData } from 'partials/profile/v2/personalData';
import { PasswordSettings } from 'partials/profile/v2/passwordSettings';

const WorkforceProfile = (): JSX.Element => {
  const { employee } = useOutletContext<{ employee: Employee | null }>();

  return (
    <div className="flex gap-8">
      <PersonalData employee={employee} />
      <PasswordSettings employee={employee} />
    </div>
  );
};

export { WorkforceProfile };
