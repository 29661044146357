import { useMemo, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { RiderStates } from 'utils/constants';
import usePermissions, { Permission } from 'utils/hooks/usePermissions';
import { isPunchOnlineEnabled } from 'utils/eppo/helpers';
import { Button } from '@hubportal/components';
import { Info } from 'components/info';
import { ConfirmationModal } from 'partials/confirmation-modal';
import { PunchInOverride } from 'partials/punch-in-override';
import { SetTempOffline } from 'partials/set-temp-offline';
import { GenerateOtp } from 'partials/generate-otp';
import { Profile } from 'partials/profile/v1/profile';
import { Employee } from 'models';
import { UnblockModal } from 'partials/block/unblock-modal';

type WorkforceProfileActionType =
  | 'set_offline'
  | 'set_online'
  | 'block'
  | 'unblock'
  | 'reset_password'
  | null;

type WorkforceProfileAction = {
  type?: WorkforceProfileActionType;
  modalTitle?: string;
  loading?: WorkforceProfileActionType;
  func?: () => Promise<void>;
};

const WorkforceProfile = (): JSX.Element => {
  const { t } = useTranslation();
  const { isAllowed } = usePermissions();
  const { employee } = useOutletContext<{ employee: Employee | null }>();

  const [action, setAction] = useState<WorkforceProfileAction>({});
  const [info, setInfo] = useState<null | {
    type: 'success' | 'error';
    message: string;
  }>();

  const isEmployeeLoaded = useMemo(() => !!employee?.workforceID, [employee]);

  const handler = async (successMessage, func): Promise<void> => {
    try {
      setAction({ ...action, loading: action?.type });
      setInfo(null);
      await func();
      setInfo({ type: 'success', message: successMessage });

      setTimeout(() => {
        setInfo(null);
      }, 2000);
    } catch (err) {
      setInfo({ type: 'error', message: 'generic_error' });
      throw err;
    } finally {
      setAction({ ...action, loading: null });
    }
  };

  const resetPassword = (): Promise<void> =>
    handler(
      'reset_password_success',
      async () => await employee?.resetPassword()
    );

  const setOffline = (): Promise<void> =>
    handler(
      'set_rider_offline_success',
      async () => await employee?.setOffline()
    );

  const setOnline = (): Promise<void> =>
    handler(
      'set_rider_online_success',
      async () => await employee?.setOnline()
    );

  const block = (): Promise<void> => {
    return handler('block_rider_success', async () => {
      await employee?.block();
    });
  };

  const unblock = (): Promise<void> => {
    return handler('unblock_rider_success', async () => {
      const hasBoth =
        employee?.blockingReasons?.findIndex(
          (r) => r.reason === 'OTHER_AND_IP_BLOCKED'
        ) !== -1;

      const isIpBlocked =
        employee?.blockingReasons?.findIndex(
          (r) => r.reason === 'IP_BLOCKED'
        ) !== -1;

      await employee?.unblock(
        hasBoth ? 'OTHER_AND_IP_BLOCKED' : isIpBlocked ? 'IP_BLOCKED' : 'OTHER'
      );
    });
  };

  return (
    <>
      <Profile employee={employee} />

      <div className="flex flex-col gap-2 mt-10">
        {info?.message && (
          <Info type={info?.type} variant="secondary">
            {t(info?.message)}
          </Info>
        )}

        {employee?.ecID && isAllowed(Permission.WRITE_PUNCH_OVERRIDE_ALL) && (
          <PunchInOverride ecId={employee.ecID} />
        )}

        {/* Set online  */}
        {isEmployeeLoaded &&
          employee?.isSetOnlineEnabled() &&
          isAllowed(Permission.WRITE_TEMP_OFFLINE_ALL) && (
            <div>
              <Button
                disabled={!!action.loading}
                loading={action.loading === 'set_online'}
                onClick={() =>
                  setAction({
                    type: 'set_online',
                    modalTitle: 'set_rider_online_confirmation',
                    func: setOnline,
                  })
                }
              >
                {t('set_rider_online')}
              </Button>
            </div>
          )}

        {/* Display temp offline button for punch online hm/sl and tech-ops */}
        {isEmployeeLoaded &&
          employee?.ecID &&
          employee?.isSetTempOfflineEnabled() &&
          (isAllowed(Permission.WRITE_OFFLINE_ALL) ||
            (isAllowed(Permission.WRITE_TEMP_OFFLINE_ALL) &&
              isPunchOnlineEnabled())) && (
            <SetTempOffline
              employee={employee}
              onSuccess={() => {
                setInfo({
                  type: 'success',
                  message: 'set_rider_temp_offline_success',
                });
                setTimeout(() => {
                  setInfo(null);
                }, 2000);
              }}
            />
          )}

        {/* Display offline button for externals and non-punch online hm/sl and tech-ops */}
        {isEmployeeLoaded &&
          (!employee?.ecID ||
            isAllowed(Permission.WRITE_OFFLINE_ALL) ||
            (isAllowed(Permission.WRITE_RIDERPROFILE_ALL) &&
              !isPunchOnlineEnabled())) && (
            <div>
              <Button
                disabled={
                  !!action.loading || employee?.status === RiderStates.OFFLINE
                }
                loading={action.loading === 'set_offline'}
                onClick={() =>
                  setAction({
                    type: 'set_offline',
                    modalTitle: 'set_rider_offline_confirmation',
                    func: setOffline,
                  })
                }
              >
                {t('set_rider_offline')}
              </Button>
            </div>
          )}

        {/* Unblock  */}
        {isEmployeeLoaded &&
          employee?.isBlocked &&
          isAllowed(Permission.WRITE_UNBLOCK_ALL) && (
            <div>
              <Button
                disabled={
                  !!action.loading || employee?.status === RiderStates.BUSY
                }
                loading={action.loading === 'block'}
                onClick={() => {
                  const hasVisaExpiredReason =
                    (employee?.blockingReasons || []).filter(
                      (r) => r.reason === 'VISA_EXPIRED'
                    )?.length > 0;

                  if (hasVisaExpiredReason) {
                    setAction({ type: 'unblock' });
                    return;
                  }

                  setAction({
                    type: 'block',
                    modalTitle: t('unblock_rider_confirmation'),
                    func: unblock,
                  });
                }}
              >
                {employee?.isBlocked ? t('unblock_rider') : t('block_rider')}
              </Button>
              {employee?.status === RiderStates.BUSY && (
                <div className="detail-s">
                  <span className="text-pink">*</span>
                  {t('block_rider_busy_warning')}
                </div>
              )}
            </div>
          )}

        {/* Block  */}
        {isEmployeeLoaded &&
          (!employee?.isBlocked ||
            employee?.blockingReasons?.filter((r) => {
              return ['OTHER', 'OTHER_AND_IP_BLOCKED'].includes(r.reason);
            }).length === 0) &&
          isAllowed(Permission.WRITE_BLOCK_ALL) && (
            <div>
              <Button
                disabled={
                  !!action.loading || employee?.status === RiderStates.BUSY
                }
                loading={action.loading === 'block'}
                onClick={() =>
                  setAction({
                    type: 'block',
                    modalTitle: t('block_rider_confirmation'),
                    func: block,
                  })
                }
              >
                {t('block_rider')}
              </Button>
              {employee?.status === RiderStates.BUSY && (
                <div className="detail-s">
                  <span className="text-pink">*</span>
                  {t('block_rider_busy_warning')}
                </div>
              )}
            </div>
          )}

        {/* Reset password */}
        {isEmployeeLoaded && isAllowed(Permission.WRITE_PASSWORD_ALL) && (
          <>
            <div>
              <Button
                disabled={!!action.loading}
                loading={action.loading === 'reset_password'}
                onClick={() => {
                  resetPassword();
                }}
              >
                {t('reset_password')}
              </Button>
            </div>
            <GenerateOtp auth0Id={employee?.workforceID || ''} />
          </>
        )}
      </div>

      {/* Confirmation modal for set_online, set_offline, block and unblock actions */}
      <ConfirmationModal
        open={!!action.type}
        onClose={() => setAction({})}
        onConfirm={() => {
          setAction({});
          action.func && action.func();
        }}
      >
        {t(action?.modalTitle || '')}
      </ConfirmationModal>

      {/* unblock modal */}
      <UnblockModal
        open={action.type === 'unblock'}
        onClose={() => {
          setAction({});
        }}
        reasons={employee?.blockingReasons || []}
        onSubmit={async (reason) => {
          await employee?.unblock(reason);
        }}
      />
    </>
  );
};

export { WorkforceProfile };
