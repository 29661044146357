import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon, Snackbar, SnackbarVariant } from '@hubportal/components';

const ProfileItem = ({
  label,
  value,
  copyable,
}: {
  label: string;
  value: string | null | undefined;
  copyable?: boolean;
}): JSX.Element => {
  const { t } = useTranslation();
  const [snackbar, setSnackbar] = useState(false);

  const copy = async (text: string): Promise<void> => {
    await navigator.clipboard.writeText(text);
    setSnackbar(true);
    setTimeout(() => setSnackbar(false), 3000);
  };

  return (
    <div className="flex flex-col gap-2">
      <span className="text-s">{label}</span>
      <div className="text-m flex gap-2">
        {value || '-'}
        {copyable && value && (
          <Icon
            type="copy"
            onClick={() => {
              copy(value);
            }}
          />
        )}
      </div>

      <Snackbar
        open={snackbar}
        variant={SnackbarVariant.success}
        icon="verification"
        onClose={() => setSnackbar(false)}
      >
        {t('copied_to_clipboard')}
      </Snackbar>
    </div>
  );
};

export { ProfileItem };
