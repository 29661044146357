import { Badge, BadgeSize, BadgeVariant } from '@hubportal/components';
import { format } from 'date-fns';
import { WorkforceCompliance } from 'models/compliance';
import { useTranslation } from 'react-i18next';
import {
  ComplianceCaseStatus,
  ComplianceCaseStatusKeys,
} from 'utils/constants';

export const EmployeeStatusBadge = ({
  employee,
}: {
  employee: WorkforceCompliance;
}): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div className="flex w-full justify-end">
      {employee?.isTerminated() ? (
        <Badge variant={BadgeVariant.red} size={BadgeSize.large}>
          {t('terminated')}
        </Badge>
      ) : employee.caseStatus === ComplianceCaseStatus.TERMINATION_REQUESTED ? (
        <Badge variant={BadgeVariant['fire-red']} size={BadgeSize.large}>
          {t(ComplianceCaseStatusKeys[employee.caseStatus])}
        </Badge>
      ) : employee.caseStatus === ComplianceCaseStatus.TERMINATION_APPROVED ? (
        <Badge variant={BadgeVariant.green} size={BadgeSize.large}>
          {t(ComplianceCaseStatusKeys[employee.caseStatus])}
        </Badge>
      ) : employee.isExternal ? (
        <Badge variant={BadgeVariant['night-blue']} size={BadgeSize.large}>
          {t('external')}
        </Badge>
      ) : employee.isInProbation() ? (
        <Badge variant={BadgeVariant.orange} size={BadgeSize.large}>
          {t('probation_ends', {
            date: format(
              new Date(employee?.probationEndDate || 0),
              'dd.MM.yyyy'
            ),
          })}
        </Badge>
      ) : (
        <div className="flex flex-col gap-1 items-end">
          <div className="text-xs text-white">{t('probation_passed')}</div>
          {employee?.contractEndDate && (
            <div className="detail-l text-[#BFBFBF]">
              {t('contract_ends_at', {
                date: format(
                  new Date(employee?.contractEndDate || 0),
                  'dd.MM.yyyy'
                ),
              })}
            </div>
          )}
        </div>
      )}
    </div>
  );
};
