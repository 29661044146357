import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Loading, Select, SelectItem } from '@hubportal/components';
import { TooltipTitle } from 'partials/tooltip-title';
import { getSicknessRate } from 'utils/network/apis';
import get from 'lodash.get';
import { Intervals } from 'utils/constants';

export const SicknessRate = ({ id }: { id?: string }): JSX.Element => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [selectedInterval, setSelectedInterval] = useState(
    Intervals.LAST_3_MONTHS
  );
  const [data, setData] = useState<object>({});

  const getData = async (interval: string): Promise<void> => {
    try {
      setError('');
      setLoading(true);

      const response = await getSicknessRate(id, {
        time_range: selectedInterval,
      });
      setData({ ...data, [interval]: response });
    } catch (err: any) {
      setError(err?.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (id && Object.keys(get(data, selectedInterval, {})).length === 0) {
      getData(selectedInterval);
    }
  }, [id, selectedInterval]);

  const selectedData = useMemo(() => {
    return {
      sickDays: get(
        data,
        `${selectedInterval}.number_of_sick_days`,
        0
      ) as number,
      scheduledDays: get(
        data,
        `${selectedInterval}.number_of_scheduled_days`,
        0
      ) as number,
      rate: Math.round(get(data, `${selectedInterval}.rate`, 0) * 100),
    };
  }, [data, selectedInterval]);

  return (
    <div className="flex flex-col gap-2">
      <div className="title-s flex justify-between">
        <TooltipTitle
          label={t('sickness_rate')}
          info={t('sickness_rate_tooltip')}
        />
        <Select
          label=""
          placeholder=""
          value={{
            id: selectedInterval,
            label: t(`filter_${selectedInterval.toLowerCase()}`),
          }}
          onChange={({ id: selectId }) => setSelectedInterval(selectId)}
        >
          {[Intervals.ALL_TIME, Intervals.LAST_3_MONTHS].map((ri) => (
            <SelectItem
              key={ri}
              id={ri}
              label={t(`filter_${ri.toLowerCase()}`)}
            >
              {t(`filter_${ri.toLowerCase()}`)}
            </SelectItem>
          ))}
        </Select>
      </div>
      <div className="flex rounded-xl bg-primary gap-1">
        <div className="p-8 text-center border-r-2 border-solid border-secondary w-40">
          <div className="header-l text-white">
            {loading ? <Loading /> : `${selectedData.rate}%`}
          </div>
          <div className="detail-l lowercase">{t('sickness_rate')}</div>
        </div>
        <div className="flex flex-col gap-3 p-8 text-s">
          {error && (
            <span className="text-xs col-span-2 text-pink">{error}</span>
          )}
          <div>
            <span>{t('total_sickness_days')}</span>
            {': '}
            <span className="title-s">{selectedData.sickDays}</span>
          </div>
          <div>
            <span>{t('total_worked_and_absent_days')}</span>
            {': '}
            <span className="title-s">
              {selectedData.scheduledDays + selectedData.sickDays}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
