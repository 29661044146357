import { useEffect } from 'react';
import sdk from '@hubportal/sdk';
import jwtDecode, { JwtPayload } from 'jwt-decode';
import useStore from 'utils/store';

export enum Permission {
  READ_RIDERPROFILE_ALL = 'read:riderprofile:all',
  CREATE_RIDER_EXTERNAL = 'create:rider:external',
  CREATE_RIDER_INTERNAL = 'create:rider:internal',
  WRITE_RIDERSTATE_ALL = 'write:riderstate:all',
  WRITE_RIDERPROFILE_ALL = 'write:riderprofile:all',
  WRITE_EMAIL_ALL = 'write:email:all',
  WRITE_PHONENUMBER_ALL = 'write:phonenumber:all',
  READ_HUBS_ALL = 'read:hubs:all',
  READ_COMPLIANCE_ALL = 'read:compliance:all',
  READ_COMPLIANCE_ALLOWED = 'read:compliance:allowed',
  READ_COMPLIANCE_LIMITED = 'read:compliance:limited',
  READ_PERFORMANCE_LIMITED = 'read:performance:limited',
  WRITE_COMPLIANCE_ALL = 'write:compliance:all',
  WRITE_COMPLIANCE_CONFIRMATION_ALL = 'write:compliance_confirmation:all',
  WRITE_TEMP_OFFLINE_ALL = 'write:temp_offline:all',
  WRITE_OFFLINE_ALL = 'write:offline:all',
  WRITE_BLOCK_ALL = 'write:block:all',
  WRITE_UNBLOCK_ALL = 'write:unblock:all',
  WRITE_PASSWORD_ALL = 'write:password:all',
  WRITE_PUNCH_OVERRIDE_ALL = 'write:punch_override:all',
  EXCUSE_NO_SHOWS = 'force_excuse:no_show:all',
}

type PermissionsResult = {
  permissionsReady: boolean;
  isAllowed(permission: Permission): boolean;
  isAllowedStrict(permission: Permission, hub?: string): boolean;
};

const usePermissions = (): PermissionsResult => {
  const {
    permissions,
    permissionsReady,
    setPermissions,
    allowedHubs,
    setAllowedHubs,
  } = useStore();

  useEffect(() => {
    const parseUserPermissions = async (): Promise<void> => {
      try {
        const token = await sdk.getAuthClient().getTokenSilently({
          audience: process.env.REACT_APP_AUTH0_AUDIENCE,
        });
        const decodedToken =
          jwtDecode<
            JwtPayload & { permissions?: string[]; allowed_hubs?: string[] }
          >(token) || {};
        const { permissions: jwtPermissions = [], allowed_hubs: hubs = [] } =
          decodedToken;

        const authorized = jwtPermissions.reduce(
          (acc, permission) => ({ ...acc, [permission]: true }),
          {}
        );

        setAllowedHubs(hubs);
        setPermissions(authorized);
      } catch (err) {
        sdk.reportError(err, '@hubportal/workforce', {
          message: 'usePermissions: parse user permissions error',
        });
      }
    };

    if (!permissionsReady) {
      parseUserPermissions();
    }
  }, []);

  return {
    permissionsReady,
    isAllowed: (permission) => {
      return permissions[permission] || false;
    },
    isAllowedStrict: (permission, hub) => {
      if (permissions[permission] && hub) {
        return allowedHubs.some((h) => hub?.startsWith(h));
      }
      return false;
    },
  };
};

export default usePermissions;
