import { useTranslation } from 'react-i18next';
import { Select, SelectItem, SelectProps } from '@hubportal/components';

const TerminationEligibilityFilter = ({
  onChange,
  ...props
}: SelectProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div className="">
      <Select
        placeholder={t('termination_eligibility')}
        minWidth={false}
        dropdownFit="wide"
        onChange={onChange}
        {...props}
      >
        {[
          {
            id: 'all',
            label: 'termination_eligibility',
          },
          {
            id: 'is_critical',
            label: 'termination_eligibility_filter_is_critical',
          },
          {
            id: 'opened',
            label: 'termination_eligibility_filter_case_opened',
          },
          {
            id: 'not_eligible',
            label: 'termination_eligibility_filter_not_eligible',
          },
        ].map(({ id, label }) => (
          <SelectItem key={id} id={id} label={t(label)}>
            {id === 'all' ? t('all') : t(label)}
          </SelectItem>
        ))}
      </Select>
    </div>
  );
};

export { TerminationEligibilityFilter };
