import {
  Multiselect,
  MultiselectItem,
  MultiselectProps,
} from '@hubportal/components';
import { useTranslation } from 'react-i18next';
import { RiderStates } from 'utils/constants';

const RiderStatusMultiselect = ({
  values,
  onChange,
  ...props
}: MultiselectProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Multiselect
      labels={{
        default: t('status'),
        search: '',
      }}
      values={values}
      onChange={onChange}
      {...props}
    >
      {[
        RiderStates.ONLINE,
        RiderStates.BUSY,
        RiderStates.RETURNING,
        RiderStates.STARTING,
        RiderStates.IDLE,
        RiderStates.ON_BREAK,
        RiderStates.TEMP_OFFLINE,
        RiderStates.OFFLINE,
      ]
        .filter((s) => !!s)
        .map((status) => {
          const label = t(`rider_status_${status?.toLowerCase()}`);
          return (
            <MultiselectItem key={status} value={status || ''} label={label}>
              {label}
            </MultiselectItem>
          );
        })}
    </Multiselect>
  );
};

export { RiderStatusMultiselect };
