import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { format } from 'date-fns';
import {
  Button,
  ButtonVariant,
  Modal,
  ModalVariant,
  Select,
  SelectItem,
  SelectVariant,
  Textarea,
  TextareaVariant,
} from '@hubportal/components';
import { track } from 'utils/tracking';
import { createComment } from 'utils/network/apis';
import { Info } from 'components/info';
import { getFeedbackMetrics } from 'utils/eppo/helpers';
import DatePicker from 'react-date-picker';
import omit from 'lodash.omit';
import * as Yup from 'yup';

const getValidationSchema = (t): object =>
  Yup.object({
    metric: Yup.object({
      id: Yup.string().required(t('required')),
      label: Yup.string(),
    }).required(),
    feedback: Yup.string().required(t('required')),
  });

const initialValues = {
  metric: { id: '', label: '' },
  feedbackDate: '',
  feedback: '',
};

export const AddFeedbackModal = ({
  open,
  workforceID,
  ecID,
  name,
  onSuccess,
  onClose,
}: {
  open: boolean;
  workforceID: string;
  ecID: string;
  name?: string;
  onSuccess: () => any;
  onClose: () => any;
}): JSX.Element => {
  const { t } = useTranslation();

  const [info, setInfo] = useState<null | {
    type: 'success' | 'error';
    message: string;
  }>();

  const metrics = useMemo(() => getFeedbackMetrics(), []);

  const create = async ({ metric, feedback, feedbackDate }): Promise<void> => {
    try {
      setInfo(null);
      track('click', {
        component_name: 'submit_feedback',
        component_content: metric?.toLowerCase(),
        screen_name: 'feedback',
        component_value: `${ecID}`,
      });

      await createComment(workforceID, {
        type: metric,
        description: feedback,
        ...(feedbackDate
          ? {
              feedback_date: format(
                new Date(feedbackDate),
                "yyyy-MM-dd'T'HH:mm:ss'Z'"
              ),
            }
          : null),
      });
      onSuccess();
    } catch (err: any) {
      setInfo({ type: 'error', message: err?.message });
    }
  };

  const formik = useFormik({
    initialValues: {
      ...initialValues,
    },
    validateOnMount: true,
    validationSchema: getValidationSchema(t),
    onSubmit: async () => {
      formik.setSubmitting(true);
      await create({
        feedback: formik.values.feedback,
        feedbackDate: formik.values.feedbackDate,
        metric: formik.values.metric?.id,
      });
      formik.setSubmitting(false);
    },
  });

  return (
    <Modal
      open={open}
      variant={ModalVariant.secondary}
      onClose={() => {
        onClose();
      }}
    >
      <div className="w-96 text-left">
        <div className="header-m">{t('add_feedback_to', { name })}</div>
        <div className="title-m my-px">
          {t('add_feedback_modal_description')}
        </div>
        <form onSubmit={formik.handleSubmit} autoComplete="off">
          <div className="flex flex-col gap-4 my-6">
            <div>
              <div className="my-1 text-s">{t('metric')}</div>
              <Select
                required
                placeholder={t('metric')}
                variant={SelectVariant.primary}
                error={formik.touched.metric ? formik.errors.metric?.id : ''}
                onChange={(value) =>
                  formik.handleChange({
                    target: { name: 'metric', value },
                  })
                }
                {...omit(formik.getFieldProps('metric'), 'onChange')}
              >
                {metrics.map((metric) => {
                  return (
                    <SelectItem
                      key={metric}
                      id={metric}
                      label={t(`feedback_metric_${metric?.toLowerCase()}`)}
                    >
                      {t(`feedback_metric_${metric?.toLowerCase()}`)}
                    </SelectItem>
                  );
                })}
              </Select>
            </div>
            <div>
              <div className="my-1 text-s">{t('performance_talk_date')}</div>
              <DatePicker
                className="hubportal-datepicker"
                onChange={(value) => {
                  formik.handleChange({
                    target: { name: 'feedbackDate', value },
                  });
                }}
                format="dd.MM.yyyy"
                {...omit(formik.getFieldProps('feedbackDate'), 'onChange')}
              />
            </div>
            <div className="my-2 header-s">{t('feedback')}:</div>
            <Textarea
              rows={7}
              variant={TextareaVariant.primary}
              {...formik.getFieldProps('feedback')}
            />
          </div>
          {info?.message && (
            <Info variant="secondary" type={info?.type}>
              {t(info?.message)}
            </Info>
          )}
          <div className="flex gap-2 [&>button]:flex-1 [&>button]:justify-center">
            <Button variant={ButtonVariant.quaternary} onClick={onClose}>
              {t('cancel')}
            </Button>
            <Button
              disabled={!formik.isValid || formik.isSubmitting}
              loading={formik.isSubmitting}
              onClick={() => {
                formik.submitForm();
              }}
            >
              {t('add_feedback')}
            </Button>
          </div>
        </form>
      </div>
    </Modal>
  );
};
