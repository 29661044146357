import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { Sendout } from 'models';

export const Warning = ({
  item: warning,
  border,
}: {
  item: Sendout;
  border?: boolean;
}): JSX.Element => {
  const { t } = useTranslation();

  return (
    <span className="contents">
      <div>{t('flink_hq')}</div>
      <div className="flex flex-col gap-4">
        <div>{warning?.getLabel(t)}</div>
        <div className="detail-l">
          {warning.deliveredAt || warning.sendAt
            ? format(
                new Date(warning?.deliveredAt || warning?.sendAt || 0),
                'dd.MM.yyyy'
              )
            : ''}
        </div>
      </div>
      {border && (
        <div className="col-span-2 border-b border-solid border-secondary"></div>
      )}
    </span>
  );
};
