export enum RiderStates {
  'ONLINE' = 'ONLINE',
  'OFFLINE' = 'OFFLINE',
  'BUSY' = 'BUSY',
  'RETURNING' = 'RETURNING',
  'STARTING' = 'STARTING',
  'IDLE' = 'IDLE',
  'ON_BREAK' = 'ON_BREAK',
  'TEMP_OFFLINE' = 'TEMP_OFFLINE',
}

export enum VehicleTypes {
  'CAR' = 'car',
  'SCOOTER' = 'scooter',
  'BICYCLE' = 'bicycle',
  'CARGO' = 'cargo',
}

export enum ComplianceCaseType {
  NO_SHOW = 'NO_SHOW',
  INACTIVITY = 'INACTIVITY',
  SHORT_LATENESS = 'SHORT_LATENESS',
  LONG_LATENESS = 'LONG_LATENESS',
  LATE_SICK_NOTE = 'LATE_SICK_NOTE',
}

export type RiderLocation = {
  lat?: number;
  lng?: number;
  latitude?: number;
  longitude?: number;
  eta?: number;
};

export type RiderWithStatus = {
  id: string;
  status: RiderStates;
  statusReason?: string;
  firstName: string;
  lastName: string;
  activeOrderNumber?: string;
};

export enum StateCodesEnum {
  CREATED = 1, // Supported in saleor
  PICKERACCEPTED = 2, // Supported in saleor
  PACKED = 3, // Supported in saleor
  RIDERCLAIMED = 4,
  ONROUTE = 5,
  ARRIVED = 6, // Supported in saleor
  DELIVERED = 7, // Supported in saleor
  CANCELLED = 8,
  UNSPECIFIED = 9,
}

export type RiderWithStatusAndLocation = RiderWithStatus & RiderLocation;

export enum ComplianceCaseStatus {
  OPENED = 'OPENED',
  DRAFTED = 'DRAFTED',
  CLOSED = 'CLOSED',
  EMPTY = 'EMPTY',
  TERMINATION_REQUESTED = 'TERMINATION_REQUESTED',
  TERMINATION_APPROVED = 'TERMINATION_APPROVED',
  TERMINATION_REJECTED = 'TERMINATION_REJECTED',
  TERMINATED = 'TERMINATED',
}

export const ComplianceCaseStatusKeys = {
  [ComplianceCaseStatus.OPENED]: 'compliance_case_opened',
  [ComplianceCaseStatus.TERMINATION_REQUESTED]:
    'compliance_case_termination_requested',
  [ComplianceCaseStatus.TERMINATION_APPROVED]:
    'compliance_case_termination_approved',
};

export const RiderStateMappings = {
  ONLINE: 'ONLINE',
  RETURNING: 'RETURNING',
  BUSY: 'BUSY',
  TEMP_OFFLINE: 'INACTIVE',
  IDLE: 'INACTIVE',
  ON_BREAK: 'INACTIVE',
  STARTING: 'INACTIVE',
  OFFLINE: 'OFFLINE',
};

export const RiderStateColors = {
  [RiderStates.ONLINE]: 'bg-green-400',
  [RiderStates.RETURNING]: 'bg-pink-100',
  [RiderStates.BUSY]: 'bg-pink-300',
  [RiderStates.TEMP_OFFLINE]: 'bg-gray-400',
  [RiderStates.IDLE]: 'bg-orange-400',
  [RiderStates.ON_BREAK]: 'bg-gray-400',
  [RiderStates.STARTING]: 'bg-gray-400',
  [RiderStates.OFFLINE]: 'bg-gray-300',
};

export const getRiderStateColor = (
  status: RiderStates,
  reason?: string
): string => {
  if (status === RiderStates.TEMP_OFFLINE && reason) {
    return 'bg-orange-400';
  }
  return RiderStateColors[status] || 'bg-[#ABABAB]';
};

export const getRiderStateLabel = (
  status: RiderStates,
  reason?: string
): string => {
  if (status === RiderStates.TEMP_OFFLINE) {
    return reason
      ? `rider_status_temp_offline_reason_${reason.toLowerCase()}`
      : 'rider_status_temp_offline_reason_declining';
  }
  return `rider_status_${status.toLowerCase()}`;
};

export const UNDERAGE_WARNING_START_TIME = '22:00';
export const UNDERAGE_RESTRICTED_START_TIME = '23:00';
export const SICKNESS_RATE_THRESHOLD = 25;
export const MIN_SEARCH_TERM_LENGTH = 2;

export const CaseStatusToBadgeMap = {
  [ComplianceCaseStatus.OPENED]: {
    variant: 'red',
    label: 'eligible_for_termination',
  },
  [ComplianceCaseStatus.DRAFTED]: {
    variant: 'light',
    label: 'not_eligible_for_termination',
  },
  [ComplianceCaseStatus.CLOSED]: {
    variant: 'light',
    label: 'not_eligible_for_termination',
  },
  [ComplianceCaseStatus.TERMINATION_REJECTED]: {
    variant: 'light',
    label: 'not_eligible_for_termination',
  },
  [ComplianceCaseStatus.TERMINATION_REQUESTED]: {
    variant: 'yellow',
    label: 'termination_requested',
  },
  [ComplianceCaseStatus.TERMINATION_APPROVED]: {
    variant: 'green',
    label: 'termination_approved',
  },
};

export const Intervals = {
  ALL_TIME: 'all_time',
  LAST_3_MONTHS: 'last_3_months',
};
