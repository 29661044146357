import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { Pagination } from '@hubportal/components';
import { useEffect, useMemo, useState } from 'react';

const DEFAULT_PAGE_SIZE = 3;

const LateShowItem = ({ item }: { item: any }): JSX.Element => {
  const { t } = useTranslation();

  const date = new Date(item?.start_at || 0);
  const from = item?.start_at ? format(new Date(item.start_at), 'HH:mm') : null;
  const to = item?.end_at ? format(new Date(item.end_at), 'HH:mm') : null;

  return (
    <span className="contents">
      <div className="col-span-3 border-b border-solid border-secondary"></div>
      <div>{format(date, 'dd.MM.yyyy')}</div>
      <div className="flex flex-col gap-3">
        <div>{from && to ? `${from}-${to}` : t('time_unknown')}</div>
      </div>
    </span>
  );
};

export const LateShowsList = ({ list }: { list: any[] }): JSX.Element => {
  const { t } = useTranslation();

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: DEFAULT_PAGE_SIZE,
    pageCount: 0,
  });

  useEffect(() => {
    setPagination({
      pageIndex: 0,
      pageSize: DEFAULT_PAGE_SIZE,
      pageCount: Math.ceil((list?.length || 1) / DEFAULT_PAGE_SIZE),
    });
  }, [list]);

  const paginated = useMemo(() => {
    return list
      .sort(
        (a, b) =>
          new Date(b.start_at).getTime() - new Date(a.start_at).getTime()
      )
      .slice(
        pagination.pageIndex * pagination.pageSize,
        (pagination.pageIndex + 1) * pagination.pageSize
      );
  }, [list, pagination]);

  return (
    <div className="flex flex-col gap-2">
      <div className="flex flex-col rounded-xl bg-primary gap-4 text-gray-300 pb-6">
        {list?.length === 0 && (
          <div className="grow flex items-center text-s">
            {t('no_shows_history_empty')}
          </div>
        )}
        {list.length > 0 && (
          <>
            <div className="grid grid-cols-3 gap-x-6 gap-y-2 text-s">
              <div className="title-s">{t('dates')}</div>
              <div className="title-s">{t('shifts')}</div>

              {paginated.map((item, i) => {
                return <LateShowItem key={i} item={item} />;
              })}
            </div>
          </>
        )}
        {list?.length > DEFAULT_PAGE_SIZE && (
          <Pagination
            minimized
            goToPage={(pi) => {
              setPagination({ ...pagination, pageIndex: pi });
            }}
            pageCount={pagination.pageCount}
            pageIndex={pagination.pageIndex}
            pageSize={pagination.pageSize}
            setPageSize={(ps) => {
              setPagination({ ...pagination, pageSize: ps });
            }}
            totalCount={list?.length || 0}
            pageSizeOptions={[3]}
            labels={{ show: t('show') }}
          />
        )}
      </div>
    </div>
  );
};
