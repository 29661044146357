import { useState, useEffect } from 'react';
import { useOutletContext, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Pagination, TableSortBy } from '@hubportal/components';
import useStore from 'utils/store';
import withMFA from 'utils/hoc/withMFA';
import waitPermissions from 'utils/hoc/waitPermissions';
import usePermissions, { Permission } from 'utils/hooks/usePermissions';
import useWorkforceCompliance, {
  WorkforceComplianceSearchQuery,
} from 'utils/hooks/useWorkforceCompliance';
import { Spinner } from 'components/spinner';
import { HubsMultiselect } from 'partials/multiselect';
import { ComplianceTable } from 'partials/workforce-compliance-overview/table';
import { Help } from 'partials/help';
import { WorkforceCompliance } from 'models';
import { sortKey } from 'utils/helpers';
import { MIN_SEARCH_TERM_LENGTH } from 'utils/constants';

const DEFAULT_PAGE_SIZE = 500;
const DEFAULT_QUERY = {
  max: DEFAULT_PAGE_SIZE,
  skip: 0,
};

const WorkforceComplianceOverviewFC = (): JSX.Element => {
  const { t } = useTranslation();
  const { isAllowed } = usePermissions();
  const { selectedHub } = useStore();
  const { searchTerm } = useOutletContext<{ searchTerm: string }>();

  const [sortBy, setSortBy] = useState<TableSortBy>({ column: '', type: null });
  const [searchParams, setSearchParams] = useSearchParams();
  const [employees, setEmployees] = useState<WorkforceCompliance[]>([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: DEFAULT_PAGE_SIZE,
    pageCount: 0,
  });

  const [selectedHubs, setSelectedHubs] = useState<string[]>(
    isAllowed(Permission.READ_HUBS_ALL)
      ? searchParams?.get('hubs')?.split(',').filter(Boolean) || []
      : [selectedHub?.slug]
  );

  const [query, setQuery] = useState<WorkforceComplianceSearchQuery>({
    ...DEFAULT_QUERY,
    generic: searchTerm,
    selectedHubs,
    sort: sortKey(sortBy.column, sortBy.type),
  });

  const { status, error, data } = useWorkforceCompliance(query);

  useEffect(() => {
    setQuery({
      ...query,
      max: pagination.pageSize,
      skip: pagination.pageSize * pagination.pageIndex,
    });
  }, [pagination.pageIndex, pagination.pageSize]);

  useEffect(() => {
    setQuery({
      ...query,
      selectedHubs,
      skip: 0,
    });
    setPagination({ ...pagination, pageIndex: 0 });
  }, [selectedHubs]);

  useEffect(() => {
    if (searchTerm.length === 0 || searchTerm.length > MIN_SEARCH_TERM_LENGTH) {
      setQuery({ ...query, skip: 0, generic: searchTerm });
      setPagination({ ...pagination, pageIndex: 0 });
    }
  }, [searchTerm]);

  useEffect(() => {
    setQuery({
      ...query,
      sort: sortKey(sortBy.column, sortBy.type),
      skip: 0,
    });
    setPagination({ ...pagination, pageIndex: 0 });
  }, [sortBy]);

  useEffect(() => {
    const hubs = isAllowed(Permission.READ_HUBS_ALL)
      ? searchParams?.get('hubs')?.split(',').filter(Boolean) || []
      : [selectedHub?.slug];
    setSelectedHubs(hubs);
  }, [selectedHub]);

  useEffect(() => {
    setSearchParams(
      {
        hubs: selectedHubs.join(','),
        status: searchParams.get('status') || '',
        blocked: searchParams.get('blocked') || '',
        search: searchTerm,
        sort: sortKey(sortBy.column, sortBy.type),
      },
      { replace: true }
    );
  }, [selectedHubs, searchTerm, sortBy]);

  useEffect(() => {
    setEmployees(data?.results || []);
    setPagination({
      ...pagination,
      pageCount: Math.ceil(
        (data?.pagination?.total || 1) /
          (data?.pagination?.max || data?.pagination?.limit || 1)
      ),
    });
  }, [data]);

  return (
    <>
      <div className="px-10 py-4 flex flex-col gap-y-5">
        <div className="flex gap-x-2 items-start">
          {isAllowed(Permission.READ_HUBS_ALL) && (
            <div className="w-52">
              <HubsMultiselect
                values={selectedHubs}
                onChange={setSelectedHubs}
              />
            </div>
          )}
        </div>
      </div>
      <div className="relative mb-16">
        {error && <div className="text-pink text-xs px-10">{t(error)}</div>}
        {status === 'loading' && (
          <div className="absolute top-20 right-0 left-0 text-center">
            <Spinner />
          </div>
        )}
        <ComplianceTable
          data={employees}
          sortBy={sortBy}
          onSortChange={(sb) => setSortBy(sb)}
        />
      </div>
      <div className="px-10 py-2">
        <Pagination
          goToPage={(pi) => {
            setPagination({ ...pagination, pageIndex: pi });
          }}
          pageCount={pagination.pageCount}
          pageIndex={pagination.pageIndex}
          pageSize={pagination.pageSize}
          setPageSize={(ps) => {
            setPagination({ ...pagination, pageSize: ps });
          }}
          totalCount={data?.pagination?.total || 0}
          pageSizeOptions={[10, 20, 30, 50, 100, 250, 500]}
          labels={{ show: t('show') }}
        />
      </div>
      <Help />
    </>
  );
};

const WorkforceComplianceOverview = waitPermissions(
  withMFA(WorkforceComplianceOverviewFC)
);
export { WorkforceComplianceOverview };
