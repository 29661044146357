import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { Info } from 'components/info';
import {
  createPunchInOverride,
  getActivePunchOverrides,
} from 'utils/network/apis';
import { Button, Snackbar, SnackbarVariant } from '@hubportal/components';
import { PunchInOverrideModal } from './modal';

interface PunchOverride {
  ec_id: string;
  created_at: string;
  expired_at: string;
}

interface PunchInOverrideProps {
  ecId: string;
}

/**
 *
 * TODO: should be refactored
 *
 */
const PunchInOverride = ({ ecId }: PunchInOverrideProps): JSX.Element => {
  const { t } = useTranslation();

  const [modal, setModal] = useState<boolean>(false);
  const [snackbar, setSnackbar] = useState(false);

  const [activePunchInOverride, setActivePunchInOverride] =
    useState<PunchOverride>();

  const getActiveOverride = async (): Promise<void> => {
    try {
      const response = await getActivePunchOverrides(ecId);
      if (response.results && response.results?.length > 0) {
        setActivePunchInOverride(response.results[0]);
      }
    } catch (err) {}
  };

  const create = async ({ reason, duration }): Promise<void> => {
    await createPunchInOverride({
      ec_id: ecId,
      reason,
      time_window_in_minutes: duration,
    });
    await getActiveOverride();
    setSnackbar(true);
    setTimeout(() => setSnackbar(false), 3000);
  };

  useEffect(() => {
    if (ecId) {
      getActiveOverride();
    }
  }, [ecId]);

  return (
    <>
      {ecId && (
        <div>
          <Button
            disabled={modal || !!activePunchInOverride}
            loading={modal}
            onClick={() => setModal(true)}
          >
            {t('create_punch_in_override')}
          </Button>

          {activePunchInOverride && (
            <Info type="success" variant="secondary">
              {t('create_punch_in_override_active', {
                time: format(
                  new Date(activePunchInOverride.expired_at),
                  'HH:mm'
                ),
              })}
            </Info>
          )}
        </div>
      )}

      <PunchInOverrideModal
        open={modal}
        onClose={() => {
          setModal(false);
        }}
        onSubmit={create}
      />

      <Snackbar
        open={snackbar}
        variant={SnackbarVariant.success}
        icon="verification"
        onClose={() => setSnackbar(false)}
      >
        {t('create_punch_in_override_success')}
      </Snackbar>
    </>
  );
};

export { PunchInOverride };
