import sdk from '@hubportal/sdk';

export const track = (type, params): void => {
  window?.analytics?.track(type, params, {
    traits: {
      hub_slug: sdk?.getHub()?.slug,
      user_type: sdk?.getUserProfile()?.roles,
    },
    userId: sdk?.getUserProfile()?.id,
  });
};
