import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ComplianceCase, Employee } from 'models';
import {
  Badge,
  BadgeVariant,
  Button,
  ButtonVariant,
  Icon,
} from '@hubportal/components';
import { updateCase } from 'utils/network/apis';
import {
  ComplianceCaseStatus,
  ComplianceCaseType,
  SICKNESS_RATE_THRESHOLD,
} from 'utils/constants';
import { CloseCaseModal } from './close-case-modal';
import { NoShowsTrackerItem } from './termination-tracker-item/no-shows-tracker-item';
import { InactivityTrackerItem } from './termination-tracker-item/inactivity-tracker-item';
import { track } from 'utils/tracking';

interface CaseDecisionProps {
  employee: Employee;
  complianceCase: ComplianceCase;
  warnings: Warning[];
  comments: Comment[];
  sicknessRateCritical: boolean;
  onSuccess: (editedFields) => void;
  setSnackbar: (props) => void; // TODO: find a better way to display snackbar
}

const openSAP = (ecId): void => {
  window.open(`${process.env.REACT_APP_SAP_URL}#/user/${ecId}`, '_blank');
};

export const CaseDecision = ({
  employee,
  complianceCase,
  warnings,
  comments,
  sicknessRateCritical,
  onSuccess,
  setSnackbar,
}: CaseDecisionProps): JSX.Element => {
  const { t } = useTranslation();

  const [copied, setCopied] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const requestTermination = async (): Promise<void> => {
    try {
      setLoading(true);
      setError('');

      track('click', {
        component_content: 'to_sap',
        component_name: 'termination_tracker',
        screen_name: 'compliance',
        component_value: `${employee?.ecID}`,
      });

      await updateCase(employee.workforceID, complianceCase.id, {
        status: ComplianceCaseStatus.TERMINATION_REQUESTED,
      });

      openSAP(employee.ecID);
      onSuccess({ status: ComplianceCaseStatus.TERMINATION_REQUESTED });
    } catch (err: any) {
      setError(err?.response?.data?.error || err.message);
    } finally {
      setLoading(false);
    }
  };

  const dontTerminate = (): void => {
    track('click', {
      component_content: 'dont_terminate',
      component_name: 'termination_tracker',
      screen_name: 'compliance',
      component_value: `${employee?.ecID}`,
    });
    setConfirmationModal(true);
  };

  const copy = async (): Promise<void> => {
    track('click', {
      component_content: 'copy_case_information',
      component_name: 'termination_tracker',
      screen_name: 'compliance',
      component_value: `${employee?.ecID}`,
    });

    await navigator.clipboard.writeText(
      complianceCase.getCopy(employee, warnings, comments, t)
    );

    setCopied(true);
    setSnackbar({ visible: true, message: 'copied_to_clipboard' });
    setTimeout(() => setSnackbar({ visible: false, message: '' }), 3000);
  };

  return (
    <>
      <div className="grid grid-cols-[1fr,_3fr]  gap-1">
        <div className="p-8 border-r-2 border-solid border-secondary">
          <div className="flex flex-col gap-2 items-start">
            {complianceCase.type === ComplianceCaseType.INACTIVITY ? (
              <InactivityTrackerItem
                daysInactive={complianceCase?.daysInactive}
                lastActivityAt={complianceCase?.lastActivityAt || ''}
              />
            ) : (
              <NoShowsTrackerItem
                count={
                  complianceCase?.noShows?.filter(
                    (ns) => ns.type === 'UNEXCUSED'
                  )?.length
                }
              />
            )}
            {sicknessRateCritical && (
              <Badge variant={BadgeVariant['light-red']}>
                {t('sickness_rate_threshold', {
                  threshold: SICKNESS_RATE_THRESHOLD,
                })}
              </Badge>
            )}
          </div>
        </div>
        <div className="text-s p-8 flex flex-col gap-6">
          <div>{t('continue_in_sap_clipboard')}</div>
          <div className="flex gap-2 items-start min-w-max">
            <div
              className="flex gap-2 bg-secondary py-2 px-3 rounded title-m cursor-pointer text-white"
              onClick={() => {
                copy();
              }}
            >
              <Icon type="copy" />
              {t('copy_case_information')}
            </div>
            <Button
              disabled={!copied || loading}
              loading={loading}
              onClick={() => {
                requestTermination();
              }}
            >
              {t('continue_in_sap')}
            </Button>
            <div className="ml-auto">
              <Button
                disabled={loading}
                loading={loading}
                variant={ButtonVariant.quinary}
                onClick={() => dontTerminate()}
              >
                {t('do_not_terminate')}
              </Button>
            </div>
          </div>
          {error && <div className="text-pink text-xs">{t(error)}</div>}
        </div>
      </div>

      <CloseCaseModal
        open={confirmationModal}
        workforceID={employee.workforceID}
        caseID={complianceCase.id}
        onSuccess={onSuccess}
        setSnackbar={setSnackbar}
        onClose={() => setConfirmationModal(false)}
      />
    </>
  );
};
