import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { NoShow } from 'models/no-show';
import usePermissions, { Permission } from 'utils/hooks/usePermissions';
import { ExcuseNoShows } from 'partials/excuse-no-show';
import {
  Icon,
  Tooltip,
  TooltipVariant,
  Snackbar,
  SnackbarVariant,
  SelectItem,
  Select,
  SelectVariant,
  Pagination,
} from '@hubportal/components';
import { excuseNoShows } from 'utils/network/apis';
import { ComplianceCaseStatus } from 'utils/constants';

const DEFAULT_PAGE_SIZE = 3;

const NoShowItem = ({
  isWarningsModuleEnabled,
  item: noShow,
}: {
  isWarningsModuleEnabled?: boolean;
  item: NoShow;
}): JSX.Element => {
  const ref = useRef(null);

  const { t } = useTranslation();
  const [tooltipVisible, setTooltipVisible] = useState(false);

  // if (!noShow.schedules?.length) {
  //   return <span key={noShow?.id}></span>;
  // }

  const date = new Date(noShow?.at || 0);

  return (
    <span className="contents">
      <div className="col-span-3 border-b border-solid border-secondary"></div>
      <div>{format(date, 'dd.MM.yyyy')}</div>
      <div className="flex flex-col gap-3">
        {noShow?.schedules?.map((s, idx) => {
          const from = s.from ? format(new Date(s.from), 'HH:mm') : null;
          const to = s.to ? format(new Date(s.to), 'HH:mm') : null;
          return (
            <div key={idx}>
              {from && to ? `${from}-${to}` : t('time_unknown')}
            </div>
          );
        })}
      </div>

      <div>
        {isWarningsModuleEnabled &&
          (noShow.warningDeliveredAt ? (
            <div className="flex gap-2 items-center">
              {t('warning_sent')}
              <div
                ref={ref}
                onMouseOver={() => setTooltipVisible(true)}
                onMouseLeave={() => setTooltipVisible(false)}
              >
                <Icon type="warningFilled" size="small" />
              </div>
              <Tooltip
                refElement={ref?.current || null}
                visible={tooltipVisible}
                variant={TooltipVariant.pink}
                placement="top"
              >
                <div className="max-w-[200px]">
                  {format(
                    new Date(noShow?.warningDeliveredAt || 0),
                    'dd.MM.yyyy'
                  )}
                </div>
              </Tooltip>
            </div>
          ) : noShow.reminderDeliveredAt ? (
            t('reminder_sent')
          ) : (
            ' '
          ))}
      </div>
    </span>
  );
};

export const NoShows = ({
  workforceID,
  list,
  isWarningsModuleEnabled,
  complianceCaseStatus,
  onExcuseNoShowSuccess,
}: {
  workforceID: string;
  list: NoShow[];
  isWarningsModuleEnabled?: boolean;
  complianceCaseStatus: ComplianceCaseStatus;
  onExcuseNoShowSuccess: () => Promise<any>;
}): JSX.Element => {
  const { t } = useTranslation();
  const { isAllowed } = usePermissions();
  const [currentTab, setCurrentTab] = useState('unexcused');
  const [snackbar, setSnackbar] = useState<any>({
    open: false,
    type: '',
    message: '',
  });

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: DEFAULT_PAGE_SIZE,
    pageCount: 0,
  });

  const noShows = useMemo(() => {
    return {
      unexcused: list?.filter((ns) => ns?.type === 'UNEXCUSED'),
      excused: list?.filter((ns) => ns?.type === 'EXCUSED'),
    };
  }, [list]);

  const currentList = useMemo(() => {
    return currentTab === 'unexcused'
      ? noShows?.unexcused
      : currentTab === 'excused'
      ? noShows?.excused
      : [];
  }, [currentTab, list]);

  useEffect(() => {
    setPagination({
      pageIndex: 0,
      pageSize: DEFAULT_PAGE_SIZE,
      pageCount: Math.ceil((currentList?.length || 1) / DEFAULT_PAGE_SIZE),
    });
  }, [currentList]);

  const paginated = useMemo(() => {
    return currentList.slice(
      pagination.pageIndex * pagination.pageSize,
      (pagination.pageIndex + 1) * pagination.pageSize
    );
  }, [currentList, pagination]);

  return (
    <div className="flex flex-col gap-2">
      <div className="flex flex-col rounded-xl bg-primary gap-4 text-gray-300 pb-6">
        {list?.length === 0 && (
          <div className="grow flex items-center text-s">
            {t('no_shows_history_empty')}
          </div>
        )}
        {list.length > 0 && (
          <>
            <div className="self-start">
              <Select
                label=""
                placeholder=""
                value={{
                  id: currentTab,
                  label: t(currentTab),
                }}
                onChange={({ id: selectId }) => setCurrentTab(selectId)}
                variant={SelectVariant.secondary}
              >
                {['unexcused', 'excused'].map((ri) => (
                  <SelectItem key={ri} id={ri} label={t(ri)}>
                    {t(ri)}
                  </SelectItem>
                ))}
              </Select>
            </div>

            <div className="grid grid-cols-3 gap-x-6 gap-y-2 text-s">
              <div className="title-s">{t('dates')}</div>
              <div className="title-s">{t('shifts')}</div>
              {isWarningsModuleEnabled && (
                <div className="title-s">{t('status')}</div>
              )}
              {paginated.map((ns) => {
                return (
                  <NoShowItem
                    key={ns.id}
                    item={ns}
                    isWarningsModuleEnabled={isWarningsModuleEnabled}
                  />
                );
              })}
            </div>
            {currentList?.length > DEFAULT_PAGE_SIZE && (
              <Pagination
                minimized
                goToPage={(pi) => {
                  setPagination({ ...pagination, pageIndex: pi });
                }}
                pageCount={pagination.pageCount}
                pageIndex={pagination.pageIndex}
                pageSize={pagination.pageSize}
                setPageSize={(ps) => {
                  setPagination({ ...pagination, pageSize: ps });
                }}
                totalCount={currentList?.length || 0}
                pageSizeOptions={[3]}
                labels={{ show: t('show') }}
              />
            )}
            {currentTab === 'unexcused' &&
              noShows?.unexcused?.length > 0 &&
              isAllowed(Permission.EXCUSE_NO_SHOWS) &&
              (complianceCaseStatus === ComplianceCaseStatus.OPENED ||
                complianceCaseStatus === ComplianceCaseStatus.DRAFTED ||
                complianceCaseStatus === ComplianceCaseStatus.CLOSED) && (
                <ExcuseNoShows
                  list={noShows?.unexcused}
                  onSubmit={async (nsIDs) => {
                    try {
                      await excuseNoShows(workforceID, {
                        no_show_day_ids: nsIDs,
                      });
                      await onExcuseNoShowSuccess();
                      setSnackbar({
                        open: true,
                        type: SnackbarVariant.success,
                        message: t('excuse_no_shows_success'),
                      });
                      setTimeout(() => setSnackbar({ open: false }), 3000);
                    } catch (err: any) {
                      setSnackbar({
                        open: true,
                        type: SnackbarVariant.error,
                        message: err?.message,
                      });
                      setTimeout(() => setSnackbar({ open: false }), 3000);
                    }
                  }}
                />
              )}

            <Snackbar
              open={!!snackbar?.open}
              variant={snackbar?.type as SnackbarVariant}
              icon={
                snackbar?.type === SnackbarVariant.success
                  ? 'verification'
                  : 'warning'
              }
              onClose={() => setSnackbar({ open: false })}
            >
              <div id="snackbar-excuse-no-shows-success">
                {snackbar?.message || ''}
              </div>
            </Snackbar>
          </>
        )}
      </div>
    </div>
  );
};
