import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import {
  Badge,
  BadgeVariant,
  Button,
  ButtonVariant,
  Modal,
  ModalSize,
} from '@hubportal/components';
import LinesEllipsis from 'react-lines-ellipsis';

export const FeedbackItem = ({
  feedback,
}: {
  feedback: Comment;
}): JSX.Element => {
  const { t } = useTranslation();

  const [isClamped, setIsClamped] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);

  const handleReflow = (rleState): void => {
    const { clamped } = rleState;
    setIsClamped(clamped);
  };

  return (
    <>
      <div
        className={`rounded-lg bg-primary p-6 flex flex-col gap-4 ${
          isClamped ? 'cursor-pointer' : ''
        }`}
        onClick={() => {
          if (isClamped) {
            setIsExpanded(true);
          }
        }}
      >
        <div className="title-s text-white">
          {feedback.actor_firstname && feedback.actor_lastname
            ? `${feedback.actor_firstname} ${
                feedback.actor_lastname?.trim()[0]
              }.`
            : ''}
        </div>
        <div className="flex min-h-[1.5rem]">
          {feedback.type && (
            <Badge variant={BadgeVariant.white}>
              {t(`feedback_metric_${feedback.type?.toLowerCase()}`)}
            </Badge>
          )}
        </div>
        <div className="text-s text-white break-words">
          <LinesEllipsis
            text={feedback?.description}
            onReflow={handleReflow}
            maxLine={7}
          />
        </div>
        <div className="mt-auto border-b border-secondary"></div>
        <div className="detail-l text-gray-400">
          {format(
            new Date(feedback?.feedback_date || feedback?.created_at || 0),
            'MMM dd, yyyy'
          )}
        </div>
      </div>
      <Modal
        open={isExpanded}
        onClose={() => setIsExpanded(false)}
        size={ModalSize.large}
      >
        <div className="flex flex-col gap-4 items-start min-h-0">
          <div className="header-m text-white">
            {feedback.actor_firstname && feedback.actor_lastname
              ? `${feedback.actor_firstname} ${
                  feedback.actor_lastname?.trim()[0]
                }.`
              : ''}
          </div>

          <div className="detail-l text-gray-400">
            {format(
              new Date(feedback?.feedback_date || feedback?.created_at || 0),
              'MMM dd, yyyy'
            )}
          </div>
          <div className="header-s">{t('feedback')}:</div>
          <div className="flex">
            {feedback.type && (
              <Badge variant={BadgeVariant.white}>
                {t(`feedback_metric_${feedback.type?.toLowerCase()}`)}
              </Badge>
            )}
          </div>
          <div className="overflow-auto text-left">{feedback.description}</div>
          <div className="flex flex-col grow w-full [&>button]:justify-center">
            <Button
              variant={ButtonVariant.quinary}
              onClick={() => setIsExpanded(false)}
            >
              {t('close')}
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};
