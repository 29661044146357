import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { getWorkforceDetails } from 'utils/network/apis';
import { useCurrentPath } from 'utils/hooks/useCurrentPath';
import {
  isComplianceViewEnabled,
  getComplianceJobTitles,
} from 'utils/eppo/helpers';
import usePermissions, { Permission } from 'utils/hooks/usePermissions';
import { Employee } from 'models';
import { Back } from 'components/back';
import { Tab, Tabs, TabsVariant } from '@hubportal/components';
import { Profile } from 'partials/profile/v2/profile';

const WorkforceProfileIndex = (): JSX.Element => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const { path: currentPath } = useCurrentPath();

  const { t } = useTranslation();
  const { id } = useParams();
  const { isAllowed, isAllowedStrict } = usePermissions();

  const [employee, setEmployee] = useState<Employee | null>(null);
  const [error, setError] = useState('');

  const complianceJobTitles = useMemo(() => getComplianceJobTitles(), []);

  const isComplianceEnabled = useMemo(() => {
    return (
      (isAllowed(Permission.READ_COMPLIANCE_ALL) ||
        isAllowedStrict(
          Permission.READ_COMPLIANCE_ALLOWED,
          employee?.homeHub
        ) ||
        isAllowedStrict(
          Permission.READ_COMPLIANCE_LIMITED,
          employee?.homeHub
        )) &&
      employee?.isInternal &&
      complianceJobTitles?.includes(employee?.jobTitle || '') &&
      isComplianceViewEnabled()
    );
  }, [employee]);

  const isPerformanceEnabled = useMemo(() => {
    return (
      (isAllowed(Permission.READ_COMPLIANCE_ALL) ||
        isAllowedStrict(
          Permission.READ_COMPLIANCE_ALLOWED,
          employee?.homeHub
        ) ||
        isAllowedStrict(
          Permission.READ_COMPLIANCE_LIMITED,
          employee?.homeHub
        ) ||
        isAllowedStrict(
          Permission.READ_PERFORMANCE_LIMITED,
          employee?.homeHub
        )) &&
      employee?.isInternal &&
      complianceJobTitles?.includes(employee?.jobTitle || '') &&
      isComplianceViewEnabled()
    );
  }, [employee]);

  const tabs = [
    {
      id: 'compliance',
      label: 'compliance',
      isEnabled: isComplianceEnabled,
    },
    {
      id: 'performance',
      label: 'performance',
      isEnabled: isPerformanceEnabled,
    },
    {
      id: 'feedback',
      label: 'feedback_tracker',
      isEnabled: isPerformanceEnabled,
    },
    { id: 'settings', label: 'account_settings', isEnabled: true },
  ];

  const getData = async (): Promise<void> => {
    try {
      const response = await getWorkforceDetails(id);
      setEmployee(Employee.from(response, (e) => setEmployee(e?.clone())));
    } catch (err: any) {
      if (err?.response?.status === 404) {
        setError(err?.response?.data?.error);
      }
    }
  };

  useEffect(() => {
    if (id) {
      getData();
    }
  }, [id]);

  const changeTab = useCallback(
    (tabId: string) => {
      if (tabId === 'compliance') {
        navigate(`compliance${search || ''}`, { replace: true });
      } else if (tabId === 'performance') {
        navigate(`performance${search || ''}`, { replace: true });
      } else if (tabId === 'feedback') {
        navigate(`feedback${search || ''}`, { replace: true });
      } else {
        navigate(
          `${location.pathname?.replace(
            /\/(compliance|performance|feedback)/,
            ''
          )}${search || ''}`,
          {
            replace: true,
          }
        );
      }
    },
    [search]
  );

  const currentTab = useMemo(() => {
    if (currentPath === '/workforce/:id/compliance') {
      return 'compliance';
    } else if (currentPath === '/workforce/:id/performance') {
      return 'performance';
    } else if (currentPath === '/workforce/:id/feedback') {
      return 'feedback';
    }
    return 'settings';
  }, [location.pathname]);

  const back = (): void => {
    if (window.history.state && window.history.state.idx > 0) {
      navigate(-1);
    } else {
      navigate(`/workforce${search || ''}`, { replace: true });
    }
  };

  return (
    <>
      <div className="px-10 pt-8 flex flex-col gap-4">
        <Back onClick={back} />
        <Profile employee={employee} />
        {error && <div className="text-pink text-xs">{t(error)}</div>}
        <div>
          <Tabs
            value={currentTab}
            onChange={changeTab}
            variant={TabsVariant.secondary}
          >
            {tabs.map((tab) =>
              tab.isEnabled ? (
                <Tab id={tab.id} key={tab.id}>
                  <div className="title-m">{t(tab.label)}</div>
                </Tab>
              ) : (
                <div key={tab.id}></div>
              )
            )}
          </Tabs>
          <div className="border-b border-primary -mx-10" />
        </div>
      </div>

      <div className="p-8">
        <Outlet context={{ employee, setEmployee, employeeError: error }} />
      </div>
    </>
  );
};

export { WorkforceProfileIndex };
