import axios, { AxiosRequestConfig, Method } from 'axios';
import format from 'string-format';
import qs from 'qs';
import statics from './statics.json';
import sdk from '@hubportal/sdk';
// import { history } from 'utils/history';

// Abort request if it takes longer than 30 seconds
const REQUEST_TIMEOUT = 30 * 1000;

interface RequestOptions {
  data?: any;
  query?: string;
  variables?: Record<string, any>;
  customHeaders?: any;
  interpolate?: any;
  withCredentials?: boolean;
  meta?: any;
  queryOptions?: any;
}

type System = 'BFF' | 'AUTH0' | 'HUB_ORDERS_BFF';

type OperationType = 'REST' | 'GRAPHQL';

export const fetch = async (
  type: Method,
  system: System,
  operation: OperationType,
  path: keyof typeof statics.endpoints | '',
  options: RequestOptions = {}
): Promise<any> => {
  const headers = {
    'Content-Type': 'application/json',
    ...(system === 'BFF' ? { 'X-Country-Code': sdk.getCountry() } : {}),
    ...(options.customHeaders || {}),
  };

  const baseURL = process.env[`REACT_APP_${system}_BASE_URL`];

  let url =
    operation === 'GRAPHQL'
      ? baseURL
      : statics.endpoints[path as keyof typeof statics.endpoints];

  if (operation === 'REST' && options.interpolate) {
    url = format(url || '', options.interpolate);
  }

  if (operation === 'REST' && type === 'GET' && options.data) {
    url += `?${qs.stringify(options.data, {
      encode: true,
      indices: false,
      arrayFormat: options?.queryOptions?.arrayFormat || 'comma',
    })}`;
  }

  if (operation === 'REST' && options.query) {
    url += `?${qs.stringify(options.query, {
      encode: true,
      indices: false,
      arrayFormat: options?.queryOptions?.arrayFormat || 'comma',
    })}`;
  }

  let config: AxiosRequestConfig = {
    url,
    baseURL,
    headers,
    method: type,
    responseType: 'json',
    timeout: REQUEST_TIMEOUT,
    withCredentials: options.withCredentials,
  };

  if (operation === 'GRAPHQL') {
    config.data = { query: options.query, variables: options.variables };
  } else if (type !== 'GET') {
    config = { ...config, data: options.data };
  }

  try {
    const response = await axios(config);
    return response.data;
  } catch (err: any) {
    if (err?.response?.status >= 500) {
      sdk.reportError(
        new Error(`[Axios error]: Message: ${err}`),
        '@hubportal/workforce',
        {
          status: err?.response?.status,
          data: err?.response?.data,
          message: err?.message,
        }
      );
    }

    if (err?.response?.status === 401) {
      // localStorage.setItem('token', '');
      // history.go(0);
    }
    throw err;
  }
};
