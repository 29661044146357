export enum SendoutType {
  NO_SHOW_FIRST_REMINDER = 'FIRST_REMINDER',
  NO_SHOW_SECOND_REMINDER = 'SECOND_REMINDER',
  NO_SHOW_THIRD_REMINDER = 'THIRD_REMINDER',
  NO_SHOW_FIRST_WARNING = 'FIRST_WARNING',
  NO_SHOW_SECOND_WARNING = 'SECOND_WARNING',

  INACTIVITY_FIRST_WARNING = 'INACTIVITY_FIRST_WARNING',
  INACTIVITY_SECOND_WARNING = 'INACTIVITY_SECOND_WARNING',
  INACTIVITY_THIRD_WARNING = 'INACTIVITY_THIRD_WARNING',
  INACTIVITY_FOURTH_WARNING = 'INACTIVITY_FOURTH_WARNING',
}

export class Sendout {
  id: string;
  type: SendoutType;
  sendAt: string;
  deliveredAt?: string;
  noShowAt?: string;

  constructor(details: any = {}) {
    this.id = details.id;
    this.type = details.message_type;
    this.sendAt = details.sent_at;
    this.deliveredAt = details.delivered_at;
    this.noShowAt = details.no_show_at;
  }

  public static from(details: any): Sendout {
    return new Sendout(details);
  }

  public isWarning(): boolean {
    return [
      SendoutType.NO_SHOW_FIRST_WARNING,
      SendoutType.NO_SHOW_SECOND_WARNING,
      SendoutType.INACTIVITY_FIRST_WARNING,
      SendoutType.INACTIVITY_SECOND_WARNING,
      SendoutType.INACTIVITY_THIRD_WARNING,
      SendoutType.INACTIVITY_FOURTH_WARNING,
    ].includes(this.type);
  }

  public getLabel(t: any): string {
    if (
      [
        SendoutType.NO_SHOW_FIRST_WARNING,
        SendoutType.NO_SHOW_SECOND_WARNING,
      ].includes(this.type)
    ) {
      return t('unexcused_no_show');
    }

    if (this.type === SendoutType.INACTIVITY_FIRST_WARNING) {
      return t('inactivity_warning', { day: 14 });
    }

    if (this.type === SendoutType.INACTIVITY_SECOND_WARNING) {
      return t('inactivity_warning', { day: 20 });
    }

    if (this.type === SendoutType.INACTIVITY_THIRD_WARNING) {
      return t('inactivity_warning', { day: 27 });
    }

    if (this.type === SendoutType.INACTIVITY_FOURTH_WARNING) {
      return t('inactivity_final_warning', { day: 15 });
    }

    return '';
  }
}
