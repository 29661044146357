import { useEffect, useMemo, useState } from 'react';
import {
  Select,
  SelectItem,
  Tab,
  Tabs,
  TabsVariant,
} from '@hubportal/components';
import { TooltipTitle } from 'partials/tooltip-title';
import { useTranslation } from 'react-i18next';
import { ComplianceCaseStatus, Intervals } from 'utils/constants';
import { LateShifts } from 'partials/late-shifts';
import { format, isBefore, subDays } from 'date-fns';
import { NoShows } from 'partials/no-shows';
import { Employee } from 'models/employee';
import { getNoShowDays, getWorkforceLateness } from 'utils/network/apis';
import get from 'lodash.get';
import { NoShow } from 'models/no-show';
import { LateShowsList } from 'partials/late-shifts/list';

export const ShiftCompliance = ({
  employee,
  isWarningsModuleEnabled,
  complianceCaseStatus,
  onExcuseNoShowSuccess,
}: {
  employee: Employee | null;
  isWarningsModuleEnabled: boolean;
  complianceCaseStatus: ComplianceCaseStatus;
  onExcuseNoShowSuccess: () => Promise<any>;
}): JSX.Element => {
  const { t } = useTranslation();

  const [selectedInterval, setSelectedInterval] = useState(
    Intervals.LAST_3_MONTHS
  );
  const [currentTab, setCurrentTab] = useState('no_shows');

  const [data, setData] = useState<object>({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const period = useMemo(() => {
    const to = new Date();
    const hireDate = new Date(employee?.hireDate || 0);

    if (selectedInterval === Intervals.LAST_3_MONTHS) {
      let from = subDays(to, 90);
      if (isBefore(from, hireDate)) {
        from = hireDate;
      }
      return { from, to };
    } else {
      return { from: hireDate, to };
    }
  }, [employee, selectedInterval]);

  const getNoShows = async (): Promise<any> => {
    return await getNoShowDays(employee?.workforceID, {
      from: format(new Date(period.from), 'yyyy-MM-dd'),
      to: format(period.to, 'yyyy-MM-dd'),
      limit: 1000,
      offset: 0,
    });
  };

  const getLateShows = async (): Promise<any> => {
    return await getWorkforceLateness(employee?.workforceID, {
      from_date: format(new Date(period.from), 'yyyy-MM-dd'),
      to_date: format(period.to, 'yyyy-MM-dd'),
    });
  };

  const getData = async (interval: string): Promise<void> => {
    try {
      setError('');
      setLoading(true);

      const [nsResponse, lsResponse] = await Promise.all([
        getNoShows(),
        getLateShows(),
      ]);

      const noShows = (nsResponse?.results?.map(NoShow.from) || []).filter(
        (ns) => ns.type !== 'PENDING'
      );

      setData({
        ...data,
        [interval]: {
          ...data[interval],
          excused: noShows?.filter((ns) => ns.type === 'EXCUSED'),
          unexcused: noShows?.filter((ns) => ns.type === 'UNEXCUSED'),
          onTime: lsResponse?.on_time_shifts_list || [],
          late: lsResponse?.late_shifts_list || [],
          tooLate: lsResponse?.too_late_shifts_list || [],
          total:
            ((lsResponse?.completed_shifts as number) || 0) +
            (noShows.length as number),
        },
      });
    } catch (err: any) {
      setError(err?.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (
      employee?.workforceID &&
      Object.keys(get(data, selectedInterval, {})).length === 0
    ) {
      getData(selectedInterval);
    }
  }, [employee, selectedInterval]);

  const selectedData = useMemo(() => {
    return get(data, `${selectedInterval}`, {});
  }, [data, selectedInterval]);

  const tabs = [
    {
      id: 'no_shows',
      label: <div className="title-m">{t('no_shows')}</div>,
    },
    {
      id: 'too_late_shifts',
      label: <div className="title-m">{t('shifts_late', { n: 10 })}</div>,
    },
    {
      id: 'late_shifts',
      label: <div className="title-m">{t('shifts_late', { n: 5 })}</div>,
    },
  ];

  return (
    <div className="flex flex-col gap-2">
      <div className="title-s flex justify-between">
        <TooltipTitle
          label={t('shift_compliance')}
          info={t('shift_compliance_tooltip')}
        />
        <Select
          label=""
          placeholder=""
          value={{
            id: selectedInterval,
            label: t(`filter_${selectedInterval.toLowerCase()}`),
          }}
          onChange={({ id: selectId }) => setSelectedInterval(selectId)}
        >
          {[Intervals.ALL_TIME, Intervals.LAST_3_MONTHS].map((ri) => (
            <SelectItem
              key={ri}
              id={ri}
              label={t(`filter_${ri.toLowerCase()}`)}
            >
              {t(`filter_${ri.toLowerCase()}`)}
            </SelectItem>
          ))}
        </Select>
      </div>

      <div className="flex flex-col rounded-xl bg-primary gap-4 text-gray-300 py-4 px-8 pb-6 min-h-[14rem]">
        {error && <div className="text-pink text-xs">{t(error)}</div>}
        <LateShifts
          data={{
            onTime: selectedData?.onTime?.length || 0,
            late: selectedData?.late?.length || 0,
            tooLate: selectedData?.tooLate?.length || 0,
            excused: selectedData?.excused?.length || 0,
            unexcused: selectedData?.unexcused?.length || 0,
            total: selectedData?.total || 0,
          }}
          period={period}
          loaded={!loading}
        />

        {selectedData?.total > 0 && (
          <>
            <Tabs
              value={currentTab}
              onChange={setCurrentTab}
              direction="horizontal"
              variant={TabsVariant.secondary}
            >
              {tabs.map((tab) => (
                <Tab id={tab.id} key={tab.id}>
                  {tab.label}
                </Tab>
              ))}
            </Tabs>
            {currentTab === 'no_shows' && (
              <NoShows
                workforceID={employee?.workforceID || ''}
                isWarningsModuleEnabled={isWarningsModuleEnabled}
                list={[
                  ...(selectedData.excused || []),
                  ...(selectedData.unexcused || []),
                ]}
                complianceCaseStatus={complianceCaseStatus}
                onExcuseNoShowSuccess={onExcuseNoShowSuccess}
              />
            )}
            {currentTab === 'too_late_shifts' && (
              <LateShowsList list={selectedData.tooLate || []} />
            )}
            {currentTab === 'late_shifts' && (
              <LateShowsList list={selectedData.late || []} />
            )}
          </>
        )}
      </div>
    </div>
  );
};
