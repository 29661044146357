import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Select, SelectItem, SelectProps } from '@hubportal/components';
import { getCPHValues } from 'utils/eppo/helpers';

const CPHFilter = ({ onChange, ...props }: SelectProps): JSX.Element => {
  const { t } = useTranslation();
  const list = useMemo(() => getCPHValues(), []);

  return (
    <Select
      label=""
      placeholder={t('cph')}
      onChange={onChange}
      minWidth={false}
      dropdownFit="wide"
      {...props}
    >
      {[{ cph: 'all', age: 'all' }, ...list].map(({ cph, age }) => {
        return (
          <SelectItem
            key={age}
            id={`${age}`}
            label={
              cph === 'all'
                ? t('cph')
                : `${((cph as number) / 100).toFixed(2)}+ €/${t('hour')}`
            }
          >
            {cph === 'all'
              ? t('all')
              : `${((cph as number) / 100).toFixed(2)}+ €/${t('hour')}`}
          </SelectItem>
        );
      })}
    </Select>
  );
};

export { CPHFilter };
