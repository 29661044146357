export const FEATURE_FLAGS = {
  DASHBOARD_VARIABLES: '_rw__workforce_dashboard_variables',
  JOB_TITLES: 'wm-job-title-type',
  RIDER_PERFORMANCE_VIEW: '_rw__rider_performance_view',
  PUNCH_ONLINE: '_rw__go_online_only_when_punched_in',
  RIDER_WIDGET_VEHICLE_ASSIGNMENT: 'of-rider-widget-vehicle-assignment',
};

export const FEATURE_VARIANTS = {
  ON: 'on',
  OFF: 'off',
};
