import React from 'react';

interface SidebarProps {
  header: JSX.Element;
}

const Sidebar = ({
  header,
  children,
}: React.PropsWithChildren<SidebarProps>): JSX.Element => {
  return (
    <div className="w-80 fixed h-full border-r border-gray-600 pl-10 pr-4 pt-10 z-[1] bg-secondary flex flex-col gap-y-4">
      <div>{header}</div>
      <div>{children}</div>
    </div>
  );
};

export { Sidebar };
