import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useNavigate, useSearchParams } from 'react-router-dom';
import {
  Header,
  Tab,
  Tabs,
  Input,
  Badge,
  BadgeVariant,
  BadgeSize,
} from '@hubportal/components';
import { isComplianceViewEnabled } from 'utils/eppo/helpers';
import { getComplianceOpenedCases } from 'utils/network/apis';
import useDebounce from 'utils/hooks/useDebounce';
import usePermissions, { Permission } from 'utils/hooks/usePermissions';
import useStore from 'utils/store';

const DEFAULT_COMPLIANCE_PAGE_LIMIT = 500;

const WorkforceIndex = (): JSX.Element => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { selectedHub, allowedHubs } = useStore();
  const { permissionsReady, isAllowed } = usePermissions();

  const [searchParams] = useSearchParams();
  const [searchTerm, setSearchTerm] = useState(
    searchParams?.get('search') || ''
  );
  const debouncedSearchTerm = useDebounce(searchTerm, 500);
  const [openedCaseCount, setOpenedCaseCount] = useState(0);

  const changeTab = useCallback((id: string) => {
    if (id === 'performance') {
      navigate('performance', { replace: true });
    } else {
      navigate('', { replace: true });
    }
  }, []);

  const isComplianceEnabled =
    isComplianceViewEnabled() &&
    (isAllowed(Permission.READ_COMPLIANCE_ALL) ||
      isAllowed(Permission.READ_COMPLIANCE_ALLOWED));

  const currentTab = useMemo(() => {
    if (location.pathname === '/workforce/performance') {
      return 'performance';
    }
    return 'overview';
  }, [location.pathname]);

  /**
   * HMs -> compliance_allowed permission and don't see the hub-select -> selected hub
   * People ops -> compliance_all permission and see the hub-select -> all hubs
   * Admins -> compliance_allowed permission and see the hub-select -> allowed hubs
   */

  const getOpenedCaseCount = async (limit): Promise<number> => {
    if (isComplianceEnabled) {
      const hub = isAllowed(Permission.READ_COMPLIANCE_ALL)
        ? ''
        : isAllowed(Permission.READ_COMPLIANCE_ALLOWED)
        ? isAllowed(Permission.READ_HUBS_ALL)
          ? allowedHubs
          : selectedHub?.slug
        : selectedHub?.slug;

      const response = await getComplianceOpenedCases({
        offset: 0,
        limit,
        hub,
      });

      const count = response?.count || 0;
      setOpenedCaseCount(count);
      return count;
    }
    return 0;
  };

  const onLoad = async (): Promise<void> => {
    const count = await getOpenedCaseCount(DEFAULT_COMPLIANCE_PAGE_LIMIT);
    if (count >= DEFAULT_COMPLIANCE_PAGE_LIMIT) {
      getOpenedCaseCount(4 * DEFAULT_COMPLIANCE_PAGE_LIMIT);
    }
  };

  useEffect(() => {
    if (permissionsReady && isComplianceEnabled) {
      onLoad();
    }
  }, [permissionsReady, selectedHub]);

  return (
    <>
      <Header title={t('welcome')}>
        <div className="flex gap-6">
          {isComplianceEnabled && (
            <Tabs value={currentTab} onChange={changeTab}>
              <Tab id="overview" icon="listDots">
                {t('overview')}
              </Tab>
              <Tab id="performance" icon="legal">
                <>
                  {t('compliance')}
                  {openedCaseCount > 0 && (
                    <div className="-mt-3">
                      <Badge
                        round="large"
                        size={BadgeSize.small}
                        variant={BadgeVariant.pink}
                      >
                        {openedCaseCount}
                      </Badge>
                    </div>
                  )}
                </>
              </Tab>
            </Tabs>
          )}
          <div className="w-52">
            <Input
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              onClear={() => setSearchTerm('')}
              focusStyled
              icon="magnify"
              placeholder={t('search_riders')}
            />
          </div>
        </div>
      </Header>
      <Outlet context={{ searchTerm: debouncedSearchTerm }} />
    </>
  );
};

export { WorkforceIndex };
