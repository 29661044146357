import { useEffect, useState } from 'react';

import { init } from '@eppo/js-client-sdk';

const EPPO_SDK_KEY = process.env.REACT_APP_EPPO_API_KEY;

interface EppoProviderProvider {
  waitForInitialization?: boolean;
  children: JSX.Element;
  loadingComponent?: JSX.Element;
}

export default function EppoProvider({
  waitForInitialization = true,
  children,
  loadingComponent = <div></div>,
}: EppoProviderProvider): JSX.Element {
  const [isInitialized, setIsInitialized] = useState(false);
  useEffect(() => {
    init({
      apiKey: EPPO_SDK_KEY || '',
      maxCacheAgeSeconds: 0,
      useExpiredCache: false,
      updateOnFetch: 'always',
      requestTimeoutMs: 5000,
      numInitialRequestRetries: 1,
      pollAfterSuccessfulInitialization: false,
      pollAfterFailedInitialization: false,
      assignmentLogger: {
        logAssignment: (assignment) => {
          // console.log(assignment);
        },
      },
    }).then(() => {
      return setIsInitialized(true);
    });
  }, []);

  if (!waitForInitialization || isInitialized) {
    return children;
  }
  return loadingComponent;
}
