import { useCallback, useEffect, useMemo, useState } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Icon, Tabs, Tab } from '@hubportal/components';
import { Sidebar } from 'components/sidebar';
import { getWorkforceDetails } from 'utils/network/apis';
import { useCurrentPath } from 'utils/hooks/useCurrentPath';
import {
  isComplianceViewEnabled,
  getComplianceJobTitles,
} from 'utils/eppo/helpers';
import usePermissions, { Permission } from 'utils/hooks/usePermissions';
import { Employee } from 'models';

const WorkforceProfileIndex = (): JSX.Element => {
  const navigate = useNavigate();
  const { path: currentPath } = useCurrentPath();
  const { id } = useParams();
  const { t } = useTranslation();
  const { isAllowed, isAllowedStrict } = usePermissions();
  const [employee, setEmployee] = useState<Employee | null>(null);
  const [error, setError] = useState('');

  const complianceJobTitles = useMemo(() => getComplianceJobTitles(), []);

  const tabs = [
    { id: 'overview', label: 'overview', isEnabled: true },
    {
      id: 'compliance',
      label: 'compliance',
      isEnabled:
        (isAllowed(Permission.READ_COMPLIANCE_ALL) ||
          isAllowedStrict(
            Permission.READ_COMPLIANCE_ALLOWED,
            employee?.homeHub
          ) ||
          isAllowedStrict(
            Permission.READ_COMPLIANCE_LIMITED,
            employee?.homeHub
          )) &&
        employee?.isInternal &&
        complianceJobTitles?.includes(employee?.jobTitle || '') &&
        isComplianceViewEnabled(),
    },
  ];

  const getData = async (): Promise<void> => {
    try {
      const response = await getWorkforceDetails(id);
      setEmployee(Employee.from(response));
    } catch (err: any) {
      if (err?.response?.status === 404) {
        setError(err?.response?.data?.error);
      }
    }
  };

  useEffect(() => {
    if (id) {
      getData();
    }
  }, [id]);

  const changeTab = useCallback((tabId: string) => {
    if (tabId === 'compliance') {
      navigate('compliance', { replace: true });
    } else {
      navigate(location.pathname?.replace('/compliance', ''), {
        replace: true,
      });
    }
  }, []);

  const currentTab = useMemo(() => {
    if (currentPath === '/workforce/:id/compliance') {
      return 'compliance';
    }
    return 'overview';
  }, [location.pathname]);

  const back = (): void => {
    if (window.history.state && window.history.state.idx > 0) {
      navigate(-1);
    } else {
      navigate(
        currentTab === 'overview'
          ? '/workforce'
          : currentTab === 'compliance'
          ? '/workforce/performance'
          : ''
      );
    }
  };

  return (
    <>
      <Sidebar
        header={
          <div
            className="flex items-center hover:cursor-pointer hover:text-pink mb-10"
            onClick={() => back()}
          >
            <Icon type="arrowSignLeft" />
            <span className="ml-2">{t('back')}</span>
          </div>
        }
      >
        <Tabs value={currentTab} onChange={changeTab} direction="vertical">
          {tabs.map((tab) =>
            tab.isEnabled ? (
              <Tab id={tab.id} key={tab.id}>
                {t(tab.label)}
              </Tab>
            ) : (
              <div key={tab.id}></div>
            )
          )}
        </Tabs>
      </Sidebar>
      <div className="ml-80">
        <div className="px-8 py-4 header-m text-white border-b border-primary">
          {employee?.getFullName()}
        </div>

        {error && <div className="text-pink text-xs p-10">{t(error)}</div>}

        <div className="p-8">
          <Outlet context={{ employee, setEmployee, employeeError: error }} />
        </div>
      </div>
    </>
  );
};

export { WorkforceProfileIndex };
