import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { TerminationTrackerItem } from './termination-tracker-item';

export const InactivityTrackerItem = ({
  daysInactive,
  lastActivityAt,
}: {
  daysInactive: number;
  lastActivityAt: string;
}): JSX.Element => {
  const { t } = useTranslation();

  return (
    <TerminationTrackerItem
      count={daysInactive}
      label={
        <div className="flex flex-col whitespace-nowrap">
          <div>{t('inactivity_days')}</div>
          {lastActivityAt && (
            <div className="detail-m lowercase">{`(${t(
              'last_activity'
            )}: ${format(new Date(lastActivityAt || 0), 'dd/MM/yy')})`}</div>
          )}
        </div>
      }
      tooltip={t('termination_tracker_inactivity_days_tooltip')}
    />
  );
};
