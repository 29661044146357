import { useEffect, useMemo, useState } from 'react';
import {
  useNavigate,
  useOutletContext,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { Spinner } from 'components/spinner';
import { getPerformanceGraphData } from 'utils/network/apis';
import {
  isComplianceViewEnabled,
  getComplianceJobTitles,
} from 'utils/eppo/helpers';
import usePermissions, { Permission } from 'utils/hooks/usePermissions';
import withMFA from 'utils/hoc/withMFA';
import { Employee } from 'models/employee';
import { Calendar } from 'components/calendar';
import { getPastCalendarWeeks } from 'utils/helpers';
import { Tab, Tabs, TabsVariant } from '@hubportal/components';
import {
  AcceptanceRateGraph,
  UtrGraph,
  AverageTimeAtCustomerGraph,
  OrdersDeliveredOnTimeGraph,
  AveragePickingTimeGraph,
  PostOrderIssueRateGraph,
} from 'partials/graphs';

const WorkforcePerformanceFC = (): JSX.Element => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { id } = useParams();
  const { employee, employeeError } = useOutletContext<{
    employee: Employee | null;
    employeeError?: string;
  }>();
  const { isAllowed, isAllowedStrict } = usePermissions();
  const [searchParams, setSearchParams] = useSearchParams();

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [data, setData] = useState<any>({});
  const [selectedTab, setSelectedTab] = useState<'rider' | 'oa'>();

  const periods = useMemo(() => {
    return getPastCalendarWeeks(10, true);
  }, []);

  const [selectedPeriodIndex, setSelectedPeriodIndex] = useState(
    searchParams?.get('cw')
      ? Math.max(
          periods.findIndex((p) => p.cw === Number(searchParams?.get('cw'))),
          0
        )
      : 0
  );

  const selectedPeriod = useMemo(
    () => periods[selectedPeriodIndex],
    [periods, selectedPeriodIndex]
  );

  useEffect(() => {
    setSearchParams(
      {
        cw: `${selectedPeriod?.cw || 0}`,
      },
      { replace: true }
    );
  }, [selectedPeriod]);

  const complianceJobTitles = useMemo(() => getComplianceJobTitles(), []);

  const isComplianceEnabled = useMemo(() => {
    return (
      employee?.isInternal &&
      (isAllowed(Permission.READ_COMPLIANCE_ALL) ||
        isAllowedStrict(
          Permission.READ_COMPLIANCE_ALLOWED,
          employee?.homeHub
        ) ||
        isAllowedStrict(
          Permission.READ_COMPLIANCE_LIMITED,
          employee?.homeHub
        ) ||
        isAllowedStrict(
          Permission.READ_PERFORMANCE_LIMITED,
          employee?.homeHub
        )) &&
      complianceJobTitles?.includes(employee?.jobTitle || '') &&
      isComplianceViewEnabled()
    );
  }, [employee]);

  const onLoad = async (): Promise<void> => {
    try {
      setError('');
      setLoading(true);
      const response = await getPerformanceGraphData(id, {
        from_date: format(new Date(selectedPeriod.from), 'yyyy-MM-dd'),
        to_date: format(selectedPeriod.to, 'yyyy-MM-dd'),
        hub_slug: employee?.homeHub || employee?.hubSlug || '',
      });
      setData(response);
    } catch (err: any) {
      setError(err?.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (id && isComplianceEnabled) {
      onLoad();
    }
  }, [id, isComplianceEnabled, selectedPeriod]);

  useEffect(() => {
    if (employee?.workforceID && !isComplianceEnabled) {
      navigate(location.pathname?.replace('/compliance', ''), {
        replace: true,
      });
    }
  }, [employee]);

  useEffect(() => {
    const workedAsRider = data?.acceptance_rate?.items?.some(
      (i) => i.value > 0
    );
    const workedAsOa = data?.average_picking_time?.items?.some(
      (i) => i.value > 0
    );

    const isRiderRole = employee?.jobTitle?.toLowerCase().includes('rider');
    setSelectedTab(
      !isRiderRole && workedAsOa
        ? 'oa'
        : workedAsRider
        ? 'rider'
        : workedAsOa
        ? 'oa'
        : 'rider'
    );
  }, [data]);

  const workedAsRider = data?.acceptance_rate?.items?.some((i) => i.value > 0);
  const workedAsOa = data?.average_picking_time?.items?.some(
    (i) => i.value > 0
  );

  const tabs = [
    { id: 'rider', label: 'riding_performance', isEnabled: workedAsRider },
    { id: 'oa', label: 'inside_hub_performance', isEnabled: workedAsOa },
  ];

  return (
    <>
      <div className="flex flex-col gap-4">
        <div className="self-start">
          <Calendar
            list={periods}
            selectedIndex={selectedPeriodIndex}
            onChange={setSelectedPeriodIndex}
          />
        </div>
        {!loading && !error ? (
          <>
            {selectedTab && (
              <div className="self-center p-1 rounded-lg [&>div>div]:font-medium bg-primary">
                <Tabs
                  value={selectedTab}
                  onChange={setSelectedTab as any}
                  variant={TabsVariant.tertiary}
                >
                  {tabs.map((tab) => (
                    <Tab id={tab.id} key={tab.id} disabled={!tab.isEnabled}>
                      {t(tab.label)}
                    </Tab>
                  ))}
                </Tabs>
              </div>
            )}
            {selectedTab === 'rider' ? (
              <div className="flex flex-col gap-12">
                <UtrGraph
                  selectedPeriod={selectedPeriod}
                  data={data?.operational_utr}
                />
                <AcceptanceRateGraph
                  selectedPeriod={selectedPeriod}
                  data={data?.acceptance_rate}
                />
                <AverageTimeAtCustomerGraph
                  selectedPeriod={selectedPeriod}
                  data={data?.average_time_at_customer}
                />
                <OrdersDeliveredOnTimeGraph
                  selectedPeriod={selectedPeriod}
                  data={data?.orders_delivered_on_time}
                />
              </div>
            ) : selectedTab === 'oa' ? (
              <div className="flex flex-col gap-12">
                <AveragePickingTimeGraph
                  selectedPeriod={selectedPeriod}
                  data={data?.average_picking_time}
                />
                <PostOrderIssueRateGraph
                  selectedPeriod={selectedPeriod}
                  data={data?.post_order_issue_rate}
                />
              </div>
            ) : (
              <></>
            )}
          </>
        ) : (
          <></>
        )}
      </div>
      {!employeeError && loading && <Spinner />}
      {error && <span className="text-xs text-pink">{error}</span>}
    </>
  );
};

const WorkforcePerformance = withMFA(WorkforcePerformanceFC);
export { WorkforcePerformance };
