import { useTranslation } from 'react-i18next';
import { TerminationTrackerItem } from './termination-tracker-item';

export const WarningsTrackerItem = ({
  count,
}: {
  count: number;
}): JSX.Element => {
  const { t } = useTranslation();

  return (
    <TerminationTrackerItem
      count={count}
      label={t('warnings_sent')}
      tooltip={t('termination_tracker_warnings_tooltip')}
    />
  );
};
