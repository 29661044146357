import { useEffect, useState } from 'react';
import { getHubsList } from 'utils/network/apis';
import useStore from 'utils/store';

export type GetHubsStatus = 'idle' | 'loading' | 'success' | 'error';

export type GetHubsResponse = {
  status: GetHubsStatus;
  error: string | null;
  data: any[];
};

const useHubs = (): GetHubsResponse => {
  const [status, setStatus] = useState<GetHubsStatus>('idle');
  const { hubs, setHubs } = useStore();
  const [error, setError] = useState(null);

  useEffect(() => {
    const getHubs = async (): Promise<void> => {
      if (hubs.length > 0) {
        return;
      }

      setStatus('loading');
      setError(null);

      try {
        const response = await getHubsList();
        setHubs(response.map((hub) => ({ id: hub.Slug })));
        setStatus('success');
      } catch (err: any) {
        setStatus('error');
        setError(
          err?.response?.status === 403 ? 'permission_required' : err?.message
        );
      }
    };

    getHubs();
  }, []);

  return { status, error, data: hubs };
};

export default useHubs;
