import { useTranslation } from 'react-i18next';
import { Select, SelectItem, SelectProps } from '@hubportal/components';

const WorkStatusFilter = ({ onChange, ...props }: SelectProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Select
      placeholder={t('next_shift')}
      onChange={onChange}
      minWidth={false}
      dropdownFit="wide"
      {...props}
    >
      {[
        { id: 'all', label: 'next_shift' },
        { id: 'working_now', label: 'working_now' },
        { id: 'today', label: 'today' },
        { id: 'tomorrow', label: 'tomorrow' },
        { id: 'later', label: 'later' },
        { id: 'not_scheduled', label: 'not_scheduled' },
      ].map(({ id, label }) => {
        return (
          <SelectItem key={id} id={id} label={t(label)}>
            {id === 'all' ? t('all') : t(label)}
          </SelectItem>
        );
      })}
    </Select>
  );
};

export { WorkStatusFilter };
