import { useRef, useState } from 'react';
import { zeroPad } from 'utils/helpers';
import { Icon, Tooltip, TooltipVariant } from '@hubportal/components';

interface TerminationTrackerItemProps {
  count: number;
  label: JSX.Element | string;
  tooltip: string;
}

export const TerminationTrackerItem = ({
  count,
  label,
  tooltip,
}: TerminationTrackerItemProps): JSX.Element => {
  const ref = useRef(null);
  const [tooltipVisible, setTooltipVisible] = useState(false);

  return (
    <div className="flex flex-col items-start w-8">
      <div className="header-l text-white">{zeroPad(count, 2)}</div>
      <div className="detail-l lowercase">{label}</div>
      <div
        className="mt-2"
        ref={ref}
        onMouseOver={() => setTooltipVisible(true)}
        onMouseLeave={() => setTooltipVisible(false)}
      >
        <Icon type="infoCircle" size="small" />
      </div>
      <Tooltip
        refElement={ref?.current || null}
        visible={tooltipVisible}
        variant={TooltipVariant.pink}
        placement="top"
      >
        <div className="max-w-[200px]">{tooltip}</div>
      </Tooltip>
    </div>
  );
};
