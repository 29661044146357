import { useState } from 'react';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { createExternalRider } from 'utils/network/apis';
import {
  Button,
  Input,
  InputVariant,
  SelectVariant,
  MASKED_DATE_OPTIONS,
} from '@hubportal/components';
import { Info } from 'components/info';
import {
  BIRTHDAY_MASKED_DATE_OPTIONS,
  BIRTHDAY_REGEX,
  PHONE_NUMBER_REGEX,
} from 'utils/helpers';
import { HubsSelect, CountrySelect, AgencySelect } from 'partials/select';
import omit from 'lodash.omit';
import * as Yup from 'yup';
import useStore from 'utils/store';

type Schema = {
  name: string;
  surname: string;
  email: string;
  phone: string;
  hub: { id: string; label: string };
  externalAgency: { id: string; label: string };
  country: { id: string; label: string };
  birthday: string;
};
interface CreateExternalRiderProps {
  onSuccess(): void;
}

const initialValues: Schema = {
  name: '',
  surname: '',
  email: '',
  phone: '',
  hub: { id: '', label: '' },
  externalAgency: { id: '', label: '' },
  country: { id: '', label: '' },
  birthday: '',
};

const getValidationSchema = (t): object =>
  Yup.object({
    name: Yup.string().required(t('required')),
    surname: Yup.string().required(t('required')),
    email: Yup.string().email(t('invalid_email')).required(t('required')),
    phone: Yup.string()
      .matches(RegExp(PHONE_NUMBER_REGEX), t('invalid_phone_number'))
      .required(t('required')),
    hub: Yup.object({
      id: Yup.string().required(t('required')),
      label: Yup.string(),
    }).required(),
    country: Yup.object({
      id: Yup.string().required(t('required')),
      label: Yup.string(),
    }).required(),
    externalAgency: Yup.object({
      id: Yup.string().required(t('required')),
      label: Yup.string(),
    }).required(),
    birthday: Yup.string()
      .matches(RegExp(BIRTHDAY_REGEX), t('invalid_date'))
      .when('country', {
        is: ({ label }: { label: string }) => label === 'NL',
        then: (schema) => schema.required(t('required')),
      }),
  });

const CreateExternalRider = ({
  onSuccess,
}: CreateExternalRiderProps): JSX.Element => {
  const { t } = useTranslation();
  const [info, setInfo] = useState<null | {
    type: 'success' | 'error';
    message: string;
  }>();
  const { country: initialCountry, selectedHub: initialHub } = useStore();

  const onSubmit = async (data): Promise<void> => {
    try {
      setInfo(null);
      await createExternalRider(data);
      setInfo({ type: 'success', message: t('rider_account_created') });
      onSuccess();
    } catch (err: any) {
      const error = err?.response?.data?.error || '';
      const message =
        err?.response?.status === 409
          ? 'rider_account_already_exist'
          : error || 'generic_error';
      setInfo({ type: 'error', message: message });
    }
  };

  const formik = useFormik({
    initialValues: {
      ...initialValues,
      ...(initialCountry
        ? {
            country: {
              id: initialCountry.toLowerCase(),
              label: t(`countries_${initialCountry.toLowerCase()}`),
            },
          }
        : null),
      ...(initialHub
        ? {
            hub: {
              id: initialHub?.slug,
              label: initialHub?.slug,
            },
          }
        : null),
    },
    validationSchema: getValidationSchema(t),
    onSubmit: async () => {
      formik.setSubmitting(true);
      await onSubmit({
        first_name: formik.values.name,
        last_name: formik.values.surname,
        email: formik.values.email,
        phone_number: formik.values.phone,
        hub_slug: formik.values.hub.id?.toLowerCase(),
        country: formik.values.country.id?.toUpperCase(),
        external_agency: formik.values.externalAgency.id?.toLowerCase(),
        date_of_birth: formik.values.birthday,
      });
      formik.setSubmitting(false);
    },
  });

  return (
    <form
      onSubmit={formik.handleSubmit}
      autoComplete="off"
      className="flex flex-col grow gap-4"
    >
      <div className="flex gap-2">
        <Input
          required
          label={t('first_name')}
          placeholder={t('first_name')}
          error={formik.touched.name ? formik.errors.name : ''}
          variant={InputVariant.secondary}
          {...formik.getFieldProps('name')}
        />

        <Input
          required
          label={t('last_name')}
          placeholder={t('last_name')}
          error={formik.touched.surname ? formik.errors.surname : ''}
          variant={InputVariant.secondary}
          {...formik.getFieldProps('surname')}
        />
      </div>
      <Input
        required
        type="email"
        label={t('email')}
        placeholder="max@gmail.com"
        variant={InputVariant.secondary}
        error={formik.touched.email ? formik.errors.email : ''}
        {...formik.getFieldProps('email')}
      />

      <Input
        required
        type="tel"
        label={t('phone_number')}
        placeholder="+491112223344"
        variant={InputVariant.secondary}
        error={formik.touched.phone ? formik.errors.phone : ''}
        {...formik.getFieldProps('phone')}
      />

      <HubsSelect
        variant={SelectVariant.secondary}
        error={formik.touched?.hub ? formik.errors?.hub?.id : ''}
        onChange={(value) => {
          const country = value?.id.toLowerCase().slice(0, 2);
          formik.handleChange({ target: { name: 'hub', value } });
          formik.handleChange({
            target: {
              name: 'country',
              value: {
                id: country.toLowerCase(),
                label: t(`countries_${country.toLowerCase()}`),
              },
            },
          });
        }}
        {...omit(formik.getFieldProps('hub'), 'onChange')}
      />

      <CountrySelect
        disabled
        variant={SelectVariant.secondary}
        error={formik.touched.country ? formik.errors.country?.id : ''}
        onChange={(value) =>
          formik.handleChange({ target: { name: 'country', value } })
        }
        {...omit(formik.getFieldProps('country'), 'onChange')}
      />

      <AgencySelect
        variant={SelectVariant.secondary}
        error={
          formik.touched.externalAgency ? formik.errors.externalAgency?.id : ''
        }
        onChange={(value) =>
          formik.handleChange({
            target: { name: 'externalAgency', value },
          })
        }
        {...omit(formik.getFieldProps('externalAgency'), 'onChange')}
      />

      <Input
        label={t('date_of_birth')}
        placeholder="2001-08-31"
        required={formik.values.country.label === 'NL'}
        maskOptions={{
          ...MASKED_DATE_OPTIONS,
          ...BIRTHDAY_MASKED_DATE_OPTIONS,
        }}
        variant={InputVariant.secondary}
        error={formik.touched.birthday ? formik.errors.birthday : ''}
        {...formik.getFieldProps('birthday')}
      />

      <div className="mt-auto">
        {info?.message && <Info type={info?.type}>{t(info?.message)}</Info>}
        <Button
          type="submit"
          disabled={
            !formik.isValid ||
            Object.keys(formik.touched).length === 0 ||
            formik.isSubmitting
          }
          loading={formik.isSubmitting}
        >
          {t('create_external_rider')}
        </Button>
      </div>
    </form>
  );
};

export { CreateExternalRider };
