import {
  Multiselect,
  MultiselectItem,
  MultiselectProps,
} from '@hubportal/components';
import { useTranslation } from 'react-i18next';

const PerformanceGroupMultiselect = ({
  values,
  onChange,
  ...props
}: MultiselectProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Multiselect
      labels={{
        default: t('performance_group'),
      }}
      values={values}
      onChange={onChange}
      displayDefaultLabel
      minWidth={false}
      dropdownFit="wide"
      {...props}
    >
      {[
        'top_performer',
        'good_performer',
        'low_performer',
        'worst_performer',
        // 'churn_risk', TODO:
      ].map((level) => {
        return (
          <MultiselectItem
            key={level}
            value={level}
            label={t(`performance_group_${level}`)}
          >
            {t(`performance_group_${level}`)}
          </MultiselectItem>
        );
      })}
    </Multiselect>
  );
};

export { PerformanceGroupMultiselect };
