import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { Button } from '@hubportal/components';
import { HubsSelect } from 'partials/select';
import { updateHub } from 'utils/network/apis';
import omit from 'lodash.omit';
import * as Yup from 'yup';

interface ChangeHubProps {
  id: string;
  hub: string;
  disabled: boolean;
}

const ChangeHub = ({
  id,
  hub: hubSlug,
  disabled,
}: ChangeHubProps): JSX.Element => {
  const { t } = useTranslation();

  const formik = useFormik({
    initialValues: { hub: { id: '', label: '' } },
    validationSchema: Yup.object({
      hub: Yup.object({
        id: Yup.string().required(t('required')),
        label: Yup.string(),
      }).required(),
    }),
    onSubmit: async () => {
      const hub = formik.values.hub.id?.toLowerCase();
      try {
        await updateHub(id, { hub_slug: hub });
        formik.resetForm({ values: { hub: { id: hub, label: hub } } });
      } catch (err) {
        // TODO: err
      }
    },
  });

  useEffect(() => {
    formik.resetForm({ values: { hub: { id: hubSlug, label: hubSlug } } });
  }, [hubSlug]);

  return (
    <div>
      <form
        onSubmit={formik.handleSubmit}
        autoComplete="off"
        className="flex items-center gap-2"
      >
        <div className="w-full">
          <HubsSelect
            error={formik.touched?.hub ? formik.errors?.hub?.id : ''}
            onChange={(value) =>
              formik.handleChange({ target: { name: 'hub', value } })
            }
            disabled={disabled}
            {...omit(formik.getFieldProps('hub'), 'onChange')}
          />
        </div>
        <Button
          disabled={
            !formik.dirty || !formik.isValid || formik.isSubmitting || disabled
          }
          loading={formik.isSubmitting}
          onClick={() => {
            formik.submitForm();
          }}
        >
          {t('save')}
        </Button>
      </form>
    </div>
  );
};

export { ChangeHub };
