import { useMemo } from 'react';
import { Icon } from '@hubportal/components';
import { useTranslation } from 'react-i18next';
import { getNeedHelpButtonConfig } from 'utils/eppo/helpers';
import useStore from 'utils/store';
import classnames from 'classnames';
import sdk from '@hubportal/sdk';

export const Help = (): JSX.Element => {
  const { t } = useTranslation();
  const { country } = useStore();

  const link = useMemo(() => {
    const config = getNeedHelpButtonConfig();
    const roles = sdk?.getUserProfile()?.roles;

    for (const role of roles) {
      if (config?.[country]?.[role]) {
        return config?.[country]?.[role];
      }
    }
  }, []);

  const openZendesk = (): void => {
    window.open(`${link}`, '_blank');
  };

  return link ? (
    <div
      className={classnames([
        'fixed',
        'bottom-8',
        'right-8',
        'p-4',
        'flex',
        'gap-2',
        'items-center',
        'rounded-full',
        'bg-pink-400',
        'text-white',
        'title-s',
        'cursor-pointer',
        'shadow-lg',
        'shadow-black',
      ])}
      style={{ bottom: '2rem', right: '2rem' }}
      onClick={openZendesk}
    >
      <Icon type="question" />
      {t('need_help')}
    </div>
  ) : (
    <></>
  );
};
