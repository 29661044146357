import { Outlet } from 'react-router-dom';

const Main = (): any => {
  return (
    <div style={{ width: '100%' }}>
      <div className="h-full min-h-screen">
        <div className="flex bg-secondary text-gray-300">
          <div className="flex flex-col flex-1">
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};

export { Main };
