import { memo } from 'react';
import { createPortal } from 'react-dom';
import { Icon } from '@hubportal/components';
import classnames from 'classnames';

interface SidebarProps {
  refElement?: Element | null;
  className?: string;
  children: JSX.Element;
  header?: JSX.Element | string;
  footer?: JSX.Element | string;
  onClose: () => void;
  open?: boolean;
}

function SidebarCmp({
  open,
  refElement,
  className,
  header,
  footer,
  onClose,
  children,
}: SidebarProps): JSX.Element | null {
  if (!open) {
    return null;
  }

  const classes = classnames(
    'h-full p-4 bg-primary shadow-inner break-words flex flex-col gap-4 text-gray-300',
    className
  );

  const sidebarContent = (
    <div className="w-96 fixed right-0 top-0 bottom-0 z-50">
      <div className={classes}>
        <div className="p-2 flex">
          {header && header}
          <div className="ml-auto">
            <Icon type="close" onClick={() => onClose()} />
          </div>
        </div>
        <div className="flex flex-col grow min-h-0 overflow-auto">
          {children}
        </div>
        {footer && <div className="">{footer}</div>}
      </div>
    </div>
  );

  return createPortal(sidebarContent, refElement || document.body);
}

const Sidebar = memo(SidebarCmp);

export { Sidebar };
