import { useTranslation } from 'react-i18next';
import {
  Table,
  TableSortBy,
  TableVariant,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@hubportal/components';
import { track } from 'utils/tracking';
import { Spinner } from 'components/spinner';
import { WorkforceCompliance } from 'models';
import {
  format,
  formatDistanceToNowStrict,
  isToday,
  isTomorrow,
} from 'date-fns';
import { ComplianceCaseStatus } from 'utils/constants';
import { EmployeeStatusBadge } from 'partials/employee-status/badge';

const getEmployeeVariant = (employee: WorkforceCompliance): string => {
  if (employee.caseStatus === ComplianceCaseStatus.OPENED) {
    return 'requires_analysis';
  }
  if (employee.caseStatus === ComplianceCaseStatus.TERMINATION_REQUESTED) {
    return 'termination_requested';
  }
  if (employee.caseStatus === ComplianceCaseStatus.TERMINATION_APPROVED) {
    return 'termination_approved';
  }
  if (employee?.isTerminated()) {
    return 'terminated';
  }
  if (employee.isExternal) {
    return 'external';
  }
  if (employee.probationEndDate) {
    if (employee.isInProbation()) {
      return 'in_probation';
    } else {
      return 'probation_passed';
    }
  }
  return '';
};

const NextShift = ({
  employee,
}: {
  employee: WorkforceCompliance;
}): JSX.Element => {
  const { t } = useTranslation();
  const { workingShift, nextShift, isExternal } = employee;

  return workingShift?.scheduleID ? (
    <div className="flex flex-col gap-0.5">
      <div className="flex items-center gap-1 text-s text-white">
        <span className={`w-1 h-1 rounded shrink-0 bg-green-400`} />
        {t('working_now')}
      </div>
      <div className="detail-l text-gray-300">{`${format(
        workingShift.from,
        'HH:mm'
      )} - ${format(workingShift.to, 'HH:mm')}`}</div>
    </div>
  ) : nextShift?.scheduleID ? (
    <div className="flex flex-col gap-0.5">
      <div className="text-s text-white">
        {isToday(nextShift.from)
          ? t('later_today')
          : isTomorrow(nextShift.from)
          ? t('tomorrow')
          : `${format(nextShift.from, 'E, dd LLL')}`}
      </div>
      <div className="detail-l text-gray-300">{`${format(
        nextShift.from,
        'HH:mm'
      )} - ${format(nextShift.to, 'HH:mm')}`}</div>
    </div>
  ) : !isExternal ? (
    <div className="text-s text-[#BFBFBF]">{t('not_scheduled')}</div>
  ) : (
    <>-</>
  );
};

export const AllEmployeesPerformanceTable = ({
  data,
  loading,
  sortBy,
  onSortChange,
  displayShiftsData,
  displayAcceptanceRate,
  displayUtr,
  displayOpsData,
  isPerformanceGroupEnabled,
  sortableColumns,
  calendarWeek,
}: {
  data: WorkforceCompliance[];
  loading?: boolean;
  sortBy: TableSortBy;
  onSortChange: (sb: TableSortBy) => void;
  displayShiftsData?: boolean;
  displayAcceptanceRate?: boolean;
  displayUtr?: boolean;
  displayOpsData?: boolean;
  isPerformanceGroupEnabled?: boolean;
  sortableColumns: string[];
  calendarWeek?: number;
}): JSX.Element => {
  const { t } = useTranslation();

  const onClickRow = (index: number, employee: WorkforceCompliance): void => {
    track('click', {
      component_name: 'employee_record',
      component_content: `${employee?.ecID}` || '',
      component_value: `${index + 1}`,
      component_variant: getEmployeeVariant(employee),
      screen_name: 'workforce_dashboard',
    });
  };

  return (
    <div className="flex flex-col">
      <Table
        variant={TableVariant.secondary}
        sortLabels={{
          asc: t('sort_ascending'),
          desc: t('sort_descending'),
        }}
        sortBy={sortBy}
        onSortChange={onSortChange}
        gridTemplateColumns={`
        minmax(150px, 2fr)
        minmax(120px, 1fr)
        minmax(120px, 1fr)
        ${displayShiftsData ? 'minmax(max-content, 1fr)' : ''}
        ${displayShiftsData ? 'minmax(max-content, 1fr)' : ''}
        ${displayAcceptanceRate ? 'minmax(150px, 1fr)' : ''}
        ${displayUtr ? 'minmax(150px, 1fr)' : ''}
        ${displayShiftsData ? 'minmax(150px, 1fr)' : ''}
        ${displayShiftsData ? 'max-content' : ''}
        ${displayOpsData ? 'minmax(180px, 1fr)' : ''}
        ${displayOpsData ? 'minmax(180px, 1fr)' : ''}
        max-content
    `}
      >
        <Thead>
          <Tr>
            <Th name="NAME" sortable={sortableColumns.includes('NAME')}>
              {t('name')}
            </Th>
            <Th
              name="JOB_TITLE"
              sortable={sortableColumns.includes('JOB_TITLE')}
            >
              {t('title')}
            </Th>
            <Th>
              {t(
                isPerformanceGroupEnabled
                  ? 'performance_group'
                  : 'feedback_need'
              )}
            </Th>
            {displayShiftsData && (
              <>
                <Th
                  name="NO_SHOWS"
                  sortable={sortableColumns.includes('NO_SHOWS')}
                  align="center"
                >
                  <div className="flex flex-col items-center gap-1">
                    <div>{t('no_shows')}</div>
                    <div className="detail-l text-[#BFBFBF]">{`CW ${calendarWeek} / ${t(
                      'total'
                    )}`}</div>
                  </div>
                </Th>
                <Th
                  name="LATENESS"
                  sortable={sortableColumns.includes('LATENESS')}
                  align="center"
                >
                  {t('late_shifts')}
                </Th>
              </>
            )}
            {displayAcceptanceRate && (
              <>
                <Th
                  name="ACCEPTANCE_RATE"
                  sortable={sortableColumns.includes('ACCEPTANCE_RATE')}
                >
                  {t('acceptance_rate')}
                </Th>
              </>
            )}
            {displayUtr && (
              <>
                <Th name="UTR" sortable={sortableColumns.includes('UTR')}>
                  {t('utr')}
                </Th>
              </>
            )}
            {displayShiftsData && <Th name="NEXT_SHIFT">{t('next_shift')}</Th>}
            {displayShiftsData && (
              <Th
                name="LAST_FEEDBACK"
                sortable={sortableColumns.includes('LAST_FEEDBACK')}
              >
                {t('last_feedback')}
              </Th>
            )}
            {displayOpsData && (
              <>
                <Th
                  name="AVERAGE_PICKING_TIME"
                  sortable={sortableColumns.includes('AVERAGE_PICKING_TIME')}
                >
                  {t('average_picking_time')}
                </Th>
                <Th
                  name="POST_ORDER_ISSUE_RATE"
                  sortable={sortableColumns.includes('POST_ORDER_ISSUE_RATE')}
                >
                  {t('post_order_issue_rate')}
                </Th>
              </>
            )}
            <Th
              name="PROBATION_STATUS"
              sortable={sortableColumns.includes('PROBATION_STATUS')}
              align="end"
            >
              {t('employment_status')}
            </Th>
          </Tr>
        </Thead>
        {!loading && (
          <Tbody>
            {data.map((employee, i) => {
              return (
                <Tr
                  key={employee?.workforceID}
                  href={`/workforce/${employee.workforceID}/compliance${
                    calendarWeek ? `?cw=${calendarWeek}` : ''
                  }`}
                  target="_self"
                  onClick={() => onClickRow(i, employee)}
                  variant={
                    employee.isCritical &&
                    employee.caseStatus === ComplianceCaseStatus.OPENED
                      ? 'error'
                      : employee.caseStatus === ComplianceCaseStatus.OPENED
                      ? 'warning'
                      : 'default'
                  }
                >
                  <Td>
                    <span className="title-s text-white truncate">
                      {employee.fullName}
                    </span>
                  </Td>
                  <Td>
                    <span className="text-s text-white truncate">
                      {employee.jobTitle || '-'}
                    </span>
                  </Td>
                  <Td>
                    <span className="text-s text-white capitalize">
                      {isPerformanceGroupEnabled ? (
                        employee.performanceGroup ? (
                          t(`performance_group_${employee.performanceGroup}`)
                        ) : (
                          <span className="text-[#BFBFBF] normal-case">
                            {t('no_data')}
                          </span>
                        )
                      ) : (
                        employee.feedbackNeed || (
                          <span className="text-[#BFBFBF]">{t('no')}</span>
                        )
                      )}
                    </span>
                  </Td>
                  {displayShiftsData && (
                    <Td>
                      <span className="text-s text-white capitalize flex w-full h-full items-center justify-center">
                        {(employee.totalNoShows ||
                          employee.periodNoShows ||
                          0) > 0 ? (
                          `${employee.periodNoShows || 0} / ${
                            employee.totalNoShows || 0
                          }`
                        ) : (
                          <span className="text-[#BFBFBF]">{`0 / 0`}</span>
                        )}
                      </span>
                    </Td>
                  )}
                  {displayShiftsData && (
                    <Td>
                      <div className="flex w-full h-full items-center justify-center text-s text-white">
                        {employee?.lateShifts || (
                          <span className="text-[#BFBFBF]">{0}</span>
                        )}
                      </div>
                    </Td>
                  )}
                  {displayAcceptanceRate && (
                    <Td>
                      <div className="flex w-full h-full items-center justify-center text-s text-white">
                        {employee?.acceptanceRate ? (
                          `${employee?.acceptanceRate}%`
                        ) : (
                          <span className="text-[#BFBFBF]">{t('no_data')}</span>
                        )}
                      </div>
                    </Td>
                  )}
                  {displayUtr && (
                    <Td>
                      <div className="flex w-full h-full items-center justify-center text-s text-white">
                        {employee?.utr ? (
                          employee?.utr.toFixed(2)
                        ) : (
                          <span className="text-[#BFBFBF]">{t('no_data')}</span>
                        )}
                      </div>
                    </Td>
                  )}
                  {displayShiftsData && (
                    <Td>
                      <NextShift employee={employee} />
                    </Td>
                  )}
                  {displayShiftsData && (
                    <Td>
                      <div className="text-s text-white">
                        {employee.lastFeedback ? (
                          formatDistanceToNowStrict(
                            new Date(employee.lastFeedback),
                            { addSuffix: true }
                          )
                        ) : (
                          <span className="text-[#BFBFBF]">
                            {t('no_feedback')}
                          </span>
                        )}
                      </div>
                    </Td>
                  )}
                  {displayOpsData && (
                    <Td>
                      <div className="flex w-full h-full items-center justify-center text-s text-white">
                        {employee?.averagePickingTime ? (
                          employee?.averagePickingTime.toFixed(2)
                        ) : (
                          <span className="text-[#BFBFBF]">{t('no_data')}</span>
                        )}
                      </div>
                    </Td>
                  )}
                  {displayOpsData && (
                    <Td>
                      <div className="flex w-full h-full items-center justify-center text-s text-white">
                        {employee?.postOrderIssueRate ? (
                          `${employee?.postOrderIssueRate.toFixed(2)}%`
                        ) : employee?.postOrderIssueRate === 0 ? (
                          '0%'
                        ) : (
                          <span className="text-[#BFBFBF]">{t('no_data')}</span>
                        )}
                      </div>
                    </Td>
                  )}
                  <Td>
                    <EmployeeStatusBadge employee={employee} />
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        )}
      </Table>
      {loading && (
        <div className="bg-primary/50 min-h-[24rem] w-full col-span-8 flex items-center justify-center rounded-b-lg">
          <Spinner />
        </div>
      )}
    </div>
  );
};
