import { useTranslation } from 'react-i18next';
import { Select, SelectItem, SelectProps } from '@hubportal/components';
import useStore from 'utils/store';

const CountrySelect = ({ onChange, ...props }: SelectProps): JSX.Element => {
  const { t } = useTranslation();
  const { countries } = useStore();

  return (
    <Select
      required
      label={t('country')}
      placeholder={t('country')}
      onChange={onChange}
      {...props}
    >
      {countries.map((c) => {
        return (
          <SelectItem
            key={c.id}
            id={c.id}
            label={t(`countries_${c.id}`).toString()}
          >
            {t(`countries_${c.id}`)}
          </SelectItem>
        );
      })}
    </Select>
  );
};

export { CountrySelect };
