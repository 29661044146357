import { useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import { HubPerformance, Metrics } from 'partials/hub-performance';
import { AllEmployees } from 'partials/workforce-overview/all-employees';
import { AllEmployeesPerformance } from 'partials/workforce-overview/all-employees-performance';
import { NoShowsMetric } from 'partials/workforce-overview/no-shows-metric';
import { LateShiftsMetric } from 'partials/workforce-overview/late-shifts-metric';
import { AcceptanceRateMetric } from 'partials/workforce-overview/acceptance-rate-metric';
import { AveragePickingTimeMetric } from 'partials/workforce-overview/average-picking-time-metric';
import { PostOrderIssueRateMetric } from 'partials/workforce-overview/post-order-issue-rate-metric';
import { UTRMetric } from 'partials/workforce-overview/utr-metric';
import { WithMFAWrapper } from 'utils/hoc/withMFA';
import usePermissions, { Permission } from 'utils/hooks/usePermissions';
import waitPermissions from 'utils/hoc/waitPermissions';
import { MIN_SEARCH_TERM_LENGTH } from 'utils/constants';
import useStore from 'utils/store';

const WorkforceOverviewFC = (): JSX.Element => {
  const { isAllowed } = usePermissions();
  const { country } = useStore();

  const {
    searchTerm,
    setSearchTerm,
    selectedHubs,
    selectedPeriod,
    setUpdatedAt,
  } = useOutletContext<{
    searchTerm: string;
    setSearchTerm: (s) => void;
    selectedHubs: string[];
    selectedPeriod: { cw: number; from: Date; to: Date };
    setUpdatedAt: (u) => void;
  }>();

  const [selectedMetric, setSelectedMetric] = useState<Metrics | null>(null);

  const isComplianceAllowed =
    isAllowed(Permission.READ_COMPLIANCE_ALL) ||
    isAllowed(Permission.READ_COMPLIANCE_ALLOWED) ||
    isAllowed(Permission.READ_COMPLIANCE_LIMITED);

  const isPerformanceAllowed =
    isAllowed(Permission.READ_COMPLIANCE_ALL) ||
    isAllowed(Permission.READ_COMPLIANCE_ALLOWED) ||
    isAllowed(Permission.READ_COMPLIANCE_LIMITED) ||
    isAllowed(Permission.READ_PERFORMANCE_LIMITED);

  useEffect(() => {
    if (selectedHubs.length !== 1) {
      setSelectedMetric(null);
    }
  }, [selectedHubs]);

  useEffect(() => {
    if (searchTerm) {
      setSelectedMetric(null);
    }
  }, [searchTerm]);

  const isSearchActive =
    searchTerm && searchTerm.length > MIN_SEARCH_TERM_LENGTH;

  return (
    <>
      <div className="px-10 py-8 flex flex-col gap-12">
        {isPerformanceAllowed ? (
          <WithMFAWrapper>
            {selectedHubs?.length === 1 && !isSearchActive && (
              <HubPerformance
                selectedHub={selectedHubs[0]}
                selectedMetric={selectedMetric}
                setSelectedMetric={setSelectedMetric}
                selectedPeriod={selectedPeriod}
                setUpdatedAt={setUpdatedAt}
              />
            )}
            {!selectedMetric && (
              <AllEmployeesPerformance
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
                setUpdatedAt={setUpdatedAt}
                selectedHubs={selectedHubs}
                selectedPeriod={selectedPeriod}
                isTerminationEligibilityFilterEnabled={
                  isComplianceAllowed && country === 'DE'
                }
                isCPHFilterEnabled={country === 'NL'}
                isChurnRiskFilterEnabled={country === 'NL'}
              />
            )}
            {selectedMetric === Metrics.NO_SHOWS && (
              <NoShowsMetric
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
                selectedHubs={selectedHubs}
                selectedPeriod={selectedPeriod}
              />
            )}
            {selectedMetric === Metrics.LATE_SHIFTS && (
              <LateShiftsMetric
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
                selectedHubs={selectedHubs}
                selectedPeriod={selectedPeriod}
              />
            )}
            {selectedMetric === Metrics.ACCEPTANCE_RATE && (
              <AcceptanceRateMetric
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
                selectedHubs={selectedHubs}
                selectedPeriod={selectedPeriod}
              />
            )}
            {selectedMetric === Metrics.UTR && (
              <UTRMetric
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
                selectedHubs={selectedHubs}
                selectedPeriod={selectedPeriod}
              />
            )}
            {selectedMetric === Metrics.AVERAGE_PICKING_TIME && (
              <AveragePickingTimeMetric
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
                selectedHubs={selectedHubs}
                selectedPeriod={selectedPeriod}
              />
            )}
            {selectedMetric === Metrics.POST_ORDER_ISSUE_RATE && (
              <PostOrderIssueRateMetric
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
                selectedHubs={selectedHubs}
                selectedPeriod={selectedPeriod}
              />
            )}
          </WithMFAWrapper>
        ) : (
          <AllEmployees
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            selectedHubs={selectedHubs}
          />
        )}
      </div>
    </>
  );
};

const WorkforceOverview = waitPermissions(WorkforceOverviewFC);
export { WorkforceOverview };
