import { CreateExternalRider } from './external';
import { CreateInternalRider } from './internal';

const CreateRider = ({
  type,
  onSuccess,
}: {
  type: 'internal' | 'external' | null;
  onSuccess: () => void;
}): JSX.Element => {
  return type === 'internal' ? (
    <CreateInternalRider onSuccess={onSuccess} />
  ) : type === 'external' ? (
    <CreateExternalRider onSuccess={onSuccess} />
  ) : (
    <></>
  );
};

export { CreateRider };
