import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Employee } from 'models';
import { Button } from '@hubportal/components';
import { TempOfflineModal } from './modal';

interface SetTempOfflineProps {
  employee: Employee;
  onSuccess: () => void;
}

const SetTempOffline = ({
  employee,
  onSuccess,
}: SetTempOfflineProps): JSX.Element => {
  const { t } = useTranslation();

  const [modal, setModal] = useState<boolean>(false);

  return (
    <>
      <div>
        <Button disabled={modal} loading={modal} onClick={() => setModal(true)}>
          {t('set_rider_temp_offline')}
        </Button>
      </div>
      <TempOfflineModal
        open={modal}
        onClose={() => {
          setModal(false);
        }}
        onSubmit={async (reason) => {
          await employee?.setTempOffline(reason);
          onSuccess();
        }}
      />
    </>
  );
};

export { SetTempOffline };
