import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Modal,
  RadioGroup,
  Radio,
  Button,
  ButtonVariant,
} from '@hubportal/components';
import { Info } from 'components/info';
import usePermissions, { Permission } from 'utils/hooks/usePermissions';

export const BlockOptionsModal = ({
  open,
  reasons,
  onClose,
  onSubmit,
}: {
  open: boolean;
  reasons: { reason: string }[];
  onClose: () => void;
  onSubmit: (fields) => Promise<void>;
}): JSX.Element => {
  const { t } = useTranslation();
  const { isAllowed } = usePermissions();

  const [reason, setReason] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);
  const [info, setInfo] = useState<null | {
    type: 'success' | 'error';
    message: string;
  }>();

  const block = async (toReason?: string): Promise<void> => {
    try {
      setInfo(null);
      setLoading(true);
      await onSubmit(toReason);
      setReason('');
      onClose();
    } catch (err: any) {
      setInfo({ type: 'error', message: err?.message });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      open={open}
      onClose={() => {
        setReason('');
        onClose();
      }}
    >
      <div className="w-96 min-h-0 flex flex-col overflow-auto">
        <div className="header-m text-left">
          {t('block_workforce_modal_header')}
        </div>
        <div className="text-m text-left mt-2">
          {!isAllowed(Permission.WRITE_HARD_BLOCK_ALL) && (
            <p className="text-sm text-gray-600">
              {t('block_workforce_modal_description')}
            </p>
          )}
        </div>
        <div className="flex flex-col gap-1 my-6 min-h-0 overflow-auto">
          <RadioGroup
            name="block-reasons"
            value={reason}
            onChange={(r) => setReason(r)}
          >
            {reasons.map((r) => (
              <Radio
                key={r.reason}
                id={`block-reason-${r.reason}`}
                value={r.reason}
                disabled={
                  r.reason === 'VISA_EXPIRED' &&
                  !isAllowed(Permission.WRITE_HARD_BLOCK_ALL)
                }
              >
                {t(`block_reason_${r.reason.toLowerCase()}`)}
              </Radio>
            ))}
          </RadioGroup>
        </div>
        {info?.message && <Info type={info?.type}>{t(info?.message)}</Info>}
        <div className="flex gap-2 justify-between">
          <Button variant={ButtonVariant.quinary} onClick={onClose}>
            {t('cancel')}
          </Button>
          <Button
            disabled={!reason || loading}
            loading={loading}
            onClick={() => {
              block(reason);
            }}
          >
            {t('block')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};
