import { useState } from 'react';
import { WorkforceCompliance } from 'models';
import { getCompliance } from 'utils/network/apis';
import { useDeepCompareEffect } from './useDeepCompareEffect';

export type WorkforceComplianceSearchStatus =
  | 'idle'
  | 'loading'
  | 'success'
  | 'error';

export type WorkforceComplianceSearchQuery = {
  max: number;
  skip: number;
  generic?: string;
  selectedHubs?: string[];
  sort?: string;
};

export type WorkforceComplianceSearchResponse = {
  status: WorkforceComplianceSearchStatus;
  error: string | null;
  data: {
    pagination?: {
      total?: number;
      limit?: number;
      // TODO: delete
      max?: number;
    };
    results?: WorkforceCompliance[];
  };
};

const useWorkforceCompliance = (
  query: WorkforceComplianceSearchQuery
): WorkforceComplianceSearchResponse => {
  const [status, setStatus] = useState<WorkforceComplianceSearchStatus>('idle');
  const [data, setData] = useState<{
    pagination?: { total?: number; max?: number; limit?: number };
    results?: WorkforceCompliance[];
  }>({ results: [] });
  const [error, setError] = useState(null);

  useDeepCompareEffect(() => {
    if (!query) {
      return;
    }

    const workforceCompliance = async (): Promise<void> => {
      setStatus('loading');
      setError(null);

      try {
        const { max, skip, generic, selectedHubs, sort } = query;

        const response = await getCompliance({
          max,
          skip,
          limit: max,
          offset: skip,
          is_terminated: false,
          ...(generic ? { generic } : null),
          ...(sort ? { sort } : null),
          ...(selectedHubs ? { hub: selectedHubs } : null),
        });

        setData({
          ...response,
          results: (response.results || []).map(WorkforceCompliance.from),
        });
        setStatus('success');
      } catch (err: any) {
        setStatus('error');
        setError(
          err?.response?.status === 403
            ? 'error_permission_required'
            : err?.message
        );
      }
    };

    workforceCompliance();
  }, [query]);

  return { status, error, data };
};

export default useWorkforceCompliance;
