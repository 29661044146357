import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Employee } from 'models';
import {
  Button,
  ButtonVariant,
  Chip,
  Pagination,
  TableSortBy,
} from '@hubportal/components';
import { Sidebar } from 'components/sidebar-temp';
import { CreateRider } from 'partials/create-rider';
import { AllEmployeesTable } from './all-employees-table';
import { sortKey } from 'utils/helpers';
import usePermissions, { Permission } from 'utils/hooks/usePermissions';
import useStore from 'utils/store';
import useWorkforce, { WorkforceSearchQuery } from 'utils/hooks/useWorkforce';
import { MIN_SEARCH_TERM_LENGTH } from 'utils/constants';

const DEFAULT_PAGE_SIZE = 100;
const DEFAULT_QUERY = {
  max: DEFAULT_PAGE_SIZE,
  skip: 0,
};

export const AllEmployees = ({
  selectedHubs,
  searchTerm,
  setSearchTerm,
}: {
  selectedHubs?: string[];
  searchTerm: string;
  setSearchTerm: (s) => void;
}): JSX.Element => {
  const { t } = useTranslation();
  const { isAllowed } = usePermissions();
  const { country } = useStore();
  const [, setSearchParams] = useSearchParams();

  const [sortBy, setSortBy] = useState<TableSortBy>({ column: '', type: null });
  const [underage, setUnderage] = useState<boolean>();
  const [newRider, setNewRider] = useState<'internal' | 'external' | null>(
    null
  );

  const [employees, setEmployees] = useState<Employee[]>([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: DEFAULT_PAGE_SIZE,
    pageCount: 0,
  });

  const [query, setQuery] = useState<WorkforceSearchQuery>({
    ...DEFAULT_QUERY,
    generic: searchTerm,
    selectedHubs,
    sort: sortKey(sortBy.column, sortBy.type),
  });

  const { status, error, data } = useWorkforce(query);

  useEffect(() => {
    setQuery({
      ...query,
      max: pagination.pageSize,
      skip: pagination.pageSize * pagination.pageIndex,
    });
  }, [pagination.pageIndex, pagination.pageSize]);

  useEffect(() => {
    setQuery({
      ...query,
      selectedHubs,
      isUnderage: underage,
      skip: 0,
    });
    setPagination({ ...pagination, pageIndex: 0 });
  }, [selectedHubs, underage]);

  useEffect(() => {
    if (searchTerm.length === 0 || searchTerm.length > MIN_SEARCH_TERM_LENGTH) {
      setQuery({ ...query, skip: 0, generic: searchTerm });
      setPagination({ ...pagination, pageIndex: 0 });
    }
  }, [searchTerm]);

  useEffect(() => {
    setQuery({
      ...query,
      sort: sortKey(sortBy.column, sortBy.type),
      skip: 0,
    });
    setPagination({ ...pagination, pageIndex: 0 });
  }, [sortBy]);

  useEffect(() => {
    setSearchParams(
      {
        hubs: (selectedHubs || []).join(','),
        search: searchTerm,
        sort: sortKey(sortBy.column, sortBy.type),
      },
      { replace: true }
    );
  }, [selectedHubs, searchTerm, sortBy]);

  useEffect(() => {
    setEmployees(data?.results || []);
    setPagination({
      ...pagination,
      pageCount: Math.ceil(
        (data?.pagination?.total || 1) /
          (data?.pagination?.max || data?.pagination?.limit || 1)
      ),
    });
  }, [data]);

  const isSearchActive =
    searchTerm && searchTerm.length > MIN_SEARCH_TERM_LENGTH;

  return (
    <div className="flex flex-col gap-2">
      {isSearchActive ? (
        <>
          <div className="header-s text-white">
            {status !== 'loading' && employees?.length === 0
              ? t('no_result_for', { key: searchTerm })
              : t('search_result_for', { key: searchTerm })}
          </div>
          <div
            onClick={() => setSearchTerm('')}
            className="text-xs text-pink underline cursor-pointer"
          >
            {t('clear')}
          </div>
        </>
      ) : (
        <div className="header-s text-white">{t('all_employees')}</div>
      )}
      <div className="my-2 flex justify-between">
        <div>
          {country === 'NL' && (
            <Chip selected={underage} onClick={() => setUnderage(!underage)}>
              {t('show_underage_riders')}
            </Chip>
          )}
        </div>
        <div className="flex gap-x-2 items-start">
          {isAllowed(Permission.CREATE_RIDER_EXTERNAL) && !isSearchActive && (
            <Button
              variant={ButtonVariant.quaternary}
              onClick={() => setNewRider('external')}
            >
              {t('create_external_rider')}
            </Button>
          )}
          {isAllowed(Permission.CREATE_RIDER_INTERNAL) && !isSearchActive && (
            <Button
              variant={ButtonVariant.quaternary}
              onClick={() => setNewRider('internal')}
            >
              {t('import_rider')}
            </Button>
          )}
        </div>
      </div>

      {(!isSearchActive ||
        !(
          isSearchActive &&
          status === 'success' &&
          employees?.length === 0
        )) && (
        <>
          <AllEmployeesTable
            data={employees}
            loading={status === 'loading'}
            sortBy={sortBy}
            onSortChange={(sb) => setSortBy(sb)}
          />
          {status === 'success' &&
            (data?.pagination?.total || 0) > pagination.pageSize && (
              <div className="py-2">
                <Pagination
                  goToPage={(pi) => {
                    setPagination({ ...pagination, pageIndex: pi });
                  }}
                  pageCount={pagination.pageCount}
                  pageIndex={pagination.pageIndex}
                  pageSize={pagination.pageSize}
                  setPageSize={(ps) => {
                    setPagination({ ...pagination, pageSize: ps });
                  }}
                  totalCount={data?.pagination?.total || 0}
                  labels={{ show: t('show') }}
                />
              </div>
            )}
        </>
      )}

      {/* error state */}
      {error && (
        <div className="text-center p-4">
          <div className="text-s">{error}</div>
        </div>
      )}

      <Sidebar open={!!newRider} onClose={() => setNewRider(null)}>
        <CreateRider type={newRider} onSuccess={() => setQuery({ ...query })} />
      </Sidebar>
    </div>
  );
};
