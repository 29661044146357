import { useState } from 'react';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { Button, Input, InputVariant } from '@hubportal/components';
import { Info } from 'components/info';
import { EC_ID_REGEX, getErrorMessage } from 'utils/helpers';
import { getInternalRider } from 'utils/network/apis';
import { Confirmation } from './confirmation';
import { Account } from 'models';
import * as Yup from 'yup';

interface CreateInternalRiderProps {
  onSuccess(): void;
}

const initialValues = {
  ecId: '',
};

const getValidationSchema = (t): object =>
  Yup.object({
    ecId: Yup.string()
      .matches(RegExp(EC_ID_REGEX), t('invalid_ec_id'))
      .required(t('required')),
  });

const CreateInternalRider = ({
  onSuccess,
}: CreateInternalRiderProps): JSX.Element => {
  const { t } = useTranslation();
  const [modal, setModal] = useState<'createRider' | null>();
  const [accounts, setAccounts] = useState<Account[]>([]);
  const [state, setState] = useState<null | {
    type: 'success' | 'error' | 'loading';
    message?: string;
  }>();

  const getRiderInfo = async (ecId): Promise<void> => {
    try {
      setState({ type: 'loading' });
      const response = await getInternalRider(ecId);

      setAccounts(response?.employees?.map(Account.from));
      setModal('createRider');
      setState(null);
    } catch (err: any) {
      setState({ type: 'error', message: getErrorMessage(t, err) });
    }
  };

  const formik = useFormik({
    initialValues: {
      ...initialValues,
    },
    validateOnMount: true,
    validationSchema: getValidationSchema(t),
    onSubmit: async () => {
      formik.setSubmitting(true);
      await getRiderInfo(formik.values.ecId);
      formik.setSubmitting(false);
    },
  });

  return (
    <>
      <form
        onSubmit={formik.handleSubmit}
        autoComplete="off"
        className="flex flex-col grow gap-4"
      >
        <Input
          required
          label={t('ec_id')}
          placeholder="00112233"
          variant={InputVariant.secondary}
          error={formik.touched.ecId ? formik.errors.ecId : ''}
          {...formik.getFieldProps('ecId')}
        />

        <div className="mt-auto">
          {(state?.type === 'error' || state?.type === 'success') &&
            state?.message && <Info type={state?.type}>{state?.message}</Info>}
          <Button
            onClick={() => {
              formik.submitForm();
            }}
            disabled={
              !formik.isValid ||
              formik.isSubmitting ||
              state?.type === 'loading'
            }
            loading={formik.isSubmitting || state?.type === 'loading'}
          >
            {t('import_rider')}
          </Button>
        </div>
      </form>
      <Confirmation
        ecID={formik.values.ecId}
        open={modal === 'createRider'}
        accounts={accounts || []}
        onSuccess={() => {
          setModal(null);
          setState({ type: 'success', message: t('rider_account_imported') });
        }}
        onClose={() => setModal(null)}
      />
    </>
  );
};

export { CreateInternalRider };
