import { useMemo } from 'react';
import { Account } from 'models/account';
import { MultipleAccountView } from './multiple-account-view';
import { SingleAccountView } from './single-account-view';

const Confirmation = ({
  ecID,
  open,
  accounts: allAccounts,
  onSuccess,
  onClose,
}: {
  ecID: string;
  open: boolean;
  accounts: Account[];
  onClose: () => void;
  onSuccess: () => void;
}): JSX.Element => {
  const existingAccounts = useMemo(() => {
    return allAccounts.filter((a) => a.source === 'PROFILE');
  }, [allAccounts]);

  const importedAccount = useMemo(() => {
    return allAccounts.filter((a) => a.source !== 'PROFILE')[0];
  }, [allAccounts]);

  const multipleAccountFound = existingAccounts?.length > 0;

  return multipleAccountFound ? (
    <MultipleAccountView
      open={open}
      ecID={ecID}
      source={importedAccount.source}
      account={importedAccount}
      existingAccounts={existingAccounts}
      onSuccess={onSuccess}
      onClose={onClose}
    />
  ) : (
    <SingleAccountView
      open={open}
      ecID={ecID}
      account={importedAccount}
      onClose={onClose}
      onSuccess={onSuccess}
    />
  );
};

export { Confirmation };
