interface Schedule {
  from: string;
  to: string;
}
export class NoShow {
  id: string;
  type: 'EXCUSED' | 'UNEXCUSED' | 'PENDING';
  at: string;
  schedules: Schedule[];
  reminderDeliveredAt?: string;
  warningDeliveredAt?: string;

  constructor(details: any = {}) {
    this.id = details.id;
    this.type = details.type;
    this.at = details.date;
    this.schedules = details.schedules;
    this.reminderDeliveredAt = details.reminder_delivered_at;
    this.warningDeliveredAt = details.warning_delivered_at;
  }

  public static from(details: any): NoShow {
    return new NoShow(details);
  }
}
