import { Button, Icon } from '@hubportal/components';
import { Spinner } from 'components/spinner';
import { format, isToday } from 'date-fns';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getHubPerformance } from 'utils/network/apis';
import { track } from 'utils/tracking';

export enum Metrics {
  NO_SHOWS = 'NO_SHOWS',
  LATE_SHIFTS = 'LATE_SHIFTS',
  ACCEPTANCE_RATE = 'ACCEPTANCE_RATE',
  UTR = 'UTR',
  AVERAGE_PICKING_TIME = 'AVERAGE_PICKING_TIME',
  POST_ORDER_ISSUE_RATE = 'POST_ORDER_ISSUE_RATE',
}
interface MetricProps {
  selected?: boolean;
  inactive?: boolean;
  onClick?: () => void;
  children: JSX.Element;
}

interface MetricContentProps {
  value: string;
  label: string;
  description?: string;
  indicator?: 'up' | 'down' | null;
  indicatorType?: 'red' | 'green' | null;
  indicatorText?: string;
}

interface HubPerformanceProps {
  selectedHub: string;
  selectedMetric: Metrics | null;
  setSelectedMetric: (m) => void;
  selectedPeriod: { cw: number; from: Date; to: Date; isCurrentWeek?: boolean };
  setUpdatedAt: (u) => void;
}

const initialData = {
  noShows: {
    count: 0,
    total: 0,
    rate: '0',
  },
  lateShifts: {
    count: 0,
    total: 0,
    rate: '0',
  },
  acceptanceRate: {
    value: '0',
    target: 0,
  },
  utr: {
    value: '0',
    target: 0,
  },
  averagePickingTime: {
    value: '0',
    target: 0,
  },
  postOrderIssueRate: {
    value: '0',
    target: 0,
  },
};

const Metric = ({
  selected,
  inactive,
  onClick,
  children,
}: MetricProps): JSX.Element => {
  return (
    <div
      onClick={onClick}
      className={`rounded-lg bg-primary p-4 flex flex-col gap-1 min-w-[11rem] max-w-[12rem] border-4 transition ${
        selected
          ? 'border-pink'
          : 'border-transparent hover:bg-black hover:shadow-lg'
      } ${inactive ? 'opacity-50 hover:opacity-100' : ''} ${
        onClick ? 'cursor-pointer' : ''
      }`}
    >
      {children}
    </div>
  );
};

const MetricContent = ({
  value,
  label,
  description,
  indicator,
  indicatorText,
  indicatorType,
}: MetricContentProps): JSX.Element => {
  const indicatorClass =
    indicatorText === 'green'
      ? 'text-green'
      : indicatorType === 'red'
      ? 'text-red'
      : '';
  return (
    <>
      <div className="flex gap-1 items-center">
        <div className="header-s text-white">{value}</div>
        <span className={`${indicatorClass}`}>
          {indicator === 'up' ? (
            <Icon type="arrowSignUp" size="small" />
          ) : indicator === 'down' ? (
            <Icon type="arrowSignDown" size="small" />
          ) : (
            ''
          )}
        </span>
        <span className={`detail-m ${indicatorClass}`}>{indicatorText}</span>
      </div>
      <div className="text-s text-white">{label}</div>
      <div className="detail-l">{description}</div>
    </>
  );
};

export const HubPerformance = ({
  selectedHub,
  selectedMetric,
  setSelectedMetric,
  selectedPeriod,
  setUpdatedAt,
}: HubPerformanceProps): JSX.Element => {
  const { t } = useTranslation();
  const [status, setStatus] = useState({ loading: true, error: '' });
  const [data, setData] = useState(initialData);

  const getMetrics = async (): Promise<void> => {
    try {
      setStatus({ loading: true, error: '' });
      const response = await getHubPerformance(selectedHub, {
        from_date: format(new Date(selectedPeriod.from), 'yyyy-MM-dd'),
        to_date: format(selectedPeriod.to, 'yyyy-MM-dd'),
      });
      setData({
        noShows: {
          count: response?.total_no_shows,
          total: response?.total_shifts,
          rate:
            response?.total_shifts === 0
              ? '0'
              : (
                  (100 * response?.total_no_shows || 0) / response?.total_shifts
                ).toFixed(0),
        },
        lateShifts: {
          count: response?.total_late_shows,
          total: response?.total_shifts,
          rate:
            response?.total_shifts === 0
              ? '0'
              : (
                  (100 * response?.total_late_shows || 0) /
                  response?.total_shifts
                ).toFixed(0),
        },
        acceptanceRate: {
          value: response?.acceptance_rate?.value,
          target: response?.acceptance_rate?.target,
        },
        utr: {
          value: response?.utr?.value,
          target: response?.utr?.target,
        },
        averagePickingTime: {
          value: response?.average_picking_time?.value,
          target: response?.average_picking_time?.target,
        },
        postOrderIssueRate: {
          value: response?.post_order_issue_rate?.value,
          target: response?.post_order_issue_rate?.target,
        },
      });
      setUpdatedAt(response?.updated_at);
      setStatus({ loading: false, error: '' });
    } catch (err: any) {
      if (err?.response?.status === 404) {
        setData(initialData);
        setStatus({ loading: false, error: '' });
      } else {
        setStatus({ loading: false, error: err?.message });
        throw err;
      }
    }
  };

  const onClickMetric = (metric: Metrics, value: string): void => {
    track('click', {
      component_name: 'metric_tile',
      component_content: metric.toLowerCase(),
      component_value: `${value}`,
      screen_name: 'workforce_dashboard',
    });

    setSelectedMetric(metric);
  };

  useEffect(() => {
    getMetrics();
  }, [selectedHub, selectedPeriod]);

  return (
    <div className="flex flex-col items-start gap-2">
      <div className="header-s text-white">{t('hub_performance')}</div>

      {/* error state */}
      {status.error && (
        <>
          <div className="text-s">{t('hub_performance_failed')}</div>
          <Button
            onClick={() => {
              getMetrics();
            }}
          >
            {t('try_again')}
          </Button>
        </>
      )}

      {/* loading state */}
      {status.loading && (
        <Metric inactive>
          <Spinner />
        </Metric>
      )}

      {/* content */}
      {!status.loading && !status.error && (
        <>
          <div className="text-s">
            {`${t('hub_performance_description')} ${
              isToday(selectedPeriod.from)
                ? t('showing_data_from_today')
                : t('showing_data_from', {
                    from: format(selectedPeriod.from || 0, 'MMM dd'),
                    to: selectedPeriod.isCurrentWeek
                      ? t('today')
                      : format(selectedPeriod.to || 0, 'MMM dd'),
                  })
            }`}
          </div>
          <div className="flex gap-4 my-2">
            <Metric
              selected={selectedMetric === Metrics.NO_SHOWS}
              inactive={
                selectedMetric !== null && selectedMetric !== Metrics.NO_SHOWS
              }
              onClick={() => onClickMetric(Metrics.NO_SHOWS, data.noShows.rate)}
            >
              <MetricContent
                value={`${data.noShows.rate}%`}
                label={t('no_shows')}
                description={t('hub_performance_metric_no_shows_label', {
                  noShowCount: data.noShows.count,
                  total: data.noShows.total,
                })}
              />
            </Metric>
            <Metric
              selected={selectedMetric === Metrics.LATE_SHIFTS}
              inactive={
                selectedMetric !== null &&
                selectedMetric !== Metrics.LATE_SHIFTS
              }
              onClick={() =>
                onClickMetric(Metrics.LATE_SHIFTS, data.lateShifts.rate)
              }
            >
              <MetricContent
                value={`${data.lateShifts.rate}%`}
                label={t('late_shifts')}
                description={t('hub_performance_metric_no_shows_label', {
                  noShowCount: data.lateShifts.count,
                  total: data.lateShifts.total,
                })}
              />
            </Metric>
            <Metric
              selected={selectedMetric === Metrics.ACCEPTANCE_RATE}
              inactive={
                selectedMetric !== null &&
                selectedMetric !== Metrics.ACCEPTANCE_RATE
              }
              onClick={() =>
                onClickMetric(
                  Metrics.ACCEPTANCE_RATE,
                  data.acceptanceRate.value
                )
              }
            >
              <MetricContent
                value={`${data.acceptanceRate.value}%`}
                label={t('acceptance_rate')}
                // description={`${t('target')} > ${data.acceptanceRate.target}%`}
              />
            </Metric>
            <Metric
              selected={selectedMetric === Metrics.UTR}
              inactive={
                selectedMetric !== null && selectedMetric !== Metrics.UTR
              }
              onClick={() => onClickMetric(Metrics.UTR, data.utr.value)}
            >
              <MetricContent
                value={`${data.utr.value}`}
                label={`${t('utr')} (UTR)`}
                // description={`${t('target')} > ${data.utr.target}`}
              />
            </Metric>
            <Metric
              selected={selectedMetric === Metrics.AVERAGE_PICKING_TIME}
              inactive={
                selectedMetric !== null &&
                selectedMetric !== Metrics.AVERAGE_PICKING_TIME
              }
              onClick={() =>
                onClickMetric(
                  Metrics.AVERAGE_PICKING_TIME,
                  data.averagePickingTime.value
                )
              }
            >
              <MetricContent
                value={`${data.averagePickingTime.value}`}
                label={`${t('average_picking_time')}`}
                // description={`${t('target')} < ${
                //   data.averagePickingTime.target
                // }`}
              />
            </Metric>
            <Metric
              selected={selectedMetric === Metrics.POST_ORDER_ISSUE_RATE}
              inactive={
                selectedMetric !== null &&
                selectedMetric !== Metrics.POST_ORDER_ISSUE_RATE
              }
              onClick={() =>
                onClickMetric(
                  Metrics.POST_ORDER_ISSUE_RATE,
                  data.postOrderIssueRate.value
                )
              }
            >
              <MetricContent
                value={`${data.postOrderIssueRate.value}%`}
                label={`${t('post_order_issue_rate')}`}
                // description={`${t('target')} < ${
                //   data.postOrderIssueRate.target
                // }%`}
              />
            </Metric>
          </div>
          {selectedMetric && (
            <div
              onClick={() => setSelectedMetric(null)}
              className="text-xs text-pink underline cursor-pointer"
            >
              {t('clear_filter')}
            </div>
          )}
        </>
      )}
    </div>
  );
};
