import { differenceInDays, format } from 'date-fns';
import { ComplianceCaseStatus, ComplianceCaseType } from 'utils/constants';
import {
  getCaseCopy,
  getCommentsCopy,
  getInactivityCopy,
  getNoShowsCopy,
  getWarningsCopy,
} from 'utils/helpers';
import { Employee } from './employee';
import { NoShow } from './no-show';

export class ComplianceCase {
  id: string;
  type: ComplianceCaseType;
  status: ComplianceCaseStatus;
  noShows: NoShow[];
  lastActivityAt?: string;
  daysInactive: number;
  sicknessRate?: any;

  constructor(details: any = {}) {
    const daysInactive = details?.inactivity?.last_activity_at
      ? differenceInDays(
          new Date(),
          new Date(details?.inactivity?.last_activity_at || 0)
        )
      : 0;

    this.id = details.id;
    this.type = details?.type;
    this.status =
      details?.no_shows?.length > 0 || daysInactive > 0
        ? details.status
        : ComplianceCaseStatus.EMPTY;

    this.noShows = (details?.no_shows || [])
      .map(NoShow.from)
      .filter((ns) => ns.type === 'UNEXCUSED' || ns.type === 'EXCUSED');

    this.lastActivityAt = details?.inactivity?.last_activity_at;
    this.daysInactive = daysInactive;
    this.sicknessRate = details.sickness_rate;
  }

  public static from(details: any): ComplianceCase {
    return new ComplianceCase(details);
  }

  public getCopy(
    employee: Employee,
    warnings: Warning[],
    comments: Comment[],
    t: any
  ): string {
    const unexcused = this.noShows?.filter((ns) => ns.type === 'UNEXCUSED');

    return getCaseCopy({
      ecId: employee.ecID,
      startDate: format(new Date(employee?.hireDate || 0), 'dd/MM/yy'),
      probationStatus: employee?.probationEndDate
        ? employee.isProbationPassed()
          ? t('probation_passed')
          : t('probation_ongoing')
        : '-',
      noShowsCount: unexcused?.length,
      noShows: getNoShowsCopy(unexcused, t),
      comments: getCommentsCopy(comments),
      warnings: getWarningsCopy(warnings, t),
      inactivity: getInactivityCopy(this.daysInactive, this.lastActivityAt, t),
    });
  }
}
