import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@hubportal/components';
import { NoShow } from 'models/no-show';
import { ExcuseNoShowsModal } from './modal';

interface ExcuseNoShowsProps {
  list: NoShow[];
  onSubmit: (nsIDs: string) => Promise<any>;
}

const ExcuseNoShows = ({ list, onSubmit }: ExcuseNoShowsProps): JSX.Element => {
  const { t } = useTranslation();

  const [modal, setModal] = useState<boolean>(false);

  return (
    <>
      <div>
        <Button disabled={modal} loading={modal} onClick={() => setModal(true)}>
          {t('excuse_no_shows')}
        </Button>
      </div>
      <ExcuseNoShowsModal
        list={list}
        open={modal}
        onClose={() => {
          setModal(false);
        }}
        onSubmit={onSubmit}
      />
    </>
  );
};

export { ExcuseNoShows };
