import { useRef, useState } from 'react';
import { Icon, Tooltip, TooltipVariant } from '@hubportal/components';

export const TooltipHeader = ({
  label,
  info,
  fontBold,
}: {
  label: string;
  info: string;
  fontBold?: boolean;
}): JSX.Element => {
  const ref = useRef(null);
  const [tooltipVisible, setTooltipVisible] = useState(false);

  return (
    <div className={`flex items-center gap-1 ${fontBold ? 'font-bold' : ''}`}>
      {label}
      <div
        ref={ref}
        onMouseOver={() => setTooltipVisible(true)}
        onMouseLeave={() => setTooltipVisible(false)}
        className="text-gray-300"
      >
        <Icon type="infoCircle" size="small" />
      </div>
      <Tooltip
        refElement={ref?.current || null}
        visible={tooltipVisible}
        variant={TooltipVariant.pink}
        placement="top"
      >
        <div className="max-w-[200px]">{info}</div>
      </Tooltip>
    </div>
  );
};
