import { matchRoutes, useLocation } from 'react-router-dom';

const routes = [
  { id: 'workforce', path: '/workforce' },
  { id: 'performance', path: '/workforce/performance' },
  { id: 'account_settings', path: '/workforce/:id' },
  { id: 'compliance', path: '/workforce/:id/compliance' },
  { id: 'performance', path: '/workforce/:id/performance' },
  { id: 'feedback', path: '/workforce/:id/feedback' },
  { id: 'punch_images', path: '/workforce/punch/image/:id' },
];

export const useCurrentPath = (): { id: string; path: string } => {
  const location = useLocation();
  const [{ route }] = matchRoutes(routes, location) || [];

  return route;
};
