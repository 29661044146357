import { Sendout } from 'models/sendout';
import { Warning } from 'partials/warning';
import { useTranslation } from 'react-i18next';

export const Notes = ({
  warnings = [],
}: {
  warnings?: Sendout[];
}): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col gap-2">
      <div className="text-white header-s">{t('warnings_sent')}</div>
      <div className="flex flex-col rounded-xl bg-primary text-gray-300 p-8  min-h-[14rem]">
        <>
          {warnings?.length === 0 && (
            <div className="grow flex items-center justify-center text-s">
              {t('warnings_history_empty')}
            </div>
          )}
          {warnings?.length > 0 && (
            <div className="grid grid-cols-[max-content,1fr] gap-x-16 gap-y-2 text-s max-h-96 overflow-auto">
              {warnings?.map((w, idx) => (
                <Warning
                  key={idx}
                  item={w}
                  border={idx !== warnings?.length - 1}
                />
              ))}
            </div>
          )}
        </>
      </div>
    </div>
  );
};
