import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { Button } from '@hubportal/components';
import { updateAgency } from 'utils/network/apis';
import { AgencySelect } from 'partials/select';
import omit from 'lodash.omit';
import * as Yup from 'yup';

interface ChangeAgencyProps {
  id: string;
  agency: string;
}

const ChangeExternalAgency = ({
  id,
  agency: agencyName,
}: ChangeAgencyProps): JSX.Element => {
  const { t } = useTranslation();

  const formik = useFormik({
    initialValues: { agency: { id: '', label: '' } },
    validationSchema: Yup.object({
      agency: Yup.object({
        id: Yup.string().required(t('required')),
        label: Yup.string(),
      }).required(),
    }),
    onSubmit: async () => {
      const agency = formik.values.agency.id?.toLowerCase();
      try {
        await updateAgency(id, { name: agency });
        formik.resetForm({
          values: { agency: { id: agency, label: agency } },
        });
      } catch (err) {
        // TODO: err
      }
    },
  });

  useEffect(() => {
    formik.resetForm({
      values: { agency: { id: agencyName, label: agencyName } },
    });
  }, [agencyName]);

  return (
    <form
      onSubmit={formik.handleSubmit}
      autoComplete="off"
      className="flex items-center gap-2"
    >
      <div className="grow flex flex-col">
        <AgencySelect
          error={formik.touched?.agency ? formik.errors?.agency?.id : ''}
          onChange={(value) =>
            formik.handleChange({
              target: { name: 'agency', value },
            })
          }
          {...omit(formik.getFieldProps('agency'), 'onChange')}
        />
      </div>
      <Button
        disabled={!formik.dirty || !formik.isValid || formik.isSubmitting}
        loading={formik.isSubmitting}
        onClick={() => {
          formik.submitForm();
        }}
      >
        {t('save')}
      </Button>
    </form>
  );
};

export { ChangeExternalAgency };
