import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { Employee } from 'models';
import { isPunchOnlineEnabled } from 'utils/eppo/helpers';
import { PHONE_NUMBER_REGEX } from 'utils/helpers';
import { RiderStates } from 'utils/constants';
import usePermissions, { Permission } from 'utils/hooks/usePermissions';
import { ChangeExternalAgency } from './changeExternalAgency';
import { ChangeHub } from './changeHub';
import { EditableProfileItem } from './editableProfileItem';
import { ProfileItem } from './profileItem';
import * as Yup from 'yup';

export const Profile = ({
  employee,
}: {
  employee: Employee | null;
}): JSX.Element => {
  const { t } = useTranslation();
  const { isAllowed } = usePermissions();

  const isHubChangeEnabled = useMemo(
    () => employee?.workforceID && (!employee?.ecID || !isPunchOnlineEnabled()),
    [employee]
  );

  const changeEmail = async (email): Promise<void> => {
    await employee?.changeEmail(email);
  };

  const changePhoneNumber = async (phone): Promise<void> => {
    await employee?.changePhoneNumber(phone);
  };

  return (
    <div className="flex flex-col gap-6 mt-8 max-w-sm">
      <ProfileItem
        label={t('status')}
        value={employee?.status ? employee?.getState() : '-'}
      />
      <ProfileItem
        copyable
        label={t('riderId')}
        value={employee?.workforceID}
      />
      <ProfileItem
        copyable
        label={t('employee_number')}
        value={employee?.ecID}
      />
      <ProfileItem label={t('position')} value={employee?.jobTitle} />
      <ProfileItem
        label={t('start_date')}
        value={
          employee?.hireDate
            ? format(new Date(employee.hireDate), 'dd.MM.yyyy')
            : '-'
        }
      />

      {isAllowed(Permission.WRITE_EMAIL_ALL) ? (
        <EditableProfileItem
          type="email"
          field="email"
          validationSchema={Yup.object({
            email: Yup.string()
              .email(t('invalid_email'))
              .required(t('required')),
          })}
          label={t('email')}
          value={employee?.email}
          onChange={(email) => changeEmail(email)}
        />
      ) : (
        <ProfileItem label={t('email')} value={employee?.email} />
      )}

      {isAllowed(Permission.WRITE_PHONENUMBER_ALL) ? (
        <EditableProfileItem
          type="tel"
          field="phone"
          validationSchema={Yup.object({
            phone: Yup.string()
              .matches(RegExp(PHONE_NUMBER_REGEX), t('invalid_phone_number'))
              .required(t('required')),
          })}
          label={t('phone_number')}
          value={employee?.phoneNumber}
          onChange={(phone) => changePhoneNumber(phone)}
        />
      ) : (
        <ProfileItem label={t('phone_number')} value={employee?.phoneNumber} />
      )}

      {isHubChangeEnabled ? (
        <ChangeHub
          id={employee?.workforceID || ''}
          hub={employee?.hubSlug || ''}
          disabled={employee?.status === RiderStates.BUSY}
        />
      ) : (
        <ProfileItem label={t('hub')} value={employee?.hubSlug || ''} />
      )}

      {employee?.homeHub && (
        <ProfileItem label={t('home_hub')} value={employee?.homeHub} />
      )}

      {employee?.externalAgency &&
        (isAllowed(Permission.WRITE_RIDERPROFILE_ALL) ? (
          <ChangeExternalAgency
            id={employee?.workforceID || ''}
            agency={employee?.externalAgency || ''}
          />
        ) : (
          <ProfileItem
            label={`${t('external_agency')}:`}
            value={employee?.externalAgency}
          />
        ))}

      {employee?.hubSlug?.startsWith('nl') && employee.isUnderage() && (
        <ProfileItem
          label={`${t('age_restriction')}:`}
          value={t('rider_underage_warning')}
        />
      )}
    </div>
  );
};
