import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@hubportal/components';
import { GenerateOtp } from 'partials/generate-otp';
import { Employee } from 'models/employee';
import usePermissions, { Permission } from 'utils/hooks/usePermissions';
import { Info } from 'components/info';

const PasswordSettings = ({
  employee,
}: {
  employee: Employee | null;
}): JSX.Element => {
  const { t } = useTranslation();
  const { isAllowed } = usePermissions();

  const [loading, setLoading] = useState(false);
  const [info, setInfo] = useState<null | {
    type: 'success' | 'error';
    message: string;
  }>();

  const reset = async (): Promise<void> => {
    try {
      setLoading(true);
      setInfo(null);
      await employee?.resetPassword();
      setInfo({ type: 'success', message: 'reset_password_success' });

      setTimeout(() => {
        setInfo(null);
      }, 2000);
    } catch (err) {
      setInfo({ type: 'error', message: 'generic_error' });
      throw err;
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      {isAllowed(Permission.WRITE_PASSWORD_ALL) && (
        <>
          <div className="text-white header-s mb-6">
            {t('password_settings')}
          </div>
          <div className="flex gap-4">
            <div>
              <Button
                disabled={loading}
                loading={loading}
                onClick={() => {
                  reset();
                }}
              >
                {t('reset_password')}
              </Button>
            </div>
            <GenerateOtp auth0Id={employee?.workforceID || ''} />
          </div>
          {info?.message && (
            <Info type={info?.type} variant="secondary">
              {t(info?.message)}
            </Info>
          )}
        </>
      )}
    </div>
  );
};

export { PasswordSettings };
