import { useTranslation } from 'react-i18next';
import {
  Multiselect,
  MultiselectItem,
  MultiselectProps,
} from '@hubportal/components';

const BlockedMultiselect = ({
  values,
  onChange,
  ...props
}: MultiselectProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Multiselect
      labels={{
        default: t('blocked'),
        search: '',
      }}
      values={values}
      onChange={onChange}
      {...props}
    >
      {[
        { label: 'yes', value: 'true' },
        { label: 'no', value: 'false' },
      ].map((status) => {
        const label = t(status.label);
        return (
          <MultiselectItem
            key={status.value}
            value={status.value}
            label={label}
          >
            {label}
          </MultiselectItem>
        );
      })}
    </Multiselect>
  );
};

export { BlockedMultiselect };
