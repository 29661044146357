import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Dropdown } from '@hubportal/components';
import { Employee } from 'models/employee';
import { ConfirmationModal } from 'partials/confirmation-modal';
import { Info } from 'components/info';
import { RiderStates } from 'utils/constants';
import { TempOfflineModal } from 'partials/set-temp-offline/modal';
import { PunchInOverrideModal } from 'partials/punch-in-override/modal';
import {
  createPunchInOverride,
  syncWorkforceProfile,
} from 'utils/network/apis';
import { isPunchOnlineEnabled } from 'utils/eppo/helpers';
import { useCurrentPath } from 'utils/hooks/useCurrentPath';
import usePermissions, { Permission } from 'utils/hooks/usePermissions';
import { track } from 'utils/tracking';
import { SyncWorkforceProfileModal } from 'partials/sync-profile/modal';
import { delay } from 'utils/helpers';
import { UnblockModal } from 'partials/block/unblock-modal';
import { BlockOptionsModal } from 'partials/block/block-modal';

type WorkforceProfileActionType =
  | 'set_offline'
  | 'set_online'
  | 'set_temp_offline'
  | 'create_punch_in_override'
  | 'sync_profile'
  | 'block'
  | 'block_options'
  | 'unblock'
  | null;

type WorkforceProfileAction = {
  type?: WorkforceProfileActionType;
  modalTitle?: string;
  loading?: WorkforceProfileActionType;
  func?: () => Promise<void>;
};

const Actions = ({
  employee,
  activePunchInOverride,
  getActivePunchInOverride,
  onUpdate,
}: {
  employee: Employee | null;
  activePunchInOverride;
  getActivePunchInOverride;
  onUpdate: () => Promise<void>;
}): JSX.Element => {
  const currentPath = useCurrentPath();
  const { t } = useTranslation();
  const { isAllowed } = usePermissions();

  const [action, setAction] = useState<WorkforceProfileAction>({});
  const [info, setInfo] = useState<null | {
    type: 'success' | 'error';
    message: string;
  }>();

  const handler = async (func): Promise<void> => {
    try {
      setAction({ ...action, loading: action?.type });
      await func();
    } catch (err: any) {
      setInfo({
        type: 'error',
        message: err?.response?.data?.error || err?.message,
      });
      throw err;
    } finally {
      setAction({ ...action, loading: null });
    }
  };

  const block = (): Promise<void> => {
    return handler(async () => {
      await employee?.block();
    });
  };

  const unblock = (): Promise<void> => {
    return handler(async () => {
      const hasBoth =
        employee?.blockingReasons?.findIndex(
          (r) => r.reason === 'OTHER_AND_IP_BLOCKED'
        ) !== -1;

      const isIpBlocked =
        employee?.blockingReasons?.findIndex(
          (r) => r.reason === 'IP_BLOCKED'
        ) !== -1;

      await employee?.unblock(
        hasBoth ? 'OTHER_AND_IP_BLOCKED' : isIpBlocked ? 'IP_BLOCKED' : 'OTHER'
      );
    });
  };

  const setOffline = (): Promise<void> =>
    handler(async () => await employee?.setOffline());

  const setOnline = (): Promise<void> =>
    handler(async () => await employee?.setOnline());

  const createOverride = async ({ reason, duration }): Promise<void> => {
    await createPunchInOverride({
      ec_id: employee?.ecID,
      reason,
      time_window_in_minutes: duration,
    });
    await getActivePunchInOverride();
    setAction({});
  };

  const syncProfile = async (): Promise<void> => {
    await syncWorkforceProfile(employee?.workforceID);
    await delay(500);
    await onUpdate();
  };

  const sendEvent = (actionKey: string): void => {
    track('click', {
      component_content: actionKey?.toLowerCase(),
      component_name: 'actions',
      screen_name: currentPath?.id,
      component_value: `${employee?.ecID}` || '',
    });
  };

  return (
    <div className="flex gap-4">
      {info?.message && (
        <Info type={info?.type} variant="secondary">
          {t(info?.message)}
        </Info>
      )}
      <Dropdown label={t('actions')}>
        <div className="flex flex-col gap-2 items-end">
          {/* Set online  */}
          {employee?.isSetOnlineEnabled() &&
            isAllowed(Permission.WRITE_TEMP_OFFLINE_ALL) && (
              <div
                className={`title-m text-white cursor-pointer ${
                  action.loading
                    ? 'pointer-events-none opacity-50'
                    : 'cursor-pointer'
                }`}
                onClick={() => {
                  sendEvent('set_online');
                  setAction({
                    type: 'set_online',
                    modalTitle: 'set_rider_online_confirmation',
                    func: setOnline,
                  });
                }}
              >
                {t('set_rider_online')}
              </div>
            )}

          {/* Display temp offline button for punch online hm/sl and tech-ops */}
          {employee?.ecID &&
            employee?.isSetTempOfflineEnabled() &&
            (isAllowed(Permission.WRITE_OFFLINE_ALL) ||
              (isAllowed(Permission.WRITE_TEMP_OFFLINE_ALL) &&
                isPunchOnlineEnabled())) && (
              <div
                className={`title-m text-white cursor-pointer ${
                  action.loading
                    ? 'pointer-events-none opacity-50'
                    : 'cursor-pointer'
                }`}
                onClick={() => {
                  sendEvent('set_temp_online');
                  setAction({
                    type: 'set_temp_offline',
                  });
                }}
              >
                {t('set_rider_temp_offline')}
              </div>
            )}

          {/* Display offline button for externals and non-punch online hm/sl and tech-ops */}
          {(!employee?.ecID ||
            isAllowed(Permission.WRITE_OFFLINE_ALL) ||
            (isAllowed(Permission.WRITE_RIDERPROFILE_ALL) &&
              !isPunchOnlineEnabled())) && (
            <div
              className={`title-m text-white ${
                !!action.loading || employee?.status === RiderStates.OFFLINE
                  ? 'pointer-events-none opacity-50'
                  : 'cursor-pointer'
              }`}
              onClick={() => {
                sendEvent('set_offline');
                setAction({
                  type: 'set_offline',
                  modalTitle: 'set_rider_offline_confirmation',
                  func: setOffline,
                });
              }}
            >
              {t('set_rider_offline')}
            </div>
          )}

          {/* Punch override */}
          {employee?.ecID && isAllowed(Permission.WRITE_PUNCH_OVERRIDE_ALL) && (
            <div
              className={`title-m text-white cursor-pointer ${
                !!action.loading || !!activePunchInOverride
                  ? 'pointer-events-none opacity-50'
                  : ''
              }`}
              onClick={() => {
                sendEvent('allow_early_punch_in');
                setAction({
                  type: 'create_punch_in_override',
                });
              }}
            >
              {t('create_punch_in_override')}
            </div>
          )}

          {/* Profile sync */}
          {employee?.ecID &&
            !employee.isShortTerm &&
            isAllowed(Permission.CREATE_RIDER_INTERNAL) && (
              <div
                className={`title-m text-white cursor-pointer ${
                  !!action.loading || !!activePunchInOverride
                    ? 'pointer-events-none opacity-50'
                    : ''
                }`}
                onClick={() => {
                  sendEvent('sync_profile');
                  setAction({
                    type: 'sync_profile',
                  });
                }}
              >
                {t('sync_profile')}
              </div>
            )}

          {/* Block  */}
          {(((!employee?.isBlocked ||
            employee?.blockingReasons?.filter((r) => {
              return ['OTHER', 'OTHER_AND_IP_BLOCKED'].includes(r.reason);
            }).length === 0) &&
            isAllowed(Permission.WRITE_BLOCK_ALL)) ||
            (employee?.blockingReasons?.filter((r) => {
              return ['VISA_EXPIRED'].includes(r.reason);
            }).length === 0 &&
              isAllowed(Permission.WRITE_HARD_BLOCK_ALL))) && (
            <div
              className={`title-m text-red ${
                !!action.loading || employee?.status === RiderStates.BUSY
                  ? 'pointer-events-none opacity-50'
                  : 'cursor-pointer'
              }`}
              onClick={() => {
                sendEvent('block');

                if (isAllowed(Permission.WRITE_HARD_BLOCK_ALL)) {
                  const hasVisaExpiredReason =
                    (employee?.blockingReasons || []).filter(
                      (r) => r.reason === 'VISA_EXPIRED'
                    )?.length > 0;

                  if (!hasVisaExpiredReason) {
                    setAction({ type: 'block_options' });
                    return;
                  }
                }

                setAction({
                  type: 'block',
                  modalTitle: t('block_rider_confirmation'),
                  func: block,
                });
              }}
            >
              {t('block')}
            </div>
          )}

          {/* Unblock */}
          {employee?.isBlocked && isAllowed(Permission.WRITE_UNBLOCK_ALL) && (
            <div
              className={`title-m text-red ${
                !!action.loading || employee?.status === RiderStates.BUSY
                  ? 'pointer-events-none opacity-50'
                  : 'cursor-pointer'
              }`}
              onClick={() => {
                sendEvent('unblock');

                const hasVisaExpiredReason =
                  (employee?.blockingReasons || []).filter(
                    (r) => r.reason === 'VISA_EXPIRED'
                  )?.length > 0;

                if (hasVisaExpiredReason) {
                  setAction({ type: 'unblock' });
                  return;
                }

                setAction({
                  type: 'block',
                  modalTitle: t('unblock_rider_confirmation'),
                  func: unblock,
                });
              }}
            >
              {t('unblock')}
            </div>
          )}
        </div>
      </Dropdown>

      {/* Confirmation modal for set_online, set_offline, block and unblock actions */}
      <ConfirmationModal
        open={
          action.type === 'set_online' ||
          action.type === 'set_offline' ||
          action.type === 'block'
        }
        onClose={() => setAction({})}
        onConfirm={() => {
          setAction({});
          action.func && action.func();
        }}
      >
        {t(action?.modalTitle || '')}
      </ConfirmationModal>

      {/* set_temp_offline modal */}
      <TempOfflineModal
        open={action.type === 'set_temp_offline'}
        onClose={() => {
          setAction({});
        }}
        onSubmit={async (reason) => {
          await employee?.setTempOffline(reason);
        }}
      />

      {/* create_punch_in_override modal */}
      <PunchInOverrideModal
        open={action.type === 'create_punch_in_override'}
        onClose={() => {
          setAction({});
        }}
        onSubmit={createOverride}
      />

      {/* sync_profile modal */}
      {action.type === 'sync_profile' && (
        <SyncWorkforceProfileModal
          open={action.type === 'sync_profile'}
          onClose={() => {
            setAction({});
          }}
          onSubmit={syncProfile}
        />
      )}

      {/* unblock modal */}
      <UnblockModal
        open={action.type === 'unblock'}
        onClose={() => {
          setAction({});
        }}
        reasons={employee?.blockingReasons || []}
        onSubmit={async (reason) => {
          await employee?.unblock(reason);
        }}
      />

      {/* block options modal */}
      <BlockOptionsModal
        open={action.type === 'block_options'}
        onClose={() => {
          setAction({});
        }}
        reasons={[
          employee?.blockingReasons?.findIndex((r) => r.reason === 'OTHER') ===
            -1 &&
          employee?.blockingReasons?.findIndex(
            (r) => r.reason === 'OTHER_AND_IP_BLOCKED'
          ) === -1
            ? { reason: 'OTHER' }
            : (null as any),

          employee?.blockingReasons?.findIndex(
            (r) => r.reason === 'VISA_EXPIRED'
          ) === -1
            ? { reason: 'VISA_EXPIRED' }
            : (null as any),
        ].filter((r) => r)}
        onSubmit={async (reason) => {
          await employee?.block(reason);
        }}
      />
    </div>
  );
};

export { Actions };
